import React from 'react';
import Vector from "../../assets/images/NCDDetail/Vector.svg";
import { CMS_URL, clevertap_key } from "../../vars/url";
import NoImg from "../../assets/images/noimg.jpg";
import { useState } from 'react';
import './NcdDetailSection.css';
import Info from "../../assets/images/NCDDetail/info_icon.svg";





const NcdDetailSection = ({ ncddetails, counter, cmsdata, setShowPopup }) => {
    const [showMore, setShowMore] = useState(false);

    return (
        <div className="custom_container sidebar_present sec_detail_box">
            <div className="row py-5">
                <div className="col-md-8">
                    <div className="d-flex align-items-md-start align-items-center">
                        <img className="me-4 ncd_logo_name" src={cmsdata?.logo ? `${CMS_URL}${cmsdata.logo}` : NoImg} alt="" />
                        <div className="ncd_header_top">
                            <h1>{ncddetails?.schname}</h1>

                            <p className='d-flex'>Ends in <div className='px-2' dangerouslySetInnerHTML={{ __html: counter }}></div></p>

                            <div className="d-md-flex align-items-center d-none ncd_product_download">
                                <small className="ncd_rating">{ncddetails?.bondRating?.split("/")[0]} {ncddetails?.ratingAgency}</small>
                                {cmsdata.report_file && (
                                    <a target='_blank' href={`${CMS_URL}${cmsdata.report_file}`} download>
                                        <small><img src={Vector} alt="Vector" /> Download product document</small>
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className='d-md-none d-flex align-items-center justify-content-between py-5 jhjh'>
                        <span>Fixed Returns upto <strong style={{ color: '#4DB828' }}>{ncddetails?.maxYield}%</strong></span>
                        <small className="ncd_rating_mobile">{ncddetails?.bondRating?.split("/")[0]} {ncddetails?.ratingAgency}</small>
                    </div>
                </div>
                <div className="col-md-4 text-end d-md-block d-none">
                    <div className="ncd_perc">
                        <h1><sub className='fw-bold'>upto</sub>{ncddetails?.maxYield}%</h1>
                        <p>Fixed returns</p>
                    </div>
                </div>
            </div>

            <div className="ncd_detail_wraper">
                <div className='ncd_wrap_grid'>
                    <div className="ncd_categories">
                        <h4>Minimum investment</h4>
                        <h2><i class="fa fa-rupee mrgR3"></i>{(ncddetails?.highprice * ncddetails?.noOfMandatoryBonds).toLocaleString()}</h2>
                    </div>
                    <div className="ncd_categories">
                        <h4>Maturity period
                            <small>
                                <span><img src={Info} alt="info" width={"16px"} /></span>
                                <div className='maturity_period_notif'>It is the time period after which the principal investment amount of an NCD is given back to the investor along with the interest payments that are regularly made during the life of the bond, cease to roll in.
                                    A user can choose multiple maturity periods for a single bond
                                    <div className='tringle_pointer'></div>
                                </div>
                            </small>
                        </h4>
                        {
                            ncddetails?.issuecode === 'IMC01' ?
                                <div className="showpopup_box">
                                    <span onClick={() => setShowPopup(true)}>Tenure Details</span>
                                </div>
                                :
                                <div className='d-flex flex-wrap'>
                                    {[
                                        ...new Set(ncddetails?.ipoSeriesMap?.map((item) => item.tenure))
                                    ].map((item, i) => (
                                        <h2 className={i === 0 ? '' : 'ps-2'} key={i}> <span>{item.split(' ')[0] + ' ' + item.split(' ')[1][0] + item.split(' ')[1].substring(1).toLowerCase()}</span> {i !== [
                                            ...new Set(ncddetails?.ipoSeriesMap?.map((item) => item.tenure))
                                        ].length - 1 ? <span className='text-muted'>|</span> : ''}</h2>
                                    ))}
                                </div>
                        }
                    </div>
                    <div className="interest_payout ncd_categories">
                        <h4>Interest payout
                            <small>
                                <span><img src={Info} alt="info" width={"16px"} /></span>
                                <div className='maturity_period_notif interest_apay'> Interest Payment Frequency means the frequency with which interest is paid in your Deposit Account.
                                    <div className='tringle_pointer'></div>
                                </div>
                            </small>
                        </h4>
                        <div className='d-flex'>
                            {
                                [...new Set(ncddetails?.ipoSeriesMap?.map((item) => item.frequency))].map((e, i) => (
                                    <h2 className={i === 0 ? '' : 'ps-2'} key={i}>{e[0] + e.substring(1).toLowerCase()} {i !== [...new Set(ncddetails?.ipoSeriesMap?.map((item) => item.frequency))].length - 1 ? <span className='text-muted'>|</span> : ''}</h2>
                                ))
                            }
                        </div>
                    </div>

                    <div className="row">
                        {/* DESKTOP VIEW */}
                        <div className="col-md-12 d-md-block d-none pt-4">
                            <div className="ncd_categories">
                                <h4>Issue size</h4>
                                <h2><i class="fa fa-rupee mrgR3"></i>{((ncddetails?.highprice * ncddetails?.issueQty) / 10000000).toFixed(2)} Cr</h2>
                            </div>
                        </div>

                        {/* MOBILE VIEW */}
                        {
                            showMore ?
                                <>
                                    <div className="col-md-12 d-md-none d-block">
                                        <div className="ncd_categories">
                                            <h4>Issue size</h4>
                                            <h2><i class="fa fa-rupee mrgR3"></i>{((ncddetails?.highprice * ncddetails?.issueQty) / 10000000).toFixed(2)} Cr</h2>
                                        </div>
                                    </div>
                                    <div className='d-md-none d-block text-center download_doc_mobile'>
                                        {cmsdata.report_file && (
                                            <a target='_blank' href={`${CMS_URL}${cmsdata.report_file}`} download>
                                                <small><img src={Vector} alt="Vector" /> Download product document</small>
                                            </a>
                                        )}
                                    </div>

                                </>
                                :
                                ""
                        }
                    </div>

                </div>

                <div className='text-center see_more_detail pt-5'>
                    <span onClick={() => setShowMore(showMore ? false : true)}>See {showMore ? 'less' : 'more'} details</span>
                </div>
            </div>
        </div>
    )
};

export default NcdDetailSection;
