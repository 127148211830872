import React from "react";
import "./GsecPagination.css";
import prev from "../GsecDetails/GsecWeb/assets/prev.svg";
import next from "../GsecDetails/GsecWeb/assets/next.svg";

const GsecPagination = ({ totalPage, pageNum, setPageNum, setIndexNum }) => {
  return (
    <div className="gsecPagination">
      <div
        onClick={() => {
          if (pageNum > 1) {
            setPageNum((preval) => {
              return preval - 1;
            });
            setIndexNum((preval) => {
              return preval - 1 * 6;
            });
          }
        }}
        className={
          pageNum > 1 ? "gsecPaginationBtn active" : "gsecPaginationBtn"
        }
      >
        <img src={pageNum > 1 ? next : prev} alt="prev" /> <span>Prev</span>
      </div>
      {totalPage?.map((page) => (
        <div
          onClick={() => {
            setPageNum(page);
            setIndexNum(page * 6);
          }}
          key={page}
          className={
            pageNum === page ? "paginationNum active" : "paginationNum"
          }
        >
          {page}
        </div>
      ))}
      <div
        onClick={() => {
          if (pageNum < totalPage?.length) {
            setPageNum((preval) => {
              return preval + 1;
            });
            setIndexNum((preval) => {
              return preval + 1 * 6;
            });
          }
        }}
        className={
          pageNum < totalPage?.length
            ? "gsecPaginationBtnx active"
            : "gsecPaginationBtnx"
        }
      >
        <span>Next</span>{" "}
        <img src={pageNum < totalPage?.length ? next : prev} alt="next" />
      </div>
    </div>
  );
};

export default GsecPagination;
