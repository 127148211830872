import { useEffect, useState } from "react";
import TopSection from "./sections/TopSection";
import MiddleSection from "./sections/MiddleSection";
import InDemand from "./sections/InDemand";
import FilterSection from "./sections/FilterSection";
import s from "./sections/Sections.module.scss";
import { getOpenIssuesList } from "../../../services/issuesServices";
import OtherGsecCard from "./OtherGsecCard/OtherGsecCard";
import Group18219 from "../../../assets/images/LandingPage/Group 18219.svg";
import IIFLSec from "../../../assets/images/LandingPage/IIFL Sec.svg";
import { useHistory } from "react-router-dom";
import GsecSorting from "../../GsecSorting/GsecSorting";
import GsecPagination from "../../GsecPagination/GsecPagination";

const GsecWeb = () => {
  const [filterByYear, setFilterByYear] = useState("");
  const [filterByType, setFilterByType] = useState("");
  const [allGsecList, setAllGsecList] = useState([]);
  const [filteredGsecList, setFilteredGsecList] = useState([]);
  const history = useHistory();
  const [showSortBy, setShowSortBy] = useState(false);
  const [title, setTitle] = useState("Select");
  const [selectedSort, setSelectedSort] = useState("");
  const [pageNum, setPageNum] = useState(1);
  const [indexNum, setIndexNum] = useState(6);
  const [totalPage, setTotalPage] = useState([]);

  // fetch all result
  const fetchGsec = async () => {
    let { data } = await getOpenIssuesList("all");

    let gseclist = data.resultData.filter(function (v, i) {
      return (
        v.category.trim() == "GS" ||
        v.category.trim() == "TB" ||
        v.category.trim() == "SD"
      );
    });

    const res = gseclist.map((item) =>
      item.gsecTenure.split(" ")[1]?.toLowerCase() === "years"
        ? { ...item, tenureYear: item.gsecTenure.split(" ")[0] }
        : item.gsecTenure.split(" ")[1]?.toLowerCase() === "days"
        ? {
            ...item,
            tenureYear: (item.gsecTenure.split(" ")[0] / 365).toFixed(2),
          }
        : item
    );

    setAllGsecList(res);
    setFilteredGsecList(res);

    const pageLength = Array(Math.ceil(res.length / 6))
      .fill()
      .map((_, index) => index + 1);

    setTotalPage(pageLength);
  };

  // fetch all result ends
  useEffect(() => {
    fetchGsec();
  }, []);

  useEffect(() => {
    if (filterByType) {
      const result = allGsecList.filter(
        (item) => item.category === filterByType
      );
      setFilteredGsecList(result);
    }
  }, [filterByType]);

  useEffect(() => {
    if (filterByYear) {
      const result = allGsecList.filter(
        (item) => item.tenureYear <= parseFloat(filterByYear)
      );
      setFilteredGsecList(result);
    }
  }, [filterByYear]);

  let filterYrArr = ["1", "3", "5", "10", "20", "30"];

  let filtertype = [
    {
      id: 1,
      category: "T-bill (Tresuary bill)",
      type: "TB",
    },
    {
      id: 2,
      category: "Govt. bonds",
      type: "GS",
    },
    {
      id: 3,
      category: "SDL-State Development Loan",
      type: "SD",
    },
  ];

  const handleResetFilter = () => {
    setFilteredGsecList(allGsecList);
    setFilterByType("");
    setFilterByYear("");
  };

  const handleSearchGsec = (e) => {
    const result = allGsecList.filter((item) =>
      item.schname.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredGsecList(result);
  };

  const handleSortGsec = (e) => {
    const val = e.target.value;
    setSelectedSort(val);
    if (val === "1") {
      setTitle("Tenure : low to high");
      var inc = filteredGsecList.sort((a, b) => a.tenureYear - b.tenureYear);
      setFilteredGsecList([...inc]);
    }
    if (val === "2") {
      setTitle("Tenure : high to low");
      var des = filteredGsecList.sort((a, b) => b.tenureYear - a.tenureYear);
      setFilteredGsecList([...des]);
    }
    if (val === "3") {
      setTitle("Indicative yield : low to high");
      const inc = filteredGsecList.sort(
        ({ indicativeYield: a }, { indicativeYield: b }) => a - b
      );
      setFilteredGsecList([...inc]);
    }
    if (val === "4") {
      setTitle("Indicative yield : high to low");
      const des = filteredGsecList.sort(
        ({ indicativeYield: a }, { indicativeYield: b }) => b - a
      );
      setFilteredGsecList([...des]);
    }

    setTimeout(() => {
      setShowSortBy(false);
    }, 100);
  };

  return (
    <>
      <div
        className="ncd_haeder d-md-block d-none"
        style={{ paddingLeft: "150px" }}
      >
        <div className="">
          <img
            onClick={() => history.push("/")}
            width={100}
            src={Group18219}
            alt="logo"
          />
          <span className="px-3">Powered by</span>
          <img src={IIFLSec} alt="logo" />
        </div>
      </div>
      <section className={s.GsecWebWrap}>
        <TopSection allGsecList={allGsecList} />
        <MiddleSection />
        {/* <InDemand /> */}
        <div
          id="explore"
          className={s.mainResults}
          style={{ marginBottom: "200px" }}
        >
          <h2>Explore government securities</h2>
          <div className={s.filterWrap}>
            <FilterSection
              filterYear={filterYrArr}
              filtertype={filtertype}
              setFilterByYear={setFilterByYear}
              setFilterByType={setFilterByType}
              filterByYear={filterByYear}
              filterByType={filterByType}
              handleResetFilter={handleResetFilter}
              handleSearchGsec={handleSearchGsec}
            />
          </div>
          <div className={s.resultsrWrap}>
            <GsecSorting
              selectedSort={selectedSort}
              setShowSortBy={setShowSortBy}
              showSortBy={showSortBy}
              title={title}
              handleSortGsec={handleSortGsec}
            />

            <div className="row g-5">
              {filteredGsecList.length === 0 ? (
                <div className={s.noResult_web}>
                  <h1>No G-sec available for selected results</h1>
                </div>
              ) : (
                <>
                  <div className={s.invest_sec_card}>
                    {filteredGsecList
                      .slice(indexNum - 6, pageNum * 6)
                      .map((data) => (
                        <OtherGsecCard details={data} />
                      ))}
                  </div>
                  {filteredGsecList.length > 6 && (
                    <GsecPagination
                      totalPage={totalPage}
                      pageNum={pageNum}
                      setPageNum={setPageNum}
                      setIndexNum={setIndexNum}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GsecWeb;
