import React, { useEffect, useState } from 'react';
import { addNPSTransaction, createOrder, updateNPSTransaction } from '../../services/issuesServices';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SweetAlert from "react-bootstrap-sweetalert";
import img9 from '../../assets/images/nps/img9.svg';
import img8 from '../../assets/images/nps/img8.svg';
import img7 from '../../assets/images/nps/img7.svg';
import './NpsPayment.scss';

const NpsPayment = () => {
  const history = useHistory();
  const { npsData, npsPaymentMethod } = useSelector((state) => state.npsReducer);
  const [alertMessage, setAlertMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [messageTitle, setMessageTitle] = useState("");

  
  const paymentHandler = async () => {
    try {

      // ADD GST 18% LOGIC HERE
      let gross_amount;
      const net_amount = +npsData.normalAmount + +npsData.arrearsAmount;

      if (npsData.paymentType === 'netBanking') {
        let fee = 15; //fixed fee
        let gst = fee * (18 / 100);
        gross_amount = net_amount + fee + gst;
      } else if (npsData.paymentType === 'debitCard' && net_amount <= 2000) {
        let fee = net_amount * 0.005; // fee 0.50% of net amount
        let gst = fee * (18 / 100);
        gross_amount = net_amount + fee + gst;
      } else if (npsData.paymentType === 'debitCard' && net_amount > 2000) {
        let fee = net_amount * 0.01; // fee 1% of net amount
        let gst = fee * (18 / 100);
        gross_amount = net_amount + fee + gst;
      }else{
        let fee = 5; //fixed fee
        let gst = fee * (18 / 100);
        gross_amount = net_amount + fee + gst;
      }

      const res = await createOrder({ amount: Math.round((gross_amount + Number.EPSILON) * 100) / 100 });     //gross_amount rounded upto 2 decimal place

      const result = await addNPSTransaction({
        ...npsData,
        "orderId": res.data.id,
        "paymentId": "",
        "paymentStatus": "processing",
        "desc": "",
        "createdBy": JSON.parse(localStorage.getItem('user')).clientcode
      });

      if (result.data.isSuccess && result.data.statusCode == 200) {
        // MAKE PAYMENT HERE
        const options = {
          key: process.env.REACT_APP_RAZORPAY_KEY_ID,
          name: "IIFL Securities",
          description: "NPS Subsequent Payment",
          order_id: res.data.id,
          handler: async (response) => {
            try {
              await updateNPSTransaction({
                ...npsData,
                "orderId": res.data.id,
                "paymentId": response.razorpay_payment_id,
                "paymentStatus": "Success",
                "desc": "NPS Subsequent Payment",
                "createdBy": JSON.parse(localStorage.getItem('user')).clientcode
              });
              // console.log("success", resultData)
              setShowMessage(true);
              setMessageTitle('Top-Up Completed Successfully')
            } catch (err) {
              console.log(err);
            }
          },
          theme: {
            color: "#686CFD"
          },
          modal: {
            ondismiss: async function () {
              try {
                await updateNPSTransaction({
                  ...npsData,
                  "orderId": res.data.id,
                  "paymentId": "0",
                  "paymentStatus": "Cancelled",
                  "desc": "Payment processing cancelled by user",
                  "createdBy": JSON.parse(localStorage.getItem('user')).clientcode
                });
                setShowMessage(true);
                setMessageTitle('Top-Up Cancelled Successfully')
              } catch (err) {
                console.log(err);
              }
            }
          },
          notes: {
            ...npsData,
            "pan number": JSON.parse(localStorage.getItem('user')).panNo
          },
          prefill: {
            contact: JSON.parse(localStorage.getItem('user')).mobileNo,
            email: JSON.parse(localStorage.getItem('user')).email
          },
          method: { ...npsPaymentMethod, wallet: false }
        };
        const rzp1 = new window.Razorpay(options);

        // To get payment id in case of failed transaction.
        rzp1.on('payment.failed', async (response) => {
          if (response.error.reason) {
            try {
              await updateNPSTransaction({
                ...npsData,
                "orderId": response.error.metadata.order_id,
                "paymentId": response.error.metadata.payment_id,
                "paymentStatus": "Failed",
                "desc": response.error.description,
                "createdBy": JSON.parse(localStorage.getItem('user')).clientcode
              });
            } catch (err) {
              console.log(err);
            }
          }
        });

        rzp1.open();

      } else {
        setAlertMessage(result.data.resultData);
      }
    } catch (error) {
      // console.log(error.response)
      setAlertMessage(error?.response?.data?.error?.description||'Something went wrong');
    }
  };


  useEffect(() => {
    if (Object.keys(npsData).length === 0) {
      history.push('/');
    } else {
      paymentHandler();
    }
  }, [])


  return (
    <div className='nps_form'>
      {alertMessage && (
        <SweetAlert error onConfirm={() => {
          setAlertMessage("");
          history.push('/');
        }}>
          <p>{alertMessage}</p>
        </SweetAlert>
      )}

      <div className={showMessage ? 'popup_overlay show' : 'popup_overlay'}></div>
      <div className={showMessage ? 'popup show' : 'popup'}>
        <img onClick={() => {
          setShowMessage(false);
          history.push('/');
        }} className='close' src={img8} alt="img" />
        <img src={messageTitle.includes('Cancelled') ? img7 : img9} alt="img" />
        <h2>{messageTitle}</h2>
        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p> */}
        <div className='form_btn'>
          <button onClick={() => {
            setShowMessage(false);
            history.push('/');
          }} type='button'>Okay</button>
        </div>
      </div>
    </div>
  )
};

export default NpsPayment;