import React from "react";
import gsecLogo from "../../GsecWeb/assets/gsecLogo.jpg";
import lightbulb from "../../GsecWeb/assets/fxemoji_lightbulb.svg";
import xcircle from "../../GsecWeb/assets/akar-icons_cross.svg";
import moment from "moment";
import { addgsec, getLedgerBalance } from "../../../../services/issuesServices";
import { useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import "./GsecPaymentDrawer.css";
import backArrow from "../../GsecWeb/assets/backArrow.svg";
import useWindowDimensions from "../../../../hooks/screenWidth";
import { useEffect } from "react";
import { checkBalance, totalPayableAmt } from "../../../../helpers/gsecHelper";
import gs_icon from "../../../../assets/images/gs_icon.svg";
import sdl_icon from "../../../../assets/images/sdl_icon.svg";
import T_Bill_Icon from "../../../../assets/images/T_Bill_Icon.svg";

const GsecPaymentDrawer = ({
  showPaymentDrawer,
  invtQty,
  setShowPaymentDrawer,
  gsecDetail,
  setPaynowLoader,
  setAddGsecResult,
}) => {
  const { user } = useSelector((state) => state.loggedIn);
  const [alertMessage, setAlertMessage] = useState("");
  const history = useHistory();
  const { width } = useWindowDimensions();
  const [ledgerBalance, setLedgerBalance] = useState(0);
  const [isLaoding, setIsLaoding] = useState(true);

  const fetchLedgerBalance = async () => {
    setIsLaoding(true);
    try {
      const res = await getLedgerBalance({
        clientCode: user?.clientcode,
        amount: 0,
        marginFlg: "N",
      });
      if (res.data.statusCode === 200) {
        setLedgerBalance(res.data.resultData?.availableBalance);
      } else {
        setAlertMessage(res.data.message);
      }
    } catch (error) {
      // setAlertMessage(error);
    } finally {
      setIsLaoding(false);
    }
  };

  useEffect(() => {
    if (showPaymentDrawer) {
      fetchLedgerBalance();
    }
  }, [showPaymentDrawer]);

  const handleAddGsec = async () => {
    setPaynowLoader(true);
    try {
      const res = await addgsec({
        IssueCode: gsecDetail?.issuecode,
        IssueType: gsecDetail?.category,
        ClientCode: user?.clientcode,
        LoginId: user?.loginid,
        BidQty: invtQty,
        BidPrice:
          gsecDetail?.category === "TB"
            ? gsecDetail?.faceValue
            : gsecDetail?.markupFaceValue,
        TotalAmount: gsecDetail?.faceValue * invtQty,
        BidFlag: "N",
        AppSource: user?.AppSource,
        MasterSrNo: 0,
        ApplicationNo: 0,
      });
      if (res.data.statusCode === 200) {
        setAddGsecResult(res.data);
        history.push("/application-submitted");
      } else {
        setAlertMessage(res.data.message);
      }
    } catch (error) {
      setAlertMessage(error);
    } finally {
      setPaynowLoader(false);
    }
  };

  return (
    <div>
      {alertMessage && (
        <SweetAlert error onConfirm={() => setAlertMessage("")}>
          {alertMessage}
        </SweetAlert>
      )}

      <div
        className={
          showPaymentDrawer
            ? "payment-drawer-backdrop show"
            : "payment-drawer-backdrop"
        }
      ></div>
      <div
        className={showPaymentDrawer ? "payment-drawer show" : "payment-drawer"}
      >
        <div className="x-circle">
          <img
            onClick={() => setShowPaymentDrawer(false)}
            src={width > 768 ? xcircle : backArrow}
            alt="xcircle"
          />
        </div>

        <div className="payment-drawer-wrap">
          <div className="payment-drawer-haeding">
            Please wait, while we process your application
          </div>

          <div className="payment-drawer-mobile-box">
            <div className="payment-drawer-mobile-wrap">
              <div className="payment-drawer-order-title">
                Confirm order and proceed to pay
              </div>

              <div className="payment-drawer-gseclogo">
                <div>
                  <img
                    src={
                      gsecDetail?.category === "GS"
                        ? gs_icon
                        : gsecDetail?.category === "TB"
                          ? T_Bill_Icon
                          : gsecDetail?.category === "SD"
                            ? sdl_icon
                            : gsecLogo
                    }
                    alt={gsecDetail?.schname}
                  />
                </div>
                <div>
                  <h2>{gsecDetail?.schname}</h2>
                  <p>
                    {gsecDetail?.category === "GS"
                      ? "G-Sec"
                      : gsecDetail?.category === "TB"
                        ? "T-Bill"
                        : "SDL"}
                  </p>
                </div>
              </div>

              <div className="row g-4 payment-drawer-gsecinfo">
                <div className="col-6">
                  <p>No of bonds</p>
                  <h2>{invtQty}</h2>
                </div>
                <div className="col-6">
                  <p>Total payable amount</p>
                  <h2>
                    ₹
                    {totalPayableAmt(gsecDetail, invtQty).toLocaleString(
                      "en-IN"
                    )}
                  </h2>
                </div>
                <div className="col-6">
                  <p>Allotment finalization date</p>
                  <h2>
                    {moment(gsecDetail?.allotmentFinalizationDate).format(
                      "D MMM YYYY"
                    )}
                  </h2>
                </div>
                <div className="col-6">
                  <p>Listing date</p>
                  <h2>
                    {moment(gsecDetail?.listingDate).format("D MMM YYYY")}
                  </h2>
                </div>
              </div>
            </div>
            {isLaoding ? (
              <div className="Check_fund_balance">
                <div>
                  <h2>Checking fund balance</h2>
                  <p>
                    For processing application of ₹
                    {totalPayableAmt(gsecDetail, invtQty).toLocaleString(
                      "en-IN"
                    )}
                  </p>
                </div>

                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <>
                <div
                  className={
                    checkBalance(ledgerBalance, gsecDetail, invtQty)
                      ? "payment-drawer-paynow-box"
                      : "payment-drawer-paynow-box insuf-bal"
                  }
                >
                  <div>
                    <h2>
                      {checkBalance(ledgerBalance, gsecDetail, invtQty)
                        ? `Fund Bal: ₹${ledgerBalance.toLocaleString("en-IN")}`
                        : `Insufficient  bal: ₹${ledgerBalance.toLocaleString(
                          "en-IN"
                        )}`}
                    </h2>
                    <p>
                      {checkBalance(ledgerBalance, gsecDetail, invtQty)
                        ? `₹${totalPayableAmt(
                          gsecDetail,
                          invtQty
                        ).toLocaleString(
                          "en-IN"
                        )} will be debited from the ledger`
                        : `₹${(
                          totalPayableAmt(gsecDetail, invtQty) - ledgerBalance
                        ).toLocaleString(
                          "en-IN"
                        )} is needed for placing the application.`}
                    </p>
                  </div>
                  {checkBalance(ledgerBalance, gsecDetail, invtQty) && (
                    <div>
                      <button onClick={handleAddGsec}>Pay Now</button>
                    </div>
                  )}
                </div>
                {!checkBalance(ledgerBalance, gsecDetail, invtQty) && (
                  <div className="addfund-info">
                    <img src={lightbulb} alt="img" />
                    <p>
                      You can aslo add funds through IIFL Markets mobile
                      application
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GsecPaymentDrawer;
