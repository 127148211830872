import { combineReducers } from 'redux';

import user from './user.reducer.js';
import loggedIn from './loggedIn.reducer.js';
import applicationData from './applicationData.reducer.js';
import ncdReducer from '../ncdseries/ncdReducer';
import gsecReducer from '../gsec/gsecReducer';
import npsReducer from '../nps/npsReducer';


export default combineReducers({
	user,
	loggedIn,
	applicationData,
	ncdReducer,
	gsecReducer,
	npsReducer,
});
