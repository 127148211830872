let user = JSON.parse(localStorage.getItem('user'));

const initialState = user ? { loggedIn: true, user } : {};

const loggedInReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'LOGIN': {

			return { ...state, loggedIn: true, user: action.payload }
		}
		case 'LOGOUT':
			return {};
		default:
			return state;
	}
};

export default loggedInReducer;
