export const IPO = "IPO"
export const SGB = "SGB"
export const NCD = "NCD"
export const BOND = "BOND"
export const CIPHER_KEY_PROD = "Bu4Ya5Mg~6Jt$6#x";
export const CIPHER_KEY_DEV = "Mp9uA0Iz^3Pe#92r"
export const PLAIN_REQUEST_CODE = "Ap!MymONEy@pR@d2857"


// CMOTS Constants
export const CMOTS_AUTH_USERNAME = "indiainfoline\\TaglrTech_Galaktic"
export const CMOTS_AUTH_PASSWORD = "Admin@1234"