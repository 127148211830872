import React from "react";
import "./GsecSorting.css";
import arrowDownIcon from "../GsecDetails/GsecWeb/assets/arrow-dowm-sm.svg";
import useWindowDimensions from "../../hooks/screenWidth";

const GsecSorting = ({
  title,
  selectedSort,
  setShowSortBy,
  showSortBy,
  handleSortGsec,
}) => {
  const { width } = useWindowDimensions();

  return (
    <div className="gsecSort">
      {width > 768 ? (
        <div className="sortByBox">
          <span>Sort By :</span>
          <div
            onClick={() => setShowSortBy(!showSortBy)}
            className="sortByTitle"
          >
            <p>{title}</p>
            <img src={arrowDownIcon} alt="arrowDownIcon" />
          </div>
        </div>
      ) : (
        <div onClick={() => setShowSortBy(!showSortBy)} className="mobile-sort">
          <span>
            Sort by <img src={arrowDownIcon} alt="arrowDownIcon" />
          </span>
        </div>
      )}
      {showSortBy && (
        <div className="sortingBox">
          <p>Sort By</p>
          <div className="apply_for_radio">
            <input
              onChange={handleSortGsec}
              type="radio"
              checked={selectedSort === "1"}
              id="tenlowtohigh"
              name="sort_by"
              value="1"
            />
            <label className="ms-2 me-5" htmlFor="tenlowtohigh">
              Tenure : low to high
            </label>
          </div>
          <div className="apply_for_radio">
            <input
              onChange={handleSortGsec}
              type="radio"
              checked={selectedSort === "2"}
              id="tenhightolow"
              name="sort_by"
              value="2"
            />
            <label className="ms-2 me-5" htmlFor="tenhightolow">
              Tenure : high to low
            </label>
          </div>
          <div className="apply_for_radio">
            <input
              onChange={handleSortGsec}
              type="radio"
              checked={selectedSort === "3"}
              id="indlowtohigh"
              name="sort_by"
              value="3"
            />
            <label className="ms-2 me-5" htmlFor="indlowtohigh">
              Indicative yield : low to high
            </label>
          </div>
          <div className="apply_for_radio">
            <input
              onChange={handleSortGsec}
              type="radio"
              checked={selectedSort === "4"}
              id="indhightolow"
              name="sort_by"
              value="4"
            />
            <label className="ms-2 me-5" htmlFor="indhightolow">
              Indicative yield : high to low
            </label>
          </div>
        </div>
      )}
    </div>
  );
};

export default GsecSorting;
