import NoImg from "../../../assets/images/noimg.jpg";
import s from "./DetailsScreen.module.scss";
import icon5 from "./assets/icon5.png";
import { useHistory } from "react-router-dom";
import gs_icon from "../../../assets/images/gs_icon.svg";
import sdl_icon from "../../../assets/images/sdl_icon.svg";
import T_Bill_Icon from "../../../assets/images/T_Bill_Icon.svg";

const Detailssingle = ({ details, cmsData }) => {
  const history = useHistory();

  return (
    <>
      <div
        className={s.singleDetails}
        onClick={() => history.push(`/gsec_details/${details?.issuecode}`)}
      >
        <span className={s.topTag}>{cmsData?.tag}</span>
        <div className={s.headTop}>
          <div>
            <img
              src={
                cmsData?.logo
                  ? cmsData?.logo
                  : details?.category === "GS"
                  ? gs_icon
                  : details?.category === "TB"
                  ? T_Bill_Icon
                  : details?.category === "SD"
                  ? sdl_icon
                  : NoImg
              }
              alt={details?.schname}
            />
          </div>
          <div>
            <h2>
              {cmsData?.issue_name ? cmsData?.issue_name : details?.schname}
            </h2>
            <span>
              {details?.category === "TB"
                ? "T-Bill"
                : details?.category === "SD"
                ? "SDL"
                : "G-Sec"}
            </span>
          </div>
        </div>
        <div className={s.investmentDetailsCard}>
          {details?.indicativeYield > 0 &&
            <div>
              <p>
                Indicative Yield
                {/* <img src={icon5} /> */}
              </p>
              <h2>{details?.indicativeYield}%</h2>
            </div>
          }
          <div>
            <p>Tenure</p>
            <h2>{details?.gsecTenure}</h2>
          </div>
          <div>
            <p>Interest Freq.</p>
            <h2>
              {details?.category === "TB"
                ? "At maturity"
                : details?.category === "SD"
                ? "Every 6 months"
                : "Every 6 months"}
            </h2>
          </div>
        </div>
      </div>
    </>
  );
};
export default Detailssingle;
