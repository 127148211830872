import { otpValidate } from "../services/issuesServices";


export const ValidateUser = async () => {
    let user = JSON.parse(localStorage.getItem('user'))


    try {
        if (user && user?.token) {
            let { data } = await otpValidate({ "token": user.token })
            if (data.statusCode !== 200) {
                localStorage.removeItem('user');
                window.location.reload();
            }

        }
    } catch (error) {
        console.log(error)
        localStorage.removeItem('user');
        window.location.reload();
    }
}