import React from "react";

const GsecKnow = () => {
  return (
    <div className="gsec_card_detail_two">
      <h2>Things you need to know</h2>
      <div className="acc_interest">
        <span>1.</span>
        <p>
          Placing and canceling orders is only possible between 10:00 AM and
          5:00 PM.
        </p>
      </div>
      <div className="acc_interest">
        <span>2.</span>
        <p>
          If a confirmed bid is canceled, the amount will be reversed on the
          next day.
        </p>
      </div>
      <div className="acc_interest">
        <span>3.</span>
        <p>
          A brokerage fee of 0.06% or Rs 6 per Rs 10,000 invested will be
          levied. 18% GST will apply to the total brokerage amount.
        </p>
      </div>
    </div>
  );
};

export default GsecKnow;
