import React, { Fragment } from 'react';
import s from '../LandingPage/LandingPage.module.scss';
import EventImg from '../../assets/images/LandingPage/event_black_24dp.svg';
import { useHistory } from 'react-router-dom';
import useWindowDimensions from '../../hooks/screenWidth';
import ClevertapReact from 'clevertap-react';
import { CMS_URL, clevertap_key, CMS_API_URL } from '../../vars/url';


const Section1Card = ({
	issue_type,
	logo,
	upper_tag_logo,
	title,
	card_color_left,
	card_color_right,
	apply_button_text,
	issuecode,
	invest_minimum_amount,
	invest_within,
	top,
	subHeading,
	event,
	button,
	background,
	color,
	expiry_date,
	subsdata,
	openipodt,
	issue_name,
	user,
	applyipoind,
}) => {
	const { width } = useWindowDimensions();
	const history = useHistory();
	const handleClicksec = (issuecode, issue_type = 'IPO') => {

		localStorage.setItem('app_step_event_src', window.location.pathname);

		ClevertapReact.initialize(clevertap_key);
		// ClevertapReact.event("In-Demand	Know More", {
		// 	'path': window.location.hostname,
		// 	'client-type': Object.keys(user).length > 0 ? user.user.clientType : 'Pre-login',
		// 	'client-code': Object.keys(user).length > 0 ? user.user.clientCode : 'Pre-login',
		// 	'user-agent': navigator.userAgent,
		// 	'app-source': Object.keys(user).length > 0 ? (user?.user?.AppSource ? user?.user?.AppSource : 25) : 25,
		// 	"Source": "Landing Page",
		// 	"issue-Type": issue_type,
		// 	"Issuecode": issuecode
		// })
		ClevertapReact.event("OU_Indemand Click", {
			"Button Name": "Know More",
			"Issue Name": issuecode,
			'client-type': Object.keys(user).length > 0 ? user.user.clientType : 'Pre-login',
			'client-code': Object.keys(user).length > 0 ? user.user.clientcode : 'Pre-login',
		})


		if (issue_type === 'IPO') {
			//ClevertapReact.event("IPO Detail Page")
			history.push("/ipo_details/" + issuecode)

		}
		else if (issue_type === 'NCD') {
			//ClevertapReact.event("NCD Detail Page")
			history.push("/ncd_details/" + issuecode)
		}
		else {
			//ClevertapReact.event("SGB Detail Page")
			history.push("/sovereign_gold_bond_details")
		}
	}

	// get price and date from broking api
	var mandatebond = ''
	var mandateprice = ''
	var mndtdateclos = ''
	var noOfmandateBond = 0
	var allisue = JSON.parse(localStorage.getItem("alloppor"));
	if (allisue) {
		var thisissue = allisue.filter(function (v, i) {
			return (v.issuecode == issuecode);
		})

		var thisissue = thisissue[0]

		mandatebond = thisissue?.noOfMandatoryBonds
		mandateprice = thisissue?.lowprice
		//mndtdateclos = new Date(thisissue?.clsdt).toLocaleDateString('en-IN', { weekday:"long", year:"numeric", month:"short", day:"numeric"})
		mndtdateclos = new Date(thisissue?.clsdt).toLocaleDateString('en-IN', { day: '2-digit', month: '2-digit', year: 'numeric' })
	}
	//end get price and date from broking api


	// Styling color
	if (issue_type === 'IPO') {
		card_color_left = "#4940a9";
		card_color_right = "#241f55";
	}
	else if (issue_type === 'NCD') {
		card_color_left = "#21f3f3";
		card_color_right = "#214cf3";
	}
	else {
		card_color_left = "#f8e150";
		card_color_right = "#853f02";
	}
	// End Styling color

	return (
		<div
			className={s.card + " animate__animated animate__fadeIn hover-animate"}
			style={{ background: `linear-gradient(321deg,  ${card_color_right}, ${card_color_left})`, cursor: 'pointer' }}
			onClick={() => handleClicksec(issuecode, issue_type)}
		>

			{upper_tag_logo !== 'none' &&
				<div className={s.top + " animate__animated animate__zoomIn"}>
					<span>
						<img src={`${CMS_API_URL}/icons/${upper_tag_logo}.svg`} alt={upper_tag_logo} />

						{upper_tag_logo === 'fire' && 'On fire!'}
						{upper_tag_logo === 'percentage' && 'Tax benefits'}
						{upper_tag_logo === 'rupees' && 'Fixed returns'}

					</span>
				</div>
			}

			{width > 768 && <img src={`${CMS_URL}${logo}`} alt={issuecode} />}

			<div className={s.body}>

				{width > 768 ? (
					<Fragment>
						<h3 style={card_color_left === '#ffffff' ? { color: '#000' } : { width: 'initial' }}>{issue_name}</h3>
						{/* {subsdata &&
							<span style={{color:'#fff'}}>							
								IPO subscribed by {(subsdata*100).toFixed(2)}%
							</span>
						} */}



					</Fragment>
				) : (
					<div className={s.mobileHead}>
						<img src={`${CMS_URL}${logo}`} alt={issuecode} />
						<div className={s.heading}>
							<h3 style={card_color_left === '#ffffff' ? { color: '#000' } : { width: 'initial' }}>{issue_name}</h3>
							{/* <div className={s.subHeading}>
								{openipodt.map((e,litm) => {
									if(issuecode==e.issuecode){
										return (
											<>
												<span style={card_color_left === '#ffffff' ? {color:'#000'} : {width:'initial'} }>Invest minimum ₹{e.lowprice*e.lotsize}</span>
												<span className={s.light} style={card_color_left === '#ffffff' ? {color:'#000'} : {width:'initial'} }>/ {e.lotsize} Shares </span>
											</>
										)
									}
									
								})}
							</div> */}
							{/* <div className={s.sgbdetailssubindeemand}>
								{(issue_type==='SGB' || issue_type==='NCD') &&
									<>
										<span style={card_color_left === '#ffffff' ? {color:'#000'} : {width:'initial'} }>Invest minimum ₹ {mandateprice*(mandatebond || 1)}/ {mandatebond || 1} Shares</span>
									<span className={s.investby}><img src={EventImg}  alt="Invest" /><span>Invest Before {mndtdateclos} 5:00 PM.</span></span>
									</>
								}
							</div> */}
						</div>
					</div>
				)}

				<div className={s.subHeading}>
					<span className={s.light}>
						{openipodt.map((e, litm) => {
							if (issuecode == e.issuecode) {
								return (
									<div key={litm}>
										<span style={card_color_left === '#ffffff' ? { color: '#000' } : { width: 'initial' }}>Investment Amt. <i class="fa fa-rupee mrgR3"></i>{e.highprice * e.lotsize}</span>
										<span className={s.light} style={card_color_left === '#ffffff' ? { color: '#000' } : { width: 'initial' }}>/ {e.lotsize} Shares </span>
									</div>
								)
							}

						})}
					</span>
				</div>

				<div className={s.sgbdetailssubindeemand}>

					{(issue_type === 'SGB' || issue_type === 'NCD') &&
						<>

							<span style={card_color_left === '#ffffff' ? { color: '#000' } : { width: 'initial' }}>Invest minimum <i class="fa fa-rupee mrgR3"></i> {mandateprice * (mandatebond || 1)}/ {mandatebond || 1} Shares</span>
							<span className={s.invest}><img src={EventImg} alt="Invest" /><span>Invest Before {mndtdateclos} 5:00 PM.</span></span>
						</>
					}
				</div>


				{openipodt.map((e, litm) => {
					if (issuecode == e.issuecode) {
						return (
							<div key={litm} className={s.invest}>
								<img src={EventImg} style={card_color_left === '#ffffff' ? { filter: 'invert(1)' } : { filter: 'inherit' }} alt="Invest" />
								<span style={card_color_left === '#ffffff' ? { color: '#000' } : { width: 'initial' }}>Invest Before {new Date(e.clsdt).toLocaleDateString('en-IN', {
									day: '2-digit', month: '2-digit', year: 'numeric'
								})} 5:00 PM.</span>
							</div>
						)
					}
				})}

			</div>


			<div className={s.buttons}>
				<button className={s.button} style={card_color_left === '#ffffff' ? { color: '#000', background: '#ccc' } : { width: 'inherit' }}>
					{/* {apply_button_text ? apply_button_text : 'Apply Now'} */}
					Know More
				</button>

				{/* {Object.keys(user).length > 0 && <button className={s.button2} onClick={() => applyipoind(issuecode)} >Apply Now</button>} */}
			</div>
		</div>
	);
};

export default Section1Card;
