import moment from 'moment/moment';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import pay_approve from "../../assets/images/NCDDetail/pay_approve.svg";
import pay_check from "../../assets/images/NCDDetail/pay_check1.svg";
import { zohoCreateLead, zohoCreateToken } from '../../services/issuesServices';


const BiddingSuccess = ({ setShowConfirmModal }) => {
    const history = useHistory();
    const applicationData = useSelector(state => state.applicationData);

    useEffect(() => {
        if (JSON.parse(localStorage.getItem("user")).clientType === "IIFLCLIENT") {
            zohoCreateToken().then((resp) => {
                zohoCreateLead({
                    Token: resp.data.Body.Token,
                    ObjectName: "Lead",
                    Parameters: {
                        FirstName: "",
                        LastName: applicationData.lstname,
                        Mobile: applicationData.mobileNo,
                        Email: applicationData.email,
                        LeadProduct: "Equity",
                        Campaign: "NCD",
                        LeadSource: "OneUp",
                        LeadSubStatusTemp: "Completed",
                        Appsource: "25",
                    },
                });
            });
        }
    }, [])

    return (
        <div className='confirm_model_wrapper'>
            <div className='confirm_model'>
                <div className='confirm_model_container'>
                    {/* <div className='step_connect_upi'></div> */}
                    <div className='pay_approve mt-5'>
                        <img src={pay_approve} alt="pay_approve" />
                        <h4 className='py-4' style={{ color: '#FFA928' }}>₹{(applicationData?.mainslidervalue).toLocaleString()}</h4>
                        <span>Approve payment on your UPI app </span>
                    </div>

                    <div className='d-flex py-5 your_appl line-left1'>
                        <div className='payment_check'>
                            <img src={pay_check} alt="pay_check" />
                        </div>
                        <div>
                            <span>Your application has been received</span>
                            <br />
                            <small className='text-muted'>Today {moment().format('h:mm A')}</small>
                        </div>
                    </div>

                    <div className='d-flex pt-3 pb-5 your_appl'>
                        <div className='payment_info'>
                            <img src={pay_approve} alt="pay_approve" />
                        </div>
                        <div>
                            <span>Payment approval pending</span>
                            <br />
                            <small className='text-muted'>Please check your UPI app to approve the payment request of ₹{(applicationData?.mainslidervalue).toLocaleString()}and complete the NCD application.</small>
                        </div>
                    </div>

                    <div className='view_app_Btn d-flex justify-content-center pt-4'>
                        <button onClick={() => {
                            localStorage.setItem('app_history', window.location.pathname)
                            history.push("/your_applications");
                            setShowConfirmModal(false);
                        }}>View application status</button>
                    </div>
                    <div className='confirm_Done_buttn d-flex justify-content-center'>
                        <button onClick={() => {
                            history.push("/");
                            setShowConfirmModal(false);
                        }}>Done</button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default BiddingSuccess;
