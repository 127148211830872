import moment from "moment";
import React from "react";
import stepOneIcon from "../assets/stepOneIcon.svg";
import stepTwo from "../assets/stepTwo.svg";
import stepThree from "../assets/stepThree.svg";
import stepFour from "../assets/stepFour.svg";
import stepFive from "../assets/stepFive.svg";
import playicon from "../assets/playicon.svg";

const HowItWork = ({ gsecDetail, isDate, title }) => {
  return (
    <div className="gsec_howitwork">
      <div className="how_it_work custom_container">
        <h1 className="d-none d-md-block">{title}</h1>
        <div className="howWorkPlay">
          <h1 className="d-md-none">How it works?</h1>
          <span className="under_gsec">
            <img src={playicon} alt="playicon" />
            Watch video
          </span>
        </div>

        <div className="how_it_work_wrap">
          <div className="row">
            <div className="col-md-6">
              <div className="howit_work_steps">
                <img src={stepOneIcon} alt="stepOneIcon" />
                <div>
                  <h2>Select no of bonds</h2>
                  <span>Enter the quantity of units in multiples of 100</span>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="howit_work_steps">
                <img src={stepTwo} alt="stepTwo" />
                <div>
                  <h2>Pay from ledger</h2>
                  <span>Money will be debited from your ledger </span>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="howit_work_steps">
                <img src={stepThree} alt="stepThree" />
                <div>
                  <h2>Auction Ends</h2>
                  <span>
                    Market value of the government securities will be decided
                    post auction
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="howit_work_steps">
                <img src={stepFour} alt="stepFour" />
                <div>
                  <h2>Allotment of government securities in demat</h2>
                  <span>
                    Investment will refelct in portfolio holdings{" "}
                    {isDate
                      ? `on ${moment(gsecDetail?.listingDate).format(
                          "MMM D YYYY"
                        )}`
                      : "post allotment"}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="howit_work_steps steps_last">
                <img src={stepFive} alt="stepFive" />
                <div>
                  <h2>Additonal amount refund</h2>
                  <span>
                    Refund Amount = (Total Payable Amount - Allotment Value) -
                    0.06% of Allotment Value
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWork;
