import React from 'react';
import s from '../IPODetails/IPODetails.module.scss';
import EventImg from '../../assets/images/LandingPage/event_black_24dp.svg';
import ClevertapReact from 'clevertap-react';
import { CMS_URL, clevertap_key } from '../../vars/url';

const SGBSection1Card = ({ issueQty, lowprice, highprice, gotoIPO, img, title, top, subHeading,rangeLowprice, rangeCutoff, event, button, background, color,topbackground, issueCode, history, iscolor }) => {
    return (
        <div className={s.card + " hover-animate"} style={{ background }}>
            {top != null &&
                <div className={s.top} style={{ background: `${topbackground}` }}>
                    <span>{top}</span>
                </div>
            }
            <div className={s.cardBody}>
                <img src={img} alt={title} />

                <div className={s.body}>
                    <h3 style={iscolor == 'N' ? { color: '#000' } : { width: 'initial' }}>{title}</h3>
                    <div className={s.subHeading}>
                        <span className={s.light} style={iscolor == 'N' ? { color: '#000' } : { width: 'initial' }}>{subHeading} <i className='fa fa-rupee'></i> {rangeLowprice} - <i className='fa fa-rupee'></i> {rangeCutoff}</span>
                    </div>

                    <div className={s.invest}>
                        <img src={EventImg} style={iscolor == 'N' ? { filter: 'invert(1)' } : { filter: 'inherit' }} alt="Invest" />
                        <span style={iscolor == 'N' ? { color: '#000' } : { width: 'initial' }}>{event}</span>
                    </div>
                </div>
            </div>

            <div className={s.buttons}>
                <button onClick={() => {

                    ClevertapReact.initialize(clevertap_key);
                    var ClevertapPayload = {
                        "Source": "SGB Details Page",
                        "Product Type": "SGB",
                        "Issue Name": issueCode,
                        "Issue size": ((issueQty * ((lowprice + highprice) / 2)) / 10000000).toFixed(2) + ' cr',
                    }

                    // console.log("OU_Details Page Viewed", ClevertapPayload)
                    ClevertapReact.event("OU_Details Page Viewed", ClevertapPayload);

                    gotoIPO(issueCode);

                }} className={s.button} style={iscolor == 'N' ? { border: '1px solid #000' } : { border: 'none' }} >
                    {button}
                </button>
            </div>
        </div>
    )
};

export default SGBSection1Card;
