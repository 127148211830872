export const totalPayableAmt = (gsecDetail, invtQty) => {
  if (gsecDetail?.category === "TB") {
    return gsecDetail?.faceValue * invtQty;
  } else {
    return gsecDetail?.markupFaceValue * invtQty;
  }
};

export const checkBalance = (ledgerBalance, gsecDetail, invtQty) => {
  if (gsecDetail?.category === "TB") {
    return ledgerBalance > gsecDetail?.faceValue * invtQty;
  } else {
    return ledgerBalance > gsecDetail?.markupFaceValue * invtQty;
  }
};
