let initialState = {

};

const applicationDataReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'APPLICATION-DATA':
			return action.payload;
		default:
			return state;
	}
};

export default applicationDataReducer;
