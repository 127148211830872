import React, { useEffect, useState } from "react";
import NoImg from "../../../../assets/images/noimg.jpg";
import { dateFormatter } from "../../../../helpers/utils";
import gs_icon from "../../../../assets/images/gs_icon.svg";
import sdl_icon from "../../../../assets/images/sdl_icon.svg";
import T_Bill_Icon from "../../../../assets/images/T_Bill_Icon.svg";

const GsecHeader = ({ gsecDetail }) => {
  const [exptimer, setexptimer] = useState("");
  const [counterInterval, setcounterInterval] = useState({});

  const applyTimer = (closedate) => {
    let date_1 = new Date(closedate);
    let date_2 = new Date();
    let difference = date_1.getTime() - date_2.getTime();
    let expDays = Math.ceil(difference / (1000 * 3600 * 24));
    if (expDays > 1) {
      setexptimer(`${expDays} Days`);
    } else {
      let counterInt = setInterval(() => {
        setexptimer(dateFormatter(new Date(closedate).getTime()));
      }, 1000);
      setcounterInterval(counterInt);
    }
  };

  useEffect(() => {
    clearInterval(counterInterval);
    applyTimer(gsecDetail?.clsdt);
  }, [gsecDetail?.clsdt]);

  return (
    <>
      {/* DESKTOP VIEW */}
      <div className="row align-items-center desk__view">
        <div className="col-md-6">
          <div className="sche_name">
            <div className="sche_name_image">
              <img
                src={
                  gsecDetail?.category === "GS"
                    ? gs_icon
                    : gsecDetail?.category === "TB"
                    ? T_Bill_Icon
                    : gsecDetail?.category === "SD"
                    ? sdl_icon
                    : NoImg
                }
                alt="Img"
              />
            </div>
            <div>
              <h1>{gsecDetail?.schname}</h1>
              <p>
                Application ends in <span>{exptimer}</span>
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          {gsecDetail?.indicativeYield > 0 &&
            <div className="top_induc_yield">
              <p>Indicative yield</p>
              <h1>{gsecDetail?.indicativeYield}%</h1>
            </div>
          }
        </div>
      </div>

      {/* MOBILE VIEW */}
      <div className="mobile__view">
        <div className="sche_name">
          <div className="sche_name_image_mobile">
            <div className="sche_name_image">
              <img
                src={
                  gsecDetail?.category === "GS"
                    ? gs_icon
                    : gsecDetail?.category === "TB"
                    ? T_Bill_Icon
                    : gsecDetail?.category === "SD"
                    ? sdl_icon
                    : NoImg
                }
                alt="Img"
              />
            </div>
          </div>
          <div className="sche_head_box">
            <div className="box__one">
              <h1>{gsecDetail?.schname}</h1>
              {gsecDetail?.indicativeYield > 0 &&
                <div className="top_induc_yield">
                  <p>Indicative yield</p>
                  <h1>{gsecDetail?.indicativeYield}%</h1>
                </div>
              }
            </div>
            <div className="sche_name">
              <p>
                Application ends in <span>{exptimer}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GsecHeader;
