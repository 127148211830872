import React from "react";
import "./OrderConfirmationPage.css";
import check from "../assets/Path 12486.svg";
import logo from "../assets/gsecLogo.jpg";
import { Link, useHistory } from "react-router-dom";
import { useEffect } from "react";
import moment from "moment";
import check2 from "../assets/Frame 2202384.svg";
import useWindowDimensions from "../../../../hooks/screenWidth";
import gs_icon from "../../../../assets/images/gs_icon.svg";
import sdl_icon from "../../../../assets/images/sdl_icon.svg";
import T_Bill_Icon from "../../../../assets/images/T_Bill_Icon.svg";

const OrderConfirmationPage = ({ addGsecResult, invtQty, gsecDetail }) => {
  const history = useHistory();
  const { width } = useWindowDimensions();

  // BACK TO HOME WHEN PAGE REFRESS
  useEffect(() => {
    if (
      Object.keys(gsecDetail).length === 0 ||
      Object.keys(addGsecResult).length === 0
    ) {
      history.push("/");
    }
  }, []);

  // BACK TO HOME WHEN BROWSER BACK BUTTON IS CLICKED
  useEffect(() => {
    const handleBackButton = (event) => {
      history.push("/");
    };
    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);

  return (
    <div className="custom_container">
      <div className="order-confirmation">
        <div className="check-icon">
          {width > 768 ? (
            <img src={check} alt="check" />
          ) : (
            <img src={check2} alt="check" />
          )}
          <h2 className="order-con-title">
            We have received your application.
          </h2>
        </div>

        <div className="order-confirmation-info">
          <div className="order-confirmation-scheme">
            <img
              src={
                gsecDetail?.category === "GS"
                  ? gs_icon
                  : gsecDetail?.category === "TB"
                  ? T_Bill_Icon
                  : gsecDetail?.category === "SD"
                  ? sdl_icon
                  : logo
              }
              alt={gsecDetail?.schname}
            />
            <span>{gsecDetail.schname}</span>
          </div>
          <div className="order-confirmation-scheme-detail">
            <small>No of bonds:</small>
            <span>{invtQty}</span>
          </div>
          <div className="order-confirmation-scheme-detail">
            <small>Listing date:</small>
            <span>{moment(gsecDetail.listingDate).format("D MMM YYYY")}</span>
          </div>
          <div className="order-confirmation-scheme-detail">
            <small>Application no:</small>
            <span>{addGsecResult?.resultData?.transcode.split("|")[0]}</span>
          </div>
        </div>
        <div className="order-schname-detail">
          <div className="row g-4">
            <div className="col-12 schname-logo">
              <img
                src={
                  gsecDetail?.category === "GS"
                    ? gs_icon
                    : gsecDetail?.category === "TB"
                    ? T_Bill_Icon
                    : gsecDetail?.category === "SD"
                    ? sdl_icon
                    : logo
                }
                alt={gsecDetail?.schname}
              />
              <div>
                <span>{gsecDetail.schname}</span>
                <p>T-Bill</p>
              </div>
            </div>
            <div className="col-6">
              <small>No of bonds</small>
              <h3>{invtQty}</h3>
            </div>
            <div className="col-6">
              <small>Total payable amount</small>
              <h3>₹10,185</h3>
            </div>
            <div className="col-6">
              <small>Allotment finalization date</small>
              <h3>
                {moment(gsecDetail.allotmentFinalizationDate).format(
                  "D MMM YYYY"
                )}
              </h3>
            </div>
            <div className="col-6">
              <small>Listing date</small>
              <h3>{moment(gsecDetail.listingDate).format("D MMM YYYY")}</h3>
            </div>
          </div>
        </div>

        <div className="order-confirmation-btn d-none d-md-block">
          <Link to="/gsec_details">
            <button className="btn-exp">Explore more G-secs</button>
          </Link>
          <Link to="/your_applications">
            <button className="btn-view">View Application Status</button>
          </Link>
        </div>

        <div className="order-confirmation-what-next">
          <h2>What is next?</h2>
          <p>Allotment of securities</p>
          <small>
            Investment will refelct in portfolio holdings post allotment on{" "}
            <span>{moment(gsecDetail.listingDate).format("D MMM YYYY")}</span>
          </small>
        </div>

        <div className="order-confirmation-btn d-md-none">
          <Link to="/gsec_details">
            <button className="btn-exp">Explore G-secs</button>
          </Link>
          <Link to="/your_applications">
            <button className="btn-view">View Status</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OrderConfirmationPage;
