import "./ncd-application-02.css";
import { useEffect, useState } from "react";
import { ncdDateFormatter } from "../../helpers/utils";
import { CMS_URL, clevertap_key } from "../../vars/url";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import NoImg from "../../assets/images/noimg.jpg";
import { Redirect, useHistory } from "react-router-dom";
import LoaderImg from "../../assets/images/loader.svg";
import ClevertapReact from "clevertap-react";
import { applicationData as setApplicationData } from "../../store/action/applicationData";
import SweetAlert from "react-bootstrap-sweetalert";
import "./NewNcd.css";
import Group18219 from "../../assets/images/LandingPage/Group 18219.svg";
import IIFLSec from "../../assets/images/LandingPage/IIFL Sec.svg";
import holder_remove from "../../assets/images/NCDDetail/holder_remove.svg";
import add_holder_icon from "../../assets/images/NCDDetail/add_holder_icon.svg";
import SideBar from "../SideBar/SideBar";
import { actionOthersDetail } from "../../store/ncdseries/ncdAction";
import Info from "../../assets/images/NCDDetail/info_icon.svg";

const DematUi = ({
  dematDetails,
  removeDmat,
  changedemaatname,
  changedemaatpan,
  setalert,
}) => {
  const validatePan = (value, i) => {
    if (!/[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(value.toUpperCase())) {
      setalert(`Holder ${2 + i} Pan no. must be valid.`);
      changedemaatpan("", i);
      return false;
    }
  };

  return (
    <div className={"dematrepeat"}>
      {dematDetails.map((el, i) => (
        <div className={"singledemat"} key={i}>
          <div className="row me-md-5">
            <div className="col-md-10">
              <div className="holder_header mt-5 mb-3">
                <h4>Holder {2 + i} Details</h4>
                <img
                  onClick={() => removeDmat(i)}
                  src={holder_remove}
                  alt="holder_remove"
                />
              </div>
            </div>
          </div>

          <div className={""}>
            <div className="row">
              <div className="col-md-5">
                <span>Full Name</span>
                <input
                  className="mt-2"
                  type="text"
                  placeholder="Full Name"
                  value={el.fullName}
                  onChange={(e) => changedemaatname(e.target.value, i)}
                />
              </div>
              <div className="col-md-5">
                <span>PAN No.</span>
                <br />
                <input
                  className="mt-2"
                  type="text"
                  placeholder="PAN No."
                  value={el.pan}
                  onChange={(e) => changedemaatpan(e.target.value, i)}
                  onBlur={(e) => validatePan(e.target.value, i)}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const Ncdapplication2 = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const applicationData = useSelector((state) => state.applicationData);
  const user = useSelector((state) => state.loggedIn.user);
  const { othersDetail } = useSelector((state) => state.ncdReducer);
  const [cat, setCat] = useState("IND|RET");
  const [typeapply, settypeapply] = useState("Self");

  const [dematype, setdematype] = useState("NSDL");
  const [mobileno, setmobileno] = useState(user.mobileNo || "");
  const [useremail, setuseremail] = useState(user.email || "");
  const [alert, setalert] = useState("");
  const [userpan, setuserpan] = useState(() => {
    if (user.clientType === "NONIIFLCLIENT") {
      return user.panNo;
    } else {
      return user.clientcode;
    }
  });
  const [username, setusername] = useState(user.firstName || "");
  const [userdpid, setuserdpid] = useState(user.dpid || "");
  const [userbeneficiaryID, setuserbeneficiaryID] = useState(
    user.beneficiaryID || ""
  );
  const [counter, setCounter] = useState("");
  const [dematDetails, setDematDetails] = useState(
    applicationData?.dematDetailsapp || []
  );
  const [loading, setLoading] = useState(true);

  const nextstepgo = () => {
    if (applicationData?.ncdhistory) {
      if (user.clientType === "NONIIFLCLIENT" || typeapply !== "Self") {
        if (dematype === "NSDL") {
          if (userdpid.length != 8) {
            alert("DP ID invalid");
            return false;
          }
          if (userdpid.startsWith("IN") == false) {
            alert("Please enter valid NSDL DP ID");
            return false;
          }
        }
      }
      dispatch(
        setApplicationData({
          ...applicationData,
          ncdcat: cat,
          clientcode: userpan,
        })
      );
    } else {
      if (cat === "") {
        setalert("Please select category");
        return false;
      }

      // validation part for noniiflclient or other client start
      if (user?.clientType === "NONIIFLCLIENT" || typeapply != "Self") {
        if (username.replace(" ", "").length == 0) {
          setalert("Enter Full name");
          return false;
        }

        if (!/^[a-zA-Z ]*$/.test(username)) {
          setalert(
            "Name is not valid,Please enter your full name, Eg: John Doe"
          );
          return false;
        }
        if (!/[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(userpan.toUpperCase())) {
          setalert("Pan no. must be valid.");
          return false;
        }
        if (!/^\d{10}$/.test(mobileno)) {
          setalert("Mobile number must be ten digits.");
          return false;
        }
        if (
          !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            useremail
          )
        ) {
          setalert("E-mail must be valid.");
          return false;
        }
        if (dematype != "CDSL") {
          if (userdpid.length != 8) {
            setalert("DP ID invalid");
            return false;
          }
          if (userdpid.startsWith("IN") == false) {
            setalert("Please enter valid NSDL DP ID");
            return false;
          }
          if (isNaN(userdpid.substring(2, 8))) {
            setalert("Please enter valid NSDL DP ID");
            return false;
          }
          if (userbeneficiaryID.length != 8) {
            setalert("Invalid Beneficiary.");
            return false;
          }
        } else {
          if (userbeneficiaryID.length != 16) {
            setalert("Invalid Beneficiary.");
            return false;
          }
        }

        if (dematDetails.length === 1) {
          if (!dematDetails[0]?.fullName || !dematDetails[0]?.pan) {
            setalert("Enter Holder 2 details");
            return false;
          }
        }

        if (dematDetails.length === 2) {
          if (!dematDetails[0]?.fullName || !dematDetails[0]?.pan) {
            setalert("Enter Holder 2 details");
            return false;
          }
          if (!dematDetails[1]?.fullName || !dematDetails[1]?.pan) {
            setalert("Enter Holder 3 details");
            return false;
          }
        }
      }
      // validation part for noniiflclient or other client end

      dispatch(
        setApplicationData({
          ...applicationData,
          ncdcat: cat,
          clientcode:
            user?.clientType === "IIFLCLIENT" && typeapply === "Self"
              ? user.clientcode
              : userpan,
          mobileNo: mobileno,
          email: useremail,
          panNo: userpan,
          fstname: username?.split(" ")[0],
          midname:
            username?.split(" ").length > 2 ? username?.split(" ")[1] : "",
          lstname: username ? username : user.fullname,
          applyfr: dematype,
          dpid: userdpid,
          beneficiaryID: userbeneficiaryID,
          selfoth: typeapply,
          dematDetailsapp: dematDetails,
        })
      );

      if (typeapply === "Other") {
        dispatch(
          actionOthersDetail({
            fstname: username,
            panNo: userpan,
            email: useremail,
            mobileNo: mobileno,
            dpid: userdpid,
            beneficiaryID: userbeneficiaryID,
          })
        );
      }
    }

    // Clevertap start
    ClevertapReact.initialize(clevertap_key);
    function getSeriesNamect(array, keysearch) {
      var fianalArr = [];
      array.forEach((element) => {
        fianalArr.push(element[keysearch]);
      });
      return fianalArr;
    }
    var ClevertapPayload = {
      Source: "OneUp HomePage",
      Step: "2",
      "Product Type": "NCD",
      "Issue Name": applicationData.ncdfulldetails?.schemename,
      "Bid Number": applicationData?.series?.filter(
        (item) => item.check === true
      )?.length,
      "Investment Amount": applicationData.mainslidervalue,
      "Price Type": "",
      "Applying for": typeapply,
      "Investor category": cat,
      "payment type": "",
      "Failure Message": "",
      "Application number": "",
      Amount: "",
      Series: applicationData?.series
        ?.filter((item) => item.check === true)
        .map((item) => item.seriesNumber)
        .join(","),
    };
    ClevertapReact.event("OU_Application Step", ClevertapPayload);

    // Clevertap End
    setLoading(true);
    setTimeout(function () {
      history.push("/ncd-application-step-3");
    }, 800);
  };

  const counterstart = () => {
    setInterval(() => {
      setCounter(
        ncdDateFormatter(
          new Date(applicationData?.ncdfulldetails?.enddate).getTime()
        )
      );
    }, 1000);
    setLoading(false);
  };

  const hidesweeetalert = () => {
    setalert("");
  };

  const redirectsalert = (path = "/") => {
    history.push(path);
  };

  const setForOther = () => {
    setusername(othersDetail.fstname);
    setuserpan(othersDetail.panNo);
    setuseremail(othersDetail.email);
    setmobileno(othersDetail.mobileNo);
    setuserdpid(othersDetail.dpid);
    setuserbeneficiaryID(othersDetail.beneficiaryID);
    setCat("IND|RET");
  };
  const setForSelf = () => {
    setusername(user.firstName + user.middleName + user.surName);
    setuserpan(user.panNo); /////////////////////////////////////////////// WORK HERE/////////////
    setuseremail(user.email);
    setmobileno(user.mobileNo);
    setuserdpid(user.dpid);
    setuserbeneficiaryID(user.beneficiaryID);
    setCat("IND|RET");
  };
  // Demat section functions start
  const removeDmat = async (i) => {
    let values = dematDetails;
    values.splice(i, 1);

    setDematDetails([...values]);
  };

  const changedemaatname = (val, i) => {
    let values = dematDetails;
    values[i].fullName = val;
    setDematDetails([...values]);
  };

  const changedemaatpan = (val, i) => {
    let values = dematDetails;
    values[i].pan = val.toUpperCase();
    setDematDetails([...values]);
  };

  const addDmat = () => {
    let dematadd = dematDetails;
    dematadd.push({ fullName: "", pan: "" });
    setDematDetails([...dematadd]);
  };

  // Demat section functions ends

  useEffect(() => {
    if (applicationData?.ncdfulldetails?.enddate) {
      counterstart();
    }
  }, [applicationData?.ncdfulldetails]);

  // REDIRECT ON PAGE REFRESSS TO HOME PAGE
  if (!applicationData.ncdfulldetails?.issuecode) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <div className="zoom_wrapper">
        {loading == true && (
          <div className="loading_screen">
            <img src={LoaderImg} alt="loading..." />
            <p>Please wait while fetching..</p>
          </div>
        )}
      </div>

      {alert != "" && (
        <SweetAlert
          custom
          showCancel
          showCloseButton
          error
          title="Alert!"
          cancelBtnText="Back to home"
          cancelBtnBsStyle="light"
          onConfirm={hidesweeetalert}
          onCancel={hidesweeetalert}
        >
          {alert}
        </SweetAlert>
      )}

      <div className="ncd_haeder_step1 d-md-block d-none">
        <div className="custom_container">
          <img
            onClick={() => history.push("/")}
            width={100}
            src={Group18219}
            alt="logo"
          />
          <span className="px-3">Powered by</span>
          <img src={IIFLSec} alt="logo" />
        </div>
      </div>

      <div className="d-md-none mobile_top_haeder">
        <SideBar />
      </div>

      <div className="ncd_step_one_header">
        <div className="custom_container">
          <div className="row">
            <div className="col-md-6">
              <p className="investing_as">Investing as</p>
              <div className="d-flex justify-content-md-start justify-content-between align-items-center">
                <div className="invest-as category_dropdown">
                  <select value={cat} onChange={(e) => setCat(e.target.value)}>
                    <option value="">Choose Category</option>
                    {applicationData?.ncdfulldetails?.categoryList?.map(
                      (e, i) => {
                        return (
                          <option
                            key={i}
                            value={`${e.categoryCode}|${e.categoryType.slice(
                              0,
                              3
                            )}`}
                          >
                            {e.categoryName}
                          </option>
                        );
                      }
                    )}
                  </select>
                  <div className="category_dropdown_select">Select</div>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-md-block d-none">
              <div className="d-flex align-items-md-start align-items-center justify-content-end">
                <img
                  onClick={() =>
                    history.push(
                      "/ncd_details/" +
                        applicationData?.ncdfulldetails.issuecode
                    )
                  }
                  className="me-4 ncd_logo_name"
                  src={
                    applicationData?.cmsdata?.logo
                      ? `${CMS_URL}${applicationData?.cmsdata.logo}`
                      : NoImg
                  }
                  alt="Ellipse1860"
                />
                <div className="ncd_header_top_step1">
                  <h2>{applicationData?.ncdfulldetails?.schemename}</h2>
                  <p className="d-flex">
                    Ends in{" "}
                    <div
                      className="px-2"
                      dangerouslySetInnerHTML={{ __html: counter }}
                    ></div>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="custom_container ncd_step_two_wrap">
        <div className="pt-5">
          <p className="apply_for">Applying for</p>
          <div className="pt-2 apply_for_radio">
            <div
              className={
                typeapply == "Self" ? "radio_label active" : "radio_label"
              }
            >
              <input
                onChange={(e) => {
                  settypeapply(e.target.value);
                  setForSelf();
                }}
                defaultChecked={
                  applicationData?.selfoth === "Self" ? true : false
                }
                type="radio"
                id="self"
                name="apply_for"
                value="Self"
              />
              <label className="ms-2" htmlFor="self">
                Self
              </label>
            </div>

            <div
              className={
                typeapply !== "Self" ? "radio_label active" : "radio_label"
              }
            >
              <input
                onChange={(e) => {
                  settypeapply(e.target.value);
                  setForOther();
                }}
                defaultChecked={
                  applicationData?.selfoth === "Other" ? true : false
                }
                type="radio"
                id="other"
                name="apply_for"
                value="Other"
              />
              <label className="ms-2" htmlFor="other">
                Others
              </label>
            </div>
          </div>
        </div>

        {(user.clientType === "NONIIFLCLIENT" || typeapply !== "Self") && (
          <>
            <div className="">
              <h4 className="py-5"> 1. Applicant’s details</h4>

              <div className="row g-5">
                <div className="col-md-5">
                  <div className="single_input">
                    <label>Full name</label>
                    <input
                      type="text"
                      value={username}
                      onChange={(e) => setusername(e.target.value)}
                      placeholder="Enter full name as per PAN"
                    />
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="single_input">
                    <label>PAN card no.</label>
                    <input
                      type="text"
                      value={userpan?.toUpperCase()}
                      onChange={(e) => setuserpan(e.target.value)}
                      readOnly={typeapply === "Self" ? true : false}
                      placeholder="Enter a valid PAN card no."
                    />
                  </div>
                </div>

                <div className="col-md-5">
                  <div className="single_input">
                    <label>Mobile number</label>
                    <input
                      type="number"
                      value={mobileno}
                      onChange={(e) => setmobileno(e.target.value)}
                      readOnly={typeapply === "Self" ? true : false}
                      placeholder="Enter 10 digit mobile number"
                    />
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="single_input">
                    <label>Email ID</label>
                    <input
                      type="text"
                      value={useremail}
                      onChange={(e) => setuseremail(e.target.value)}
                      readOnly={typeapply === "Self" ? true : false}
                      placeholder="Enter a valid email ID"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="">
              <h4 className="py-5">2. Demat account details</h4>

              <div className="pb-5 apply_for_radio">
                <div
                  className={
                    dematype === "NSDL" ? "radio_label active" : "radio_label"
                  }
                >
                  <input
                    onChange={(e) => {
                      setdematype(e.target.value);
                      setuserbeneficiaryID("");
                      setuserdpid("");
                    }}
                    defaultChecked={true}
                    type="radio"
                    id="nsdl"
                    name="demat_acc"
                    value="NSDL"
                  />
                  <label className="ms-2" htmlFor="nsdl">
                    NSDL
                  </label>
                </div>
                <div
                  className={
                    dematype !== "NSDL" ? "radio_label active" : "radio_label"
                  }
                >
                  <input
                    onChange={(e) => {
                      setdematype(e.target.value);
                      setuserbeneficiaryID("");
                      setuserdpid("");
                    }}
                    type="radio"
                    id="cdsl"
                    name="demat_acc"
                    value="CDSL"
                  />
                  <label className="ms-2" htmlFor="cdsl">
                    CDSL
                  </label>
                </div>
              </div>

              <div className="row g-5">
                {dematype === "NSDL" && (
                  <div className="col-md-5">
                    <div className="single_input">
                      <div className="lable_info_icon">
                        <label>DP ID</label>
                        <small>
                          <span>
                            <img src={Info} alt="info" width={"16px"} />
                          </span>
                          <div className="dpid_notif">
                            The Depository Participant Identification, or DP ID,
                            is a number assigned by the NSDL to a brokerage
                            firm. You can find this in the account details
                            section of your brokers app.
                            <div className="angle_icon"></div>
                          </div>
                        </small>
                      </div>
                      <input
                        type="text"
                        value={userdpid}
                        onChange={(e) =>
                          setuserdpid(e.target.value.toUpperCase())
                        }
                        placeholder="Enter first 8 digits of your demat ID"
                      />
                    </div>
                  </div>
                )}

                <div className="col-md-5">
                  <div className="single_input">
                    <div className="lable_info_icon">
                      <label>Beneficiary ID</label>
                      <small>
                        <span>
                          <img src={Info} alt="info" width={"16px"} />
                        </span>
                        <div className="dpid_notif bef_noteif">
                          The BO ID (Beneficiary Owner Identification Number) is
                          used to identify your unique demat account held with
                          the depository. You can find this in the account
                          details section of your brokers app.
                          <div className="angle_icon"></div>
                        </div>
                      </small>
                    </div>
                    <input
                      type="text"
                      value={userbeneficiaryID}
                      onChange={(e) => setuserbeneficiaryID(e.target.value)}
                      placeholder={
                        dematype === "NSDL"
                          ? "Enter last 8 digits of your demat ID"
                          : "Enter your 16 digit Demat ID"
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Add demat section start */}
            {Object.keys(user).length > 0 && (
              <>
                <DematUi
                  dematDetails={dematDetails}
                  removeDmat={removeDmat}
                  changedemaatname={changedemaatname}
                  changedemaatpan={changedemaatpan}
                  setalert={setalert}
                />

                {dematDetails.length < 2 && (
                  <>
                    <button
                      className={"addHolder pt-5" + " hover-animate"}
                      onClick={addDmat}
                    >
                      <div className="add_holder">
                        <img src={add_holder_icon} alt="Add Holder" />
                      </div>
                      <span>Add another holder</span>
                    </button>
                  </>
                )}
              </>
            )}
            {/* Add demat section ends */}
          </>
        )}

        <div className="step_one_btn py-5">
          <button onClick={(e) => nextstepgo()}>Save changes</button>
        </div>
      </section>
    </>
  );
};

export default Ncdapplication2;
