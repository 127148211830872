import React from "react";
import "./GsecAppCard.css";
import moment from "moment/moment";
import NoImg from "../../assets/images/noimg.jpg";
import DeleteImg from "../../assets/images/ApplicationCard/delete_black_24dp.svg";
import gs_icon from "../../assets/images/gs_icon.svg";
import sdl_icon from "../../assets/images/sdl_icon.svg";
import T_Bill_Icon from "../../assets/images/T_Bill_Icon.svg";

const GsecAppCard = ({ setGsecDetail, details, setGsecCancelModal }) => {
  return (
    <div className="gsec_applied_card">
      {(new Date(details.closeDate).getTime() > new Date().getTime() ||
        isNaN(new Date(details.closeDate).getTime())) && (
        <div
          onClick={() => {
            setGsecDetail(details);
            setGsecCancelModal(true);
          }}
          className="delete_gsec"
        >
          <img src={DeleteImg} alt="Delete" />
        </div>
      )}

      <div className="d-md-none gsec_applied_card_top">
        <div className="mob_card">
          <img
            src={
              details?.instrumentType.trim() === "GS"
                ? gs_icon
                : details?.instrumentType.trim() === "TB"
                ? T_Bill_Icon
                : details?.instrumentType.trim() === "SD"
                ? sdl_icon
                : NoImg
            }
            alt="Img"
          />
          <h2>{details.issue_name}</h2>
        </div>
        <div>
          <p>
            <span>Applied</span>
            {moment(details.openDate).format("D MMMM")} -{" "}
            {moment(details.closeDate).format("D MMMM")}
          </p>
        </div>
      </div>

      <div className="d-md-block d-none">
        <div className="gsec_applied_card_top">
          <div>
            <img
              src={
                details?.instrumentType.trim() === "GS"
                  ? gs_icon
                  : details?.instrumentType.trim() === "TB"
                  ? T_Bill_Icon
                  : details?.instrumentType.trim() === "SD"
                  ? sdl_icon
                  : NoImg
              }
              alt="Img"
            />
          </div>
          <div>
            <h2>{details.issue_name}</h2>
            <p>
              <span>Applied</span> <small>Application Issue Date: </small>
              {moment(details.openDate).format("D MMMM")} -{" "}
              {moment(details.closeDate).format("D MMMM")} | Indicative Yield:{" "}
              {details.scheduleData.indicativeYield}%
            </p>
          </div>
        </div>
      </div>

      <div className="gsec_applied_card_bottom">
        <div>
          <small>Application No</small>
          <p>{details.applicationNo}</p>
        </div>
        <div className="d-md-none">
          <small>Indicative Yield</small>
          <p>{details.scheduleData.indicativeYield}%</p>
        </div>
        <div>
          <small>Total Payable Amount</small>
          <p>₹{details.amountpaid.toLocaleString("en-IN")}</p>
        </div>
        <div>
          <small>No. of Bond</small>
          <p>{details.scheduleData.bids[0].noOfShares}</p>
        </div>
        <div>
          <small>Client ID/PAN No.</small>
          <p>{details.clientCode}</p>
        </div>
      </div>
    </div>
  );
};

export default GsecAppCard;
