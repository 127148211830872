import React, { useEffect, useState } from "react";
import { getSgbDetails, getOpenIssuesList, getIssueDetailsFromCmsByCode } from "../../services/issuesServices";
import { useHistory } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import s from '../IPODetails/IPODetails.module.scss';
import "./SGBDetailsPage.css";
import SideBar from "../SideBar/SideBar";
import { CMS_URL } from '../../vars/url';
import NoImg from '../../assets/images/noimg.jpg';
import Footer from "../Footer/Footer";
import SGB48 from "../../assets/images/Sgb/Soverign Gold Bonds_48 Px.webp";
import G20899 from "../../assets/images/Sgb/Group 20899.svg";
import G20903 from "../../assets/images/Sgb/Group 20903.svg";
import G20898 from "../../assets/images/Sgb/Group 20898.svg";
import G20897 from "../../assets/images/Sgb/Group 20897.svg";
import G20875 from "../../assets/images/Sgb/Group 20875.svg";
import G20924 from "../../assets/images/Sgb/Group 21013.svg";
import G20890 from "../../assets/images/Sgb/Group 20890.svg";
import G13500 from "../../assets/images/Sgb/group-13500.svg";
import G13497 from "../../assets/images/Sgb/group-13497.svg";
import G13445 from "../../assets/images/Sgb/Group 13445.svg";
import G13752 from "../../assets/images/Sgb/group-13752.svg";
import G13643 from "../../assets/images/Sgb/Group 13643.svg";
import CheckCircleB24 from "../../assets/images/Sgb/check_circle_black_24dp.svg";
import Maskgroup94 from "../../assets/images/Sgb/Mask Group 94.webp";
import playcircleblack24 from "../../assets/images/Sgb/play_circle_black_24dp.svg";
import Greenarrow from "../../assets/images/Sgb/green-arrow.svg";
import redarrow from "../../assets/images/Sgb/red-arrow.svg";
import polygon70 from "../../assets/images/Sgb/Polygon 70.svg";
import Polygon25 from "../../assets/images/Sgb/Polygon 25.svg";
import Physicalgold from "../../assets/images/Sgb/NoPath - Copy (2).webp";
import G18776 from "../../assets/images/Sgb/Group 18776.webp";
import LoaderImg from "../../assets/images/loader.svg";
import { useSelector } from "react-redux";
import Login from "../Login";
import SGBSection1Card from "../SGBSection1Card/SGBSection1Card";
import Group18219 from "../../assets/images/LandingPage/Group 18219.svg";
import IIFLSec from "../../assets/images/LandingPage/IIFL Sec.svg";


const SGBDetailsPage = (props) => {
  const { handleRange, selectedRange, customrange, disableranger, handleCustRange, handleDisRange } = props;
  const user = useSelector((state) => state.loggedIn);
  let history = useHistory();
  const [details, setDetails] = useState({});
  const [sgbData, setSgbData] = useState([]);
  const [hidealert, setHideAlert] = useState(false);
  const [login, setLogin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openIssue, setopenIssue] = useState([]);
  const [selectedgrams, setSelectedGrams] = useState(1);
  const [leftTime, setLeftTime] = useState({
    days: null,
    hours: null,
    minutes: null,
    seconds: null,
  });
  useEffect(() => {
    let response = getSgbDetails();
    response
      .then((res) => {
        fetchSgbData(res.data.resultData[0]);
        getRemainingTimeToApply(res.data.resultData[0].clsdt);
        handleRange(res.data.resultData[0].highprice);
        setLoading(false);
      })
      .catch((err) => console.log(err));
    let locals = window.localStorage;
    if (locals.getItem("user") !== undefined) {
      console.log(JSON.parse(locals.getItem("user")));
    }
  }, []);
  const toggleLogin = () => {
    setLogin(!login);
  };
  const fetchSgbData = (data) => {
    setSgbData(data);
    fetchopenissues();
  };
  const getRemainingTimeToApply = (closeDate) => {
    // Set the date we're counting down to
    var countDownDate = new Date(closeDate).getTime();

    // Update the count down every 1 second
    // eslint-disable-next-line no-unused-vars
    var x = setInterval(function () {
      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      setLeftTime({
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      });
    }, 1000);
  };

  const getGoldReturnsEightYears = (goldprice) => {
    let totalReturns =
      (goldprice * 10) * [1 + (8 * 2.5) / 100] + (goldprice * 10) * (0.2);
    return Number(totalReturns).toLocaleString();
  };
  const handleSlider = (e) => {
    if (e.target.value < 200000) {
      handleRange(e.target.value);
      handleGrams(e.target.value);
    }

    let ranger = document.getElementById("sgb-home-slider");
    let bubble = document.getElementById("price");
    if (e.target.value > 200000) {
      e.preventDefault()
      handleRange(200000)
      handleCustRange(200000)
    } else {
      handleDisRange(false)
    }
    const val = ranger && ranger.value;
    const min = ranger && ranger.min ? ranger.min : 15567;
    const max = ranger && ranger.max ? ranger.max : 240000;
    const newVal = Number(((val - min) * 100) / (max - min));
    if (ranger.value >= 200000) {
      bubble.style.float = 'right';
      bubble.style.left = 'unset';
      bubble.style.border = 'none';
    }
    if (ranger.value < 200000) {
      bubble.style.left = `calc(${newVal}% + (${-4.1 - newVal * 0.015}rem))`;
      bubble.style.float = 'none';
    }

  };
  const handleCustomRange = (e) => {
    handleCustRange(e.target.value)
  }
  const handleCustomRangeBlur = () => {
    if (customrange > 200000 && customrange >= (sgbData.highprice * 4000)) {
      handleRange(sgbData.highprice * 4000)
      setSelectedGrams(4000)
      handleCustRange(sgbData.highprice * 4000)
      handleDisRange(false)
    } else {

      if (customrange > 200000 && customrange < sgbData.highprice * 4000) {
        let cgrams = Math.floor(customrange / sgbData.highprice)
        let finalPrice = cgrams * sgbData.highprice
        handleRange(finalPrice)
        setSelectedGrams(cgrams)
        handleDisRange(false)
        handleCustRange(finalPrice)
      } else {
        handleRange(Math.floor(200000 / sgbData.highprice) * sgbData.highprice)
        setSelectedGrams(Math.floor(200000 / sgbData.highprice))
        handleDisRange(false)
        handleCustRange(200000)
      }
    }

  }
  const handleGrams = (price) => {
    setSelectedGrams(price / sgbData.highprice);
  };
  const handleSGBApply = () => {
    if (Object.keys(user).length > 0) {
      if (user.user.clientType === "NONIIFLCLIENT") {
        setHideAlert(true);
      } else {
        setHideAlert(false);
        history.push("/sgb_apply");
      }
    } else {
      toggleLogin(!login);
    }
  };
  const handleALertBox = () => {
    setHideAlert(false);
  };
  const fetchopenissues = async () => {
    let otsdata = []
    try {
      let detail = getOpenIssuesList('IPO').then(response => {
        let odata = []
        if (response.data.statusCode === 200) {
          odata = response.data.resultData
        }
        try {

          let cmsdata = {}
          if (odata && odata.length > 0) {
            odata.map((e) => {
              getIssueDetailsFromCmsByCode(e.issuecode).then(res => {
                cmsdata = res.data.result
                e.cmsdata = cmsdata
                otsdata.push(e);
              })
            })
          }

          setopenIssue(odata)

        }
        catch (error) {
          console.log(error);
        }

      })
    }
    catch (error) {
      console.log(error);
    }
  }
  const gotoIPO = (issueCode) => {
    window.location.href = "/ipo_details/" + issueCode;
  };

  return (

    <>

      {loading === true && (
        <div className='loading_screen loading_inside'>
          <img src={LoaderImg} alt='loading...' />
        </div>
      )}
      {login && <Login toggleLogin={toggleLogin} />}
      <SideBar toggleLogin={toggleLogin} />
      <div className={`${s.ncd_haeder} d-md-block d-none`}>
        <div className="">
          <img onClick={() => history.push('/')} width={100} src={Group18219} alt="logo" />
          <span className="px-3">Powered by</span>
          <img src={IIFLSec} alt="logo" />
        </div>
      </div>
      <div className='sgb-dp-container'>
        {/* login popup */}

        {/* alert box */}
        {hidealert && (
          <SweetAlert error title='Alert!' onConfirm={handleALertBox}>
            Since you dont't have a DEMAT account with IIFL, you can't apply for
            SGB at the moment.
          </SweetAlert>
        )}
        {/* headers */}
        <div class='sgb-dp-header'>
          <div class='sgb-dp-header-left'>
            <div class='sgb-dp-header-inner-left-top'>
              <img src={SGB48} alt='' />
              <div>
                <h1>Sovereign Gold Bond</h1>
                <p>{sgbData.schname}</p>
              </div>
            </div>
          </div>
          <div class='header-right'>
            <div class='background-color'></div>
            <h1>
              <i class="fa fa-rupee mrgR3"></i>{sgbData.highprice} <span>/1 gm</span>
            </h1>
            <p>Minimum Investment</p>
          </div>
          <div class='header-left-bottom'>
            <p>2.5% Additional Returns</p>
            <div>
              <p>
                Price: <span><i class="fa fa-rupee mrgR3"></i>{sgbData.highprice}/gm</span>
              </p>
            </div>
          </div>
        </div>
        {/* sgb details */}
        <section id='SGB-details'>
          <div class='background-color'></div>
          <div class='sgb-details-container'>
            <div class='sgb-details-card'>
              <p class='sgb-detail-card-heading'>Highly Secure</p>
              <div class='img'>
                <img src={G20903} alt='' />
              </div>
              <div class='sgb-detail-card-info'>
                <h3>Issued by RBI</h3>
                <p>
                  No issue of storage & <br />
                  high purity maintained
                </p>
              </div>
            </div>
            <div class='sgb-details-card'>
              <p class='sgb-detail-card-heading'>
                Tax Free <br class='mobile-line-breaks' />
                Fixed returns
              </p>
              <div class='img'>
                <img src={G20898} alt='' />
              </div>
              <div class='sgb-detail-card-info'>
                <h3>Paid Annually</h3>
                <p>No tax on capital gain</p>
              </div>
            </div>
            <div class='sgb-details-card'>
              <p class='sgb-detail-card-heading'>Application Closes in</p>
              <div class='img'>
                <img src={G20899} alt='' />
              </div>
              <div class='sgb-detail-card-info'>
                <h3>{!isNaN(leftTime.days) && leftTime.days} Days</h3>
                <p>{`${leftTime.hours ? leftTime.hours : 0}h ${leftTime.minutes
                  }m ${leftTime.seconds}s`}</p>
              </div>
            </div>
            <div class='sgb-details-card'>
              <h2>Invest Now!</h2>
              <p>{sgbData.schname}</p>
              <p>How much do you want to invest?</p>
              <div class='price-input'>
                <div id='price' class='sgb-details-price-bubble pricebubble'>
                  <i className="fa fa-rupee mrgR3"></i>
                  {(selectedRange < 200000) ?
                    selectedRange !== 0 && `${Number(selectedRange).toLocaleString()}`
                    :
                    <input
                      type='number'
                      min={200000}
                      value={customrange}
                      className="sgb-more-range-input"
                      onChange={handleCustomRange}
                      onBlur={handleCustomRangeBlur}
                      onKeyUp={(e) => {
                        e.preventDefault()
                        if (e.key === 'Enter') {
                          handleCustomRangeBlur()
                        }
                      }}
                      autoFocus={true}
                    />
                  }

                </div>
                <input
                  class='sgb-details-input'
                  value={selectedRange}
                  type='range'
                  name='price'
                  id='sgb-home-slider'
                  min={sgbData.highprice}
                  step={sgbData.highprice}
                  max='240000'
                  onChange={handleSlider}
                  disabled={disableranger}
                />
                <p class='sgb-details-price-labels'>
                  <span><i class="fa fa-rupee mrgR3"></i>{Number(sgbData.highprice).toLocaleString()}</span>
                  <span><i class="fa fa-rupee mrgR3"></i>2L</span>
                  <span>{">"}<i class="fa fa-rupee mrgR3"></i>2L</span>
                </p>
              </div>

              <div class='quantity'>
                <p>Total Qty</p>
                <p>{selectedgrams} gms</p>
              </div>
              <div class='invest-cta'>
                <a href='#' onClick={() => handleSGBApply()}>
                  Invest Now
                </a>
              </div>
            </div>
            <div class='sgb-details-card'>
              <p class='sgb-detail-card-heading'>Additional Charges</p>
              <div class='img'>
                <img src={G20897} alt='' />
              </div>
              <div class='sgb-detail-card-info'>
                <p>
                  No making or <br class='desktop-line-breaks' />
                  Storage <br class='mobile-line-breaks' />
                  Charges
                </p>
              </div>
            </div>
            <div class='sgb-details-card'>
              <p class='sgb-detail-card-heading'>Tradable in Market</p>
              <div class='img'>
                <img src={G20875} alt='' />
              </div>
              <div class='sgb-detail-card-info'>
                <p>
                  Sell on stock market or <br />
                  Sell Bond.
                </p>
              </div>
            </div>
            <div class='sgb-details-card'>
              <p class='sgb-detail-card-heading'>Maturity Of Gold Bonds</p>
              <div class='img'>
                <img src={G20924} alt='' />
              </div>
              <div class='sgb-detail-card-info'>
                <p>Early exit in 5 years.</p>
              </div>
            </div>
          </div>
        </section>
        {/* sgbgolds */}
        <section id='goldvs'>
          <div class='top-bg-img'>
            <img src={G20890} alt='' />
            <img src={G13500} alt='' />
            <img src={G13497} alt='' />
            <img src={G13497} alt='' />
          </div>
          <div class='down-bg-img'>
            <img src={G13752} alt='' />
            <img src={G13643} alt='' />
          </div>
          <h1>
            Gold vs Sovereign Gold <br class='mobile-line-breaks' />
            Bonds
          </h1>
          <div class='benefits-container'>
            <div class='benefits'>
              <div class='rectangle-1'></div>
              <h2>
                Benefits of Sovereign <br />
                Gold Bond
              </h2>
              <ul>
                <li>
                  <img src={CheckCircleB24} alt='' />
                  <span>No making or storage charges</span>
                </li>
                <li>
                  <img src={CheckCircleB24} alt='' />
                  <span>
                    You get an interest of 2.5% for holding
                    {/* <br class='mobile-line-breaks' /> */}
                     
                  </span>
                </li>
                <li>
                  <img src={CheckCircleB24} alt='' />
                  <span>No GST. You get tax free profit.</span>
                </li>
                <li>
                  <img src={CheckCircleB24} alt='' />
                  <span>Safe and secure</span>
                </li>
              </ul>
              <hr />
              <div class='video-section'>
                <div class='video'>
                  <img src={Maskgroup94} alt='' />
                  <a href="https://www.youtube.com/watch?v=Krs8MFHlEIg" rel="noreferrer noopener" target="__blank" ><img src={playcircleblack24} alt='' /></a>
                </div>
                <div class='video-info'>
                  <h2>
                    How are sovereign Gold Bonds <br />
                    better than Physical Gold?
                  </h2>
                  <p>
                    <img src={G13445} alt='' />
                    <span>Youtube</span>
                    <span>·</span>
                    <span>2 days ago</span>
                  </p>
                </div>
              </div>
            </div>
            <div class='comparisons'>
              <div class='rectangle-1'></div>
              <h2>8 years returns comparison</h2>
              <div class='comparison-container'>
                <div class='comparsion-1'>
                  <div class='category'>
                    <p>Sovereign Gold Bond</p>
                    <img src={SGB48} alt='' />
                    <h2><i class="fa fa-rupee mrgR3"></i>{Number(sgbData.highprice * 10).toLocaleString()}</h2>
                    <p>For 10 Gm of Gold</p>
                  </div>
                  <div class='points'>
                    <ul>
                      <span>&nbsp;</span>
                      <hr />
                      <li>
                        <img src={Greenarrow} alt='' />
                        Additional 2.5% Interest
                      </li>
                      <li>
                        <img src={Greenarrow} alt='' />
                        If gold value goes up by 20%
                      </li>
                    </ul>
                  </div>
                  <div class='total-returns'>
                    <p>Total Returns</p>
                    <p><i class="fa fa-rupee mrgR3"></i>{getGoldReturnsEightYears(sgbData.highprice)}</p>
                    <p class='after'>After 8 Years</p>
                    <span>
                      <span>
                        <img src={polygon70} alt='' />
                        <img src={Polygon25} alt='' />
                      </span>
                      40%
                    </span>
                  </div>
                </div>
                <div class='comparsion-1'>
                  <div class='category'>
                    <p>Physical Gold</p>
                    <img src={Physicalgold} alt='' />
                    <h2><i class="fa fa-rupee mrgR3"></i>{Number(sgbData.highprice * 10).toLocaleString()}</h2>
                    <p>For 10 Gm of Gold</p>
                  </div>
                  <div class='points'>
                    <ul>
                      <span>&nbsp;</span>
                      <li>
                        <img src={Greenarrow} alt='' />
                        If gold value goes up by 20%
                      </li>
                      <hr />
                      <li>
                        <img src={redarrow} alt='' />
                        Making charges
                      </li>
                      <li>
                        <img src={redarrow} alt='' />
                        Storage charges
                      </li>
                      <li>
                        <img src={redarrow} alt='' />
                        Tax on returns
                      </li>
                    </ul>
                  </div>
                  <div class='total-returns'>
                    <p>Total Returns</p>
                    <p>
                      <i class="fa fa-rupee mrgR3"></i>{Number(sgbData.highprice * 10 * (1 + 20 / 100)).toLocaleString()}
                    </p>
                    <p class='after'>After 8 Years</p>
                    <span>
                      <span>
                        <img src={polygon70} alt='' />
                        <img src={Polygon25} alt='' />
                      </span>
                      20%
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* sgb benefits table */}
        <section id='benefits'>
          <div class='benefits-table-container'>
            <div class='rectangle-1'></div>
            <h1>Benefits of Sovereign Gold Bond</h1>
            <table>
              <tr>
                <th></th>
                <th>Sovereign Gold Bond</th>
                <th>Physical Gold</th>
                <th>Gold ETF</th>
              </tr>
              <tr>
                <td>Returns/Earning</td>
                <td>More than actual return on Physical Gold</td>
                <td>Lower than real return on gold due to making charges</td>
                <td>
                  Less than actual return on physical gold (annual expense
                  deducted)
                </td>
              </tr>
              <tr>
                <td>Sovereign Guarantee</td>
                <td>Yes</td>
                <td>N/A</td>
                <td>No</td>
              </tr>
              <tr>
                <td>Interest on the Investment</td>
                <td>Yes, 2.5% of price of gold</td>
                <td>No</td>
                <td>No</td>
              </tr>
              <tr>
                <td>Fund Management Fees</td>
                <td>No</td>
                <td>No</td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>Broker Charges</td>
                <td>No</td>
                <td>No</td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>Exit/Redemption</td>
                <td>Only from 5th year</td>
                <td>Exit any time</td>
                <td>Exit any time</td>
              </tr>
              <tr>
                <td>Tradable</td>
                <td>Yes</td>
                <td>Yes</td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>Storage/ Insurance charges</td>
                <td>No</td>
                <td>Yes</td>
                <td>No</td>
              </tr>
              <tr>
                <td>Quality Check required</td>
                <td>No, (High Purity)</td>
                <td>Yes</td>
                <td>No</td>
              </tr>
              <tr>
                <td>Liquidity</td>
                <td>Limited</td>
                <td>High Liquidity</td>
                <td>High Liquidity</td>
              </tr>
            </table>
          </div>
        </section>
        {/* sgb about */}
        <section id='about'>
          <h1>About SGB</h1>
          <div class='background-color top-bg'></div>
          <div class='background-color down-bg'></div>
          <div class='about'>
            <div class='info'>
              <p>
                SGBs are government securities denominated in grams of gold. They are substitutes for holding physical gold. Investors have to pay the issue price in
                cash and the bonds will be redeemed in cash on maturity. SGB is free from issues
                like making charges and purity in the
                case of gold in jewellery form.
              </p>
            </div>
            <div class='video'>
              <a href="https://www.youtube.com/watch?v=Krs8MFHlEIg" rel="noreferrer noopener" target="_blank"><img src={G18776} alt='' /></a>
            </div>
          </div>
        </section>
        {/* dynamic ipo cards */}
        {(openIssue && openIssue.length) > 0 &&
          <section className={s.section7} style={{ marginLeft: '7rem', zoom: 0.80 }}>
            <div className={s.header}>Others also invested in</div>
            <div className={s.cards}>
              {openIssue.map((e, i) => {
                return e?.issuecode !== details.issuecode &&
                  <SGBSection1Card
                    img={e.cmsdata?.logo ? `${CMS_URL}${e.cmsdata?.logo}` : NoImg}
                    title={e.schname}
                    subHeading={`Range`}
                    rangeLowprice={e.lowprice}
                    rangeCutoff={e.cutoff}
                    event={`Invest by ${new Date(e.opndt).toLocaleDateString('en-IN', {
                      day: '2-digit', month: 'short'
                    })} - ${new Date(e.clsdt).toLocaleDateString('en-IN', {
                      day: '2-digit', month: 'short'
                    })}`}
                    button="Apply Now"
                    top={e.cmsdata?.tag}
                    background={`linear-gradient(321deg, ${e?.cmsdata?.card_color_left ? e?.cmsdata?.card_color_left : '#fff'}, ${e?.cmsdata?.card_color_right})`}
                    color={e.cmsdata?.badge_color}
                    topbackground={e.cmsdata?.tag_color}
                    issueCode={e?.issuecode}
                    history={history}
                    gotoIPO={gotoIPO}
                    iscolor={(e?.cmsdata?.card_color_left && e?.cmsdata?.card_color_left !== '#ffffff') ? 'Y' : 'N'}
                    issueQty={e.issueQty}
                    lowprice={e.lowprice}
                    highprice={e.highprice}
                  />

              })}
            </div>
          </section>
        }
      </div>
      <Footer />
    </>
  );
};

export default SGBDetailsPage;
