import s from './Footer.module.scss';
import OneUpLogo from '../../assets/images/LandingPage/Group 13604.svg';
import PoweredByLogo from '../../assets/images/LandingPage/IIFLSecFooter.svg';

const Footer = () => {
	return (
		<footer className={s.main}>
			<div className={s.left}>
				<img src={OneUpLogo} alt="OneUp" className={s.oneUpLogo} />
				<div className={s.powered_by_logo}>
					<span>Powered by</span><img src={PoweredByLogo} alt="Powered By IIFL Securities" className={s.poweredByLogo} />
				</div>

			</div>
			<div className={s.left}>
				<p>
					{'IIFL SECURITIES LTD. SEBI REGN. NO. : INZ000164132 | AMRFA: ARN NO: 47791 BSE MFD: 10234|RA NO: INH000000248 | IA NO: INA000000623 | Depository Participation: NSDL & CDSL: INDP185 2016'}
				</p>
			</div>
			<div className={s.list}>
				<a href="https://www.indiainfoline.com/privacypolicy" target="_blank">Privacy Policy</a>
				<a href="https://help.indiainfoline.com/portal/en/kb/iifl-help/ipo-ofs" target="_blank">{'Help & Support'}</a>
			</div>
			<div className={s.list}>
				<a href="https://www.indiainfoline.com/termsandconditions" target="_blank">{'Terms & Conditions'}</a>
				<a href="https://www.indiainfoline.com/disclaimer" target="_blank">Disclaimer</a>
			</div>
			<div className={s.footcopyright}>
				<span>Copyright © IIFL Securities Ltd. All rights Reserved. ver 1.5</span>
			</div>
		</footer>
	);
};

export default Footer;
