import { Fragment, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useWindowDimensions from '../../hooks/screenWidth';
import Login from '../Login';
import SideBar from '../SideBar/SideBar';
import s from './MainWrapper.module.scss';

const MainWrapper = ({ ChildElement, gradientSmall = false, gradient = true }) => {
	const [login, setLogin] = useState(false);
	const location = useLocation();
	const { width } = useWindowDimensions();

	const toggleLogin = () => {
		setLogin(!login);
	};

	return (
		<div className={s.main}>
			{login && <Login toggleLogin={toggleLogin} />}
			{
				<SideBar toggleLogin={toggleLogin} />
			}
			<div className={s.child}>
				<ChildElement toggleLogin={toggleLogin} />
			</div>
		</div>
	);
};

export default MainWrapper;
