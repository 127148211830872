import React, { Fragment } from 'react';
import s from '../LandingPage/LandingPage.module.scss';
import GoldBarImg from '../../assets/images/LandingPage/goldBar.png';
import SovereignGoldImg from '../../assets/images/LandingPage/Soverign Gold Bonds_48 Px@3x.png';
import TickImg from '../../assets/images/LandingPage/check_circle_black_24dp@3x.png';
import EventImg from '../../assets/images/LandingPage/event_black_24dp.svg';
import MinusButtonImg from '../../assets/images/LandingPage/Group 15346.svg';
import PlusButtonImg from '../../assets/images/LandingPage/Group 15345.svg';
import ArrowImg from '../../assets/images/LandingPage/Arrow@3x.png';
import { Link } from 'react-router-dom';
import videoImg from '../../assets/images/LandingPage/NoPath - Copy (13)@3x.png';

const SGBSection = ({ sgbdata, leftTime, width, handleCounter, counter, sgbprice, getGoldReturnsEightYears, handleSGBApply }) => {
    return (
        <section className={s.section3}>
            <h3 className={s.header}>Get into Gold!</h3>
            <div className={s.container}>
                <div className={s.left}>
                    <img src={GoldBarImg} alt="GoldBar" className={s.goldBar} />
                    {width > 768 && (
                        <div className={s.innerLeft}>
                            <img src={SovereignGoldImg} alt="Sovereign Gold" />
                        </div>
                    )}

                    <div className={s.innerRight}>
                        {width > 768 ? (
                            <Fragment>
                                <h2>Sovereign Gold Bond</h2>
                                <span className={s.subHeading}>{sgbdata ? sgbdata.schname : 'Scheme 2021-22 Series - V'}</span>
                            </Fragment>
                        ) : (
                            <div className={s.mobileHead}>
                                <div className={s.innerLeft}>
                                    <img src={SovereignGoldImg} alt="Sovereign Gold" />
                                </div>
                                <div className={s.heading}>
                                    <h2>Sovereign Gold Bond</h2>
                                    <span className={s.subHeading}>{sgbdata ? sgbdata.schname : 'Scheme 2021-22 Series - V'}</span>
                                </div>
                            </div>
                        )}

                        <div className={s.invest}>
                            <img src={EventImg} alt="Invest" />
                            <span>Invest within {' '}
                                {!isNaN(leftTime.hours) && `${leftTime.days} Days ${leftTime.hours} hours, ${leftTime.minutes} mins`}
                            </span>
                        </div>
                        <div className={s.circles}>
                            <div className={s.leftCircle}>
                                <span>You Invest</span>
                                <div className={s.buttons}>
                                    <button onClick={() => handleCounter('minus')}>
                                        <img src={MinusButtonImg} alt="Minus" />
                                    </button>
                                    <span>{counter} gm</span>
                                    <button onClick={() => handleCounter('add')}>
                                        <img src={PlusButtonImg} alt="Plus" />
                                    </button>
                                </div>
                                <span className={s.light}><i class="fa fa-rupee mrgR3"></i>{Number(sgbprice).toLocaleString()}</span>
                            </div>
                            <div className={s.arrow}>
                                <img src={ArrowImg} alt="Arrow" />
                            </div>

                            <div className={s.rightCircle}>
                                <span>You Get</span>
                                <h4><i class="fa fa-rupee mrgR3"></i>{getGoldReturnsEightYears(sgbdata.highprice)}</h4>
                                <span className={s.light}>If price goes up by 20% in 8 years</span>
                            </div>
                        </div>

                        <div className={s.buttonsC}>

                            <a href='#' onClick={() => handleSGBApply()}><button className={s.button1}>Apply Now</button></a>
                            <Link to='/sovereign_gold_bond_details'><button className={s.button2}>Know More</button></Link>
                        </div>
                    </div>
                </div>

                <div className={s.right}>
                    <h3>Sovereign Gold Bonds vs Physical Gold</h3>
                    <div className={s.list}>
                        <div className={s.item}>
                            <img src={TickImg} alt="Tick" />
                            <span>No making or storage charges</span>
                        </div>
                        <div className={s.item}>
                            <img src={TickImg} alt="Tick" />
                            <span>You get an extra interest of 2.5% for holding</span>
                        </div>
                        <div className={s.item}>
                            <img src={TickImg} alt="Tick" />
                            <span>No GST. You get tax free profit.</span>
                        </div>
                        <div className={s.item}>
                            <img src={TickImg} alt="Tick" />
                            <span>Safe and secure</span>
                        </div>
                    </div>

                    <div className={s.line} />

                    <div className={s.youtube}>
                        <div className={s.image}>
                            <a href="https://www.youtube.com/watch?v=Krs8MFHlEIg" rel="noreferrer noopener" target="_blank"><img src={videoImg} alt="Video" /></a>
                        </div>

                        <div className={s.body}>
                            <h5>How are sovereign Gold Bonds better than Physical Gold?</h5>
                            <span>Youtube . 2 days ago</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default SGBSection;
