import * as ncdTypes from "./ncdType";

const INITIAL_STATE = {
    ncdSeriesData: [],
    filteredData: [],
    highprice: null,
    noOfMandatoryBonds: null,
    othersDetail: {
        fstname: '',
        panNo: '',
        email: '',
        mobileNo: '',
        dpid: '',
        beneficiaryID: '',
    },
};


const ncdReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ncdTypes.NCD_SERIES:
            const { ipoSeriesMap, highprice, noOfMandatoryBonds } = action.payload;

            ipoSeriesMap.forEach((obj, i) => {
                obj.check = false;
                obj.minAmt = 0;
                obj.minQty = 0;
            });

            return {
                ...state,
                ncdSeriesData: ipoSeriesMap,
                filteredData: ipoSeriesMap,
                highprice,
                noOfMandatoryBonds,
            };

        case ncdTypes.FILTER_SERIES:
            return {
                ...state,
                filteredData: action.payload !== 'All' ? state.ncdSeriesData.filter((item) => item.tenure === action.payload) : state.ncdSeriesData,
            };

        case ncdTypes.UPDATE_CHECK_VALUE:
            const isAnycheck = state.ncdSeriesData.some((item) => item.check === true);
            const { check, seriesNumber } = action.payload;

            const index = state.filteredData.findIndex((item) => item.seriesNumber === seriesNumber);
            state.filteredData[index].check = check;
            state.filteredData[index].minAmt = check ? isAnycheck ? state.highprice : state.highprice * state.noOfMandatoryBonds : 0;
            state.filteredData[index].minQty = check ? isAnycheck ? 1 : state.noOfMandatoryBonds : 0;


            return {
                ...state,
                filteredData: state.filteredData,
            };

        case ncdTypes.UPDATE_QTY:
            const { qty, seriesNo, isMobile } = action.payload;
            const indexNo = state.filteredData.findIndex((item) => item.seriesNumber === seriesNo);

            if (!isMobile) {
                state.filteredData[indexNo].check = (isNaN(parseInt(qty)) || parseInt(qty) === 0) ? false : true;
            }
            state.filteredData[indexNo].minAmt = state.highprice * (isNaN(parseInt(qty)) ? 0 : parseInt(qty))
            state.filteredData[indexNo].minQty = isNaN(qty) ? '' : parseInt(qty);

            return {
                ...state,
                filteredData: state.filteredData,
            };

        case ncdTypes.UNCHECK_SERIES:
            const { quan, seriesNum, } = action.payload;

            if (!quan) {
                const indexNum = state.filteredData.findIndex((item) => item.seriesNumber === seriesNum);
                state.filteredData[indexNum].check = false;
            }

            return {
                ...state,
                filteredData: state.filteredData,
            };

        case ncdTypes.OTHER_DETAIL:
            return {
                ...state,
                othersDetail: action.payload,
            };


        default:
            return state;
    };
};

export default ncdReducer;