import React from "react";
import "./DematAccountModal.css";
import closeicon from "../assets/Group 48096659.svg";
import iifllogo from "../assets/iifllogo.svg";
import icon1 from "../assets/Group 48097974.svg";
import icon2 from "../assets/Group 48097975.svg";
import icon3 from "../assets/Group 48097976.svg";
import icon4 from "../assets/Group 48097977.svg";

const DematAccountModal = ({ setShowDematAccModal }) => {
  return (
    <>
      <div className="payment-drawer-backdrop show"></div>
      <div className="demat-account-modal">
        <div className="demat-account-modal-close-icon">
          <img
            onClick={() => setShowDematAccModal(false)}
            src={closeicon}
            alt="closeicon"
          />
        </div>
        <div className="demat-account-modal-info">
          <h2>Become eligible for to apply for G-sec</h2>
          <p>
            Start FREE investing in share market with no 1 stockbroker in India
          </p>
          <img src={iifllogo} alt="iifllogo" />
        </div>

        <div className="demat-account-modal-stats-wrap">
          <div className="row g-5">
            <div className="col-md-6">
              <div className="demat-account-modal-stats">
                <div className="demat-account-modal-stats-icon">
                  <img src={icon4} alt="icon4" />
                </div>
                <p>
                  <span>7 millions</span> happy customers
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="demat-account-modal-stats">
                <div className="demat-account-modal-stats-icon">
                  <img src={icon2} alt="icon4" />
                </div>
                <p>
                  <span>Zero brokerage</span> on equity delivery trades
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="demat-account-modal-stats">
                <div className="demat-account-modal-stats-icon">
                  <img src={icon3} alt="icon4" />
                </div>
                <p>
                  <span>₹20</span> per order for intraday and F&O
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="demat-account-modal-stats">
                <div className="demat-account-modal-stats-icon">
                  <img src={icon1} alt="icon4" />
                </div>
                <p>Trade with the best platform and tools</p>
              </div>
            </div>
          </div>
        </div>

        <div className="open-acc-btn">
          <button>Click to Open Instant Demat Account</button>
          <p>*For support, call /whatsapp at 9876543210</p>
        </div>
      </div>
    </>
  );
};

export default DematAccountModal;
