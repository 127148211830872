import React, { useState } from "react";
import "./GsecBidCancelModal.css";
import circleClose from "../../assets/images/Path 12202.svg";
import NoImg from "../../assets/images/noimg.jpg";
import { useSelector } from "react-redux";
import { addgsec } from "../../services/issuesServices";
import LoaderImg from "../../assets/images/loader.svg";
import gs_icon from "../../assets/images/gs_icon.svg";
import sdl_icon from "../../assets/images/sdl_icon.svg";
import T_Bill_Icon from "../../assets/images/T_Bill_Icon.svg";

const GsecBidCancelModal = ({
  setAlertMessage,
  gsecDetail,
  setGsecCancelModal,
  fetchthirdpart,
  fetchDetails,
}) => {
  const { user } = useSelector((state) => state.loggedIn);
  const [isLoading, setIsLoading] = useState(false);

  const handleBidCancell = async () => {
    setIsLoading(true);
    try {
      const res = await addgsec({
        IssueCode: gsecDetail?.scheduleData.iponame,
        IssueType: gsecDetail?.scheduleData.instrumentType.trim(),
        ClientCode: user?.clientcode,
        LoginId: user?.loginid,
        BidQty: gsecDetail?.scheduleData.bids[0].noOfShares,
        BidPrice: gsecDetail?.scheduleData.bids[0].bidPrice,
        TotalAmount:
          gsecDetail?.scheduleData.lowprice *
          gsecDetail?.scheduleData.bids[0].noOfShares,
        BidFlag: "C",
        AppSource: user?.AppSource,
        MasterSrNo: gsecDetail?.scheduleData.bids[0].masterSrNo,
        ApplicationNo: gsecDetail?.applicationNo,
      });
      if (res.data.statusCode === 200) {
        fetchthirdpart();
        fetchDetails();
        setGsecCancelModal(false);
        setAlertMessage(res.data);
      } else {
        setGsecCancelModal(false);
        setAlertMessage(res.data);
      }
    } catch (error) {
      setAlertMessage(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="gsec_cancel_modal_backdrop">
        <div className="gsec_cancel_modal">
          {isLoading && (
            <div className="bid_cancel_loader">
              <div className="loading_screen loading_inside">
                <img src={LoaderImg} alt="loading..." />
              </div>
            </div>
          )}

          <div className="gsec_cancel_modal_top">
            <img
              onClick={() => setGsecCancelModal(false)}
              src={circleClose}
              alt="circleClose"
            />
            <h2>Are you sure you want to cancel this application?</h2>
          </div>

          <div className="gsec_cancel_modal_inner">
            <div className="gsec_cancel_modal_issue">
              <img
                src={
                  gsecDetail?.instrumentType.trim() === "GS"
                    ? gs_icon
                    : gsecDetail?.instrumentType.trim() === "TB"
                    ? T_Bill_Icon
                    : gsecDetail?.instrumentType.trim() === "SD"
                    ? sdl_icon
                    : NoImg
                }
                alt="Img"
              />
              <div>
                <h2>{gsecDetail.issue_name}</h2>
                <p>Application No: {gsecDetail.applicationNo}</p>
              </div>
            </div>
            <div>
              <h2>₹{gsecDetail.amountpaid.toLocaleString("en-IN")}</h2>
              <p>Total Amount</p>
            </div>
          </div>

          <div className="gsec_cancel_modal_btn">
            <div className="row">
              <div className="col-6">
                <button onClick={handleBidCancell} className="gsec_btn_yes">
                  Yes
                </button>
              </div>
              <div className="col-6">
                <button
                  onClick={() => setGsecCancelModal(false)}
                  className="gsec_btn_no"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GsecBidCancelModal;
