import s from "./Sections.module.scss";
import shield from "../assets/shield.png";
import { useEffect } from "react";
import { useState } from "react";
const TopSection = ({ allGsecList }) => {
  const [minAmount, setMinAmount] = useState(0);
  const [indicativeYield, SetIndicativeYield] = useState(0);

  useEffect(() => {
    if (allGsecList.length > 0) {
      const amt = allGsecList.map((element) => element.minAmount);
      setMinAmount(Math.min(...amt));

      const fixedReturn = allGsecList.map((element) => element.indicativeYield);
      SetIndicativeYield(Math.max(...fixedReturn));
    }
  }, [allGsecList]);

  return (
    <>
      <div className={s.topWrapper}>
        <div className={s.topLeft}>
          <img src={shield} />
        </div>
        <div className={s.topRight}>
          <h5>Risk-free and better than FD</h5>
          <h2>Government Securities (G-secs)</h2>
          <p>
            G-sec refer to government securities which are safest alternative to
            bank FDs, which are guranteed by the govt. of India
          </p>

          <div className={s.investmentDetails}>
            <div>
              <span>Minimum investment</span>
              <h3>
                <i className="fa fa-rupee mrgR3"></i>
                {minAmount.toLocaleString("en-IN")}
              </h3>
            </div>

            <div>
              <span>Tenure</span>
              <h3>91 days - 40 Yrs</h3>
            </div>

            <div>
              <span>Indicative yield</span>
              <h3>
                <small>upto</small> {indicativeYield}%
              </h3>
            </div>
          </div>
          <a href="#explore">
            <button>Explore Now</button>
          </a>
        </div>
      </div>
    </>
  );
};

export default TopSection;
