import moment from 'moment';
import { apiServices, cmsServices, postapiservices, getapiservices, trendlyneapi, otpApiServices, zohoAPIServices, cmsWrapService, API_PATH, ipoFundingApiService, appliedApiServices, appliediposervice, npsPostapiservices, razorpayPostapiservices, dionApiServices } from './axios_client';
let host = window.location.hostname
let path = ''
if (host === 'localhost') {
	// path = "http://localhost:3005"
	path = process.env.REACT_APP_API_URL_LOCAL
}
// else if(host === 'oneupuat')
// {
// 	path = "https://oneupuatcb.indiainfoline.com"
// }
else {
	path = process.env.REACT_APP_API_URL
}

// IPO Message
// This API allows user to Get Open Issues List
export function getOpenIssuesList(category) {
	return getapiservices.post(`/ipo/open-issues/${category}`);
}

// This API allows user to Get Past Issues List
export function getPastIssuesList(count) {
	//return cmotsAPIServices.get(`/new-listing/bse/${count}?responsetype=json`);

	let data = {
		'endpoint': `https://content.dionglobal.in/new-listing/bse/${count}?responsetype=json`,
		'body': {},
		'headers': {}
	}
	return cmsWrapService.post(`/getfetchapi`, data);

}

// This API allows user to Get Upcoming Issues List
export function getUpcomingIssuesList(issue_type = 'IPO') {
	//return cmotsAPIServices.get(`/iiflapi/forthcomingipo.aspx`);
	return cmsServices.get(`${path}/issue/upcoming-issues/${issue_type}`);
}
// This API allows user to Get Top Performer List
export function getTopPerformerList(fyear) {
	//return cmotsAPIServices.get(`/iiflapi/TopPerformer.aspx?year=${fyear}`);

	let data = {
		'endpoint': `https://content.dionglobal.in/iiflapi/TopPerformer.aspx?year=${fyear}`,
		'body': {},
		'headers': {}
	}
	return cmsWrapService.post(`/getfetchapi`, data);

}
// This API allows performance graph of a ipo
export function getPerformance(issue_code) {
	return dionApiServices.post(`/ipo/DION3Yearsrevenue/${issue_code}`);
}

// This API allows subscription of a ipo
export function getSubs(issue_code) {
	const currentDate = moment().format("YYYY-MM-D");
	return dionApiServices.post(`/ipo/DIONSubscriptionDetails/${issue_code}/${currentDate}`);
}

// get profit loss witth symbol
export function getHelth(issue_code) {
	//return cmotsAPIServices.get(`iiflapi/Profitlosswithsymbol.aspx?symbol=${issue_code}`);
	let data = {
		'endpoint': `https://content.dionglobal.in/iiflapi/Profitlosswithsymbol.aspx?symbol=${issue_code}`,
		'body': {},
		'headers': {}
	}
	return cmsWrapService.post(`/getfetchapi`, data);
}

// get promoter holdings

export function getPromoterHoldings(issue_code) {
	var d = new Date(),
		month = '' + (d.getMonth() + 1),
		day = '' + d.getDate(),
		year = d.getFullYear();
	if (month.length < 2)
		month = '0' + month;
	if (day.length < 2)
		day = '0' + day;

	var datefl = [year, month, day].join('-');
	//return cmotsAPIServices.get(`/iiflapi/IPO_PromoterHoldingBeforeIPO.aspx?symbol=${issue_code}&IOdate=${datefl}`);
	let data = {
		'endpoint': `https://content.dionglobal.in/iiflapi/IPO_PromoterHoldingBeforeIPO.aspx?symbol=${issue_code}&IOdate=${datefl}`,
		'body': {},
		'headers': {}
	}
	return cmsWrapService.post(`/getfetchapi`, data);

}
// This API allows user to Get IPO Open Issues Details
export function getOpenIssuesDetails(issue_code) {
	return otpApiServices.post(`/IPO/open-issues-details/${issue_code}`);
	//return postapiservices.post(`/IPO/open-issues-details/${issue_code}`);
}

export function getOpenIssuesCordinate(issue_code) {
	return getapiservices.post(`/IPO/coordinates/${issue_code}`);
}

export function getprintingdetails(clientcode, sr_no) {
	return postapiservices.post(`/Ipo/client-info-IPO-form-printing/${clientcode}/${sr_no}`);
}


// This API allows user to
// ✓ Place New IPO
// ✓ Modify Existing IPO
// ✓ Cancel Existing IPO
export function placeNewIPO(data, type = "IPO") {
	var baseurl = '';
	if (type === 'IPO') {
		return postapiservices.post('/IPO/add', data);
		baseurl = API_PATH + `/IPO/add`

	}
	else if (type === 'NCD') {
		return postapiservices.post(`ipo/addncd`, data);
		baseurl = API_PATH + `/ipo/addncd`
	}
}

// get pdf coordinates
export function getpdfcoordinate(issuecode, clientcode, data) {
	return postapiservices.post(`ipo/ipo-form-printing/${issuecode}/${clientcode}/${data}`);
}

// get pdf coordinates
export function getpdfcoordinatencd(issuecode, clientcode, data) {
	return postapiservices.post(`ipo/ncd-form-printing/${issuecode}/${clientcode}/${data}`);
}


export function getBankList(issuecode) {
	return postapiservices.post(`ipo/getbanklist/${issuecode}`);
}

export function getBankLocationList(issuecode, bankcode) {
	return postapiservices.post(`ipo/getbanklocationlist/${issuecode}/${bankcode}`);
}

//use to get list of applied ipo
export function getAppliedIPOList(clientcode, ipid) {

	return appliedApiServices.post('ipo/get-ipo-applied-list/');
}

// This API allows user to Get  Applied IPO Details
export function getAppliedIPODetails(clientcode, ipid, appno, srno, type = "IPO") {
	if (type === 'IPO') {
		return appliedApiServices.post(`ipo/get-ipo-applied-details/${ipid}/${appno}/${srno}`);
	}
	else {
		return postapiservices.post(`ipo/get-ncd-applied-details/${clientcode}/${ipid}/${appno}/${srno}`);
	}
}

// This API allows user to Get  Applied IPO Details status
export function getAppliedConfirmationSteps(usercode, issuecode) {
	return postapiservices.post(`ipo/get-ipo-amount-confirmation-status-for-nse/${usercode}/${issuecode}`);
}


// This API allows user validate after OTP
export function otpValidate(data) {
	return otpApiServices.post(`ipo/validatetoken`, data);
}


// This API allows user validate UPI
export function upiValidate(data) {
	return otpApiServices.post(`ipo/ValidateVPA/${data}`);
}



// This API allows user logout
export function logOutUser(data) {
	return otpApiServices.post(`ipo/logout`, data);
}

// to validate user after login from any third party app
export function validthirdpartycheck(token, uid) {
	return otpApiServices.post(`IPO/ValidateOtherAppToken/${token}/${uid}`);
}


export function getAllCardDetails() {
	return apiServices.post(`IPO/add-and-check-nonIIfl-journey-clietdetail`);
}

export function brokerRecomendation(issuecode) {
	return trendlyneapi.get(`/clientapi/irwin/stock/broker-recommendation/${issuecode}/`)
}

export function getIssueDetailsFromCmsByCode(issuecode, flag = true) {
	if (flag === true) {
		return cmsServices.get(`${path}/issue/detail/code/${issuecode}`);
	}
	else {
		var promise = new Promise((resolve, reject) => {
			resolve({ 'data': { 'statusCode': 205 } });
		});
		return promise
	}
}

export function getInDemandIssuesFromCms() {
	return cmsServices.get(`${path}/issue/in-demand/`);
}

export function getIssuesByCodes(data) {
	return cmsServices.post(`${path}/issue/issuesbycodes`, { "issues": data });
}

export async function getpdfFromCmsByCode(data) {
	try {
		let pdfData = await cmsServices.post(`${path}/modify-pdf`, data);
		return pdfData;

	} catch (error) {
		var promise = new Promise((resolve, reject) => {
			resolve({
				"status": 0,
				"message": "Application file not found",
				"data": {}
			});
		});
		return promise
	}
}

// ZOHO genarate token
export function zohoCreateToken(data) {
	return zohoAPIServices.post(`/Token`, { "UserID": "60AA303E28EF490B" });
}

// ZOHO create lead
export function zohoCreateLead(data) {
	return zohoAPIServices.post(`/Save`, data);
}


// for SGB details page -08/01/2022
export function getSgbDetails() {
	return postapiservices.post(`/ipo/open-issues/SGB`)
}

// for SGB details in  application page 1
export function getSgbAuthDetails(issuecode) {
	return postapiservices.post(`ipo/sgb-issues-details/${issuecode}`)
}

// for sgb ledger balance checker
export function getLedgerBalance(payload) {
	return postapiservices.post(`ipo/sgb-check-ledger-balance`, payload)
}


// for add SGB
export function placeSGBOrder(data) {
	return postapiservices.post(`ipo/AddSGB`, data)
}


//use to get list of applied ipo
export function getNCDdetails(issuecode) {
	// return postapiservices.post(`ipo/ncd-issues-details/${issuecode}/`);
	return appliediposervice.post(`ipo/ncd-issues-details/${issuecode}/`);
}


//Add Ncd
export function addncd(data) {
	return postapiservices.post(`ipo/addncd`, data);
}


//use to get ipo funding url
export function getIpoFunding(data) {
	return ipoFundingApiService.post(`ipo/IPOFundingSessiontransfer`, data);
}
//End get ipo funding url

// ADD GSEC API
export function addgsec(data) {
	return postapiservices.post(`ipo/addgsec`, data);
  }

// NPS API
// Check PRAN No
export function checkPRANNo(data) {
	return npsPostapiservices.post('ipo/NPS/CheckPRANNo', data);
}
// Add NPS Transaction
export function addNPSTransaction(data) {
	return npsPostapiservices.post('ipo/NPS/AddNPSTransaction', data);
}
// Update NPS Transaction
export function updateNPSTransaction(data) {
	return npsPostapiservices.post('ipo/NPS/UpdateNPSTransaction', data);
}
// RAZORPAY API
// Update NPS Transaction
export function createOrder(data) {
	return razorpayPostapiservices.post('razorpay/order', data);
}