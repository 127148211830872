import React, { useEffect, useState } from "react";
import "./SGBApplication1.css";
import {
  getSgbAuthDetails,
  getLedgerBalance,
  placeSGBOrder,
} from "../../services/issuesServices";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link, useHistory } from "react-router-dom";
import SGB48 from "../../assets/images/Sgb/Soverign Gold Bonds_48 Px.webp";
import LoaderImg from "../../assets/images/loader.svg";
import { useSelector } from "react-redux";
import ClevertapReact from "clevertap-react";
import { CMS_URL, clevertap_key } from "../../vars/url";


const SGBApplicationPage1 = (props) => {
  const { handleRange, selectedRange, customrange, disableranger, handleCustRange, handleDisRange, handleSgbTransc, setSgbDetail } = props;
  const [availbalance, setAvailbalance] = useState(0);
  const [availbalanceMsg, setAvailbalanceMsg] = useState('');
  const [sgbData, setsgbData] = useState([]);
  const [selectedgrams, setSelectedGrams] = useState(1);
  const [hidealert, setHideAlert] = useState(false);
  const [alertmsg, setAlertMsg] = useState('Something went wrong, try again!')
  const [hasFunds, setHasFunds] = useState(false);
  const [loading, setLoading] = useState(false);
  let history = useHistory();
  const user = useSelector((state) => state.loggedIn);
  const [lefttime, setLeftTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  useEffect(() => {
    fetchSgbDetails()
    fetchAvailBalance()

  }, [loading]);
  const fetchSgbDetails = () => {
    let itemobj = JSON.parse(localStorage.getItem('alloppor'));
    var sgblitm = itemobj.filter(function (v, i) {
      return (v.category === 'SGB');
    })
    let bubble = document.getElementById("price");
    let response = getSgbAuthDetails(sgblitm[0].issuecode);
    response
      .then((res) => {
        setsgbData(res.data.resultData);
        setSgbDetail(res.data.resultData);
        getRemainingTimeToApply(res.data.resultData.enddate);
        setSelectedGrams(selectedRange / res.data.resultData.highprice);
        if (selectedRange === 0) {
          handleRange(Number(res.data.resultData.highprice))
        }
        if (selectedRange >= 200000) {
          bubble.style.float = 'right';
          bubble.style.left = 'unset';
          bubble.style.border = 'none';
        }

        ClevertapReact.initialize(clevertap_key);
        var ClevertapPayload = {
          Source: "OneUp HomePage",
          "Step": "1",
          "Product Type": "SGB",
          "Issue Name": res.data.resultData.issuecode,
          "Bid Number": "",
          "Investment Amount": selectedRange === 0 ? res.data.resultData.highprice : selectedRange,
          "Price Type": "",
          "Applying for": "",
          "Investor category": "",
          "payment type": "",
          "Failure Message": "",
          "Application number": "",
          "Amount": "",
          "Series": "",
        };
        ClevertapReact.event("OU_Application Step", ClevertapPayload);

      })
      .catch((err) => console.log(err));
  }
  const fetchAvailBalance = () => {
    let balanceres = getLedgerBalance({
      clientCode: user.user.clientcode,
      amount: 0,
      marginFlg: "N",
    });
    balanceres
      .then((res) => {

        if (res.data.statusCode === 200 && res.data.isSuccess === true) {
          setAvailbalance((res.data.resultData.availableBalance).toFixed(2));
          setAvailbalanceMsg('');
        }

        if (res.data.statusCode === 200 && res.data.isSuccess === false) {
          setAvailbalance((res.data.resultData.availableBalance).toFixed(2));
          setAvailbalanceMsg(String(res.data.resultData.message));
        }

        if (res.data.statusCode === 404) {
          setAvailbalance((0).toFixed(2));
          setAvailbalanceMsg(String(res.data.resultData.message));
        }

        if (res.data.statusCode === 500) {
          setAvailbalance((0).toFixed(2));
          setAvailbalanceMsg(String(res.data.resultData.message));
        }



      })
      .catch((err) => console.log(err));
  }
  const placeOrder = () => {
    setLoading(true);
    if (selectedRange === 0) {
      handleRange(sgbData.highprice)
    }
    let orderresponse = placeSGBOrder({
      "ipoName": sgbData.issuecode,
      "clientcode": user.user.clientcode,
      "loginId": user.user.loginid,
      "noOfShares": selectedgrams === 0 ? 1 : selectedgrams,
      "bidPrice": Number(sgbData.highprice),
      "chqAmount": selectedRange === 0 ? sgbData.highprice : selectedRange,
      "categoryType": "RET",
      "category": "IND",
      "subBrokerId": "",
      "appNo": 0,
      "ipoBankName": "ASBA",
      "BidFlag": "N",
      "crmLeadID": "",
      "pincode": "400059",
      "asbaParameter": "9999|0|NASBAL|0|N",
      "upiNo": "",
      "appSource": user.user.AppSource,
    }
    );
    orderresponse
      .then((res) => {
        setLoading(false);
        if (res.data.message && res.data.message === 'Success') {
          setHideAlert(false);
          handleSgbTransc(res.data.resultData.transcode);

          history.push("/sgb_application_finish");

        } else {
          setAlertMsg(res.data.message)
          setHideAlert(true)

        }

      })
      .catch((err) => {
        setHideAlert(true)
      });
  };
  const getRemainingTimeToApply = (closeDate) => {
    // Set the date we're counting down to
    var countDownDate = new Date(closeDate).getTime();

    // Update the count down every 1 second
    // eslint-disable-next-line no-unused-vars
    var x = setInterval(function () {
      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setLeftTime({
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      });

    }, 1000);
  };

  const handleSlider = (e) => {
    if (e.target.value < 200000) {
      handleRange(e.target.value);
      handleGrams(e.target.value);
    }
    checkFunds(e.target.value);
    let ranger = document.getElementById("sgb-ap1-slider");
    let bubble = document.getElementById("price");
    if (e.target.value > 200000) {
      e.preventDefault()
      handleRange(200000)
      handleCustRange(200000)
    } else {
      handleDisRange(false)
    }
    const val = ranger && ranger.value;
    const min = ranger && ranger.min ? ranger.min : sgbData.highprice;
    const max = ranger && ranger.max ? ranger.max : 240000;
    const newVal = Number(((val - min) * 100) / (max - min));
    if (ranger.value >= 200000) {
      bubble.style.float = 'right';
      bubble.style.left = 'unset';
      bubble.style.border = 'none';
    }
    if (ranger.value < 200000) {
      bubble.style.left = `calc(${newVal}% + (${-4.1 - newVal * 0.015}rem))`;
      bubble.style.float = 'none';
    }
  };
  const handleCustomRange = (e) => {
    handleCustRange(e.target.value)
  }
  const handleCustomRangeBlur = () => {
    if (customrange > 200000 && customrange >= (sgbData.highprice * 4000)) {
      handleRange(sgbData.highprice * 4000)
      setSelectedGrams(4000)
      handleCustRange(sgbData.highprice * 4000)
      handleDisRange(false)
    } else {

      if (customrange > 200000 && customrange < sgbData.highprice * 4000) {
        let cgrams = Math.floor(customrange / sgbData.highprice)
        let finalPrice = cgrams * sgbData.highprice
        handleRange(finalPrice)
        setSelectedGrams(cgrams)
        handleDisRange(false)
        handleCustRange(finalPrice)
      } else {
        handleRange(Math.floor(200000 / sgbData.highprice) * sgbData.highprice)
        setSelectedGrams(Math.floor(200000 / sgbData.highprice))

        handleDisRange(false)
        handleCustRange(200000)
      }
    }

  }
  const handleGrams = (price) => {
    setSelectedGrams(price / sgbData.highprice);
  };
  const checkFunds = (price) => {
    price > Number(availbalance) ? setHasFunds(false) : setHasFunds(true);
  };
  const handleALertBox = () => {
    setHideAlert(false);
    setLoading(false);
  };
  return (
    <>
      {loading === true && (
        <div className='loading_screen loading_inside'>
          <img src={LoaderImg} alt='loading...' />
        </div>
      )}
      {/* alert box */}
      {hidealert && (
        <SweetAlert error title='Alert!' onConfirm={handleALertBox}>
          {alertmsg}
        </SweetAlert>
      )}
      <section id='sgb-ap1-header'>
        <div class='sgb-ap1-header-left'>
          <Link to="/sovereign_gold_bond_details"><img src={SGB48} alt='' /></Link>
          <div>
            <h1>Sovereign Gold Bonds</h1>
            <div class='header-heading-subtext'>
              <p>{sgbData && sgbData.schemename}</p>
              <p>·</p>
              {!isNaN(lefttime.hours) && (
                <p>
                  {lefttime.days} Days {lefttime.hours} hours, {lefttime.minutes}{" "}
                  mins left to invest
                </p>
              )}
            </div>
          </div>
        </div>
        <div class='sgb-ap1-header-right'>
          <div class='steps'>
            <p>1</p>
            <hr />
            <p>2</p>
            <hr />
            <p>3</p>
          </div>
          <div class='step-names'>
            <p>Review Bids</p>
            <p>Payment</p>
            <p>Apply</p>
          </div>
        </div>
      </section>
      <section id='sgb-ap1-form'>
        <h1>Review Amount & Quantity</h1>
        <div class='sgb-ap1-input-container'>
          <div class='input'>
            <p>How much do you want to invest?</p>
            <div class='price-input'>
              <div id='price' class='sgb-app-price-bubble'>
                {(selectedRange < 200000) ?
                  selectedRange !== 0 && `₹${Number(selectedRange).toLocaleString()}`
                  :
                  <input
                    type='number'
                    min={200000}
                    value={customrange}
                    className="sgb-app-more-range-input"
                    onChange={handleCustomRange}
                    onBlur={handleCustomRangeBlur}
                    onKeyUp={(e) => {
                      e.preventDefault()
                      if (e.key === 'Enter') {
                        handleCustomRangeBlur()
                      }
                    }}
                    autoFocus={true}
                  />

                }
              </div>
              <input
                class='sgb-app-input'
                value={selectedRange}
                type='range'
                name='price'
                id='sgb-ap1-slider'
                min={sgbData.highprice}
                step={sgbData.highprice}
                max='240000'
                onChange={handleSlider}
                disabled={disableranger}
              />
              <p class='sgb-app-price-labels'>
                <span><i class="fa fa-rupee mrgR3"></i>{Number(sgbData.highprice).toLocaleString()}</span>
                <span><i class="fa fa-rupee mrgR3"></i>2L</span>
                <span>{">"}<i class="fa fa-rupee mrgR3"></i>2L</span>
              </p>
            </div>
            <div className='sgb-ap1-avail-balance'>
              <span className={(Number(availbalance) > selectedRange ? 'fundexist' : 'insufficiantFund')} >
                Available balance :
                <span>
                  <i class="fa fa-rupee mrgR3"></i>{availbalance}
                </span>
              </span>
              {(Number(availbalance) < selectedRange) && (
                <span className="insufficiantFund">
                  <br />
                  You have insufficient funds in your account. Please go to the
                  funds section and add the balance amount.
                </span>
              )}


            </div>
          </div>
          <div class='sgb-ap1-quantity'>
            <p>Total Qty</p>
            <p>{selectedgrams === 0 ? 1 : selectedgrams}</p>
            <p>Price per gm: <i class="fa fa-rupee mrgR3"></i>{sgbData.highprice}</p>
          </div>
        </div>
      </section>
      <footer id='sgb-ap1-footer'>
        <p>
          <span>Total Amount</span><i class="fa fa-rupee mrgR3"></i>{selectedRange === 0 ? Number(sgbData.highprice).toLocaleString() : Number(selectedRange).toLocaleString()}
        </p>

        {(Number(availbalance) > selectedRange) ? (
          <button onClick={placeOrder} class='next-btn'>
            Apply<i class='fas fa-arrow-right'></i>
          </button>
        ) : (    
          <div className='adfundsdisabled'>Please add funds from Funds section on your Mobile App or Website</div>
        )}
      </footer>
    </>
  );
};

export default SGBApplicationPage1;