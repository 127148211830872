import React from "react";
import "./OtherGsecCard.css";
import { getIssueDetailsFromCmsByCode } from "../../../../services/issuesServices";
import { useEffect } from "react";
import { useState } from "react";
import NoImg from "../../../../assets/images/noimg.jpg";
import { useHistory, useLocation } from "react-router-dom";
import { dateFormatter } from "../../../../helpers/utils";
import infoicon from "../assets/infoicon.svg";
import gs_icon from "../../../../assets/images/gs_icon.svg";
import sdl_icon from "../../../../assets/images/sdl_icon.svg";
import T_Bill_Icon from "../../../../assets/images/T_Bill_Icon.svg";

const OtherGsecCard = ({ details }) => {
  const [cmsData, setcmsData] = useState({});
  const history = useHistory();
  const [exptimer, setexptimer] = useState("");
  const [counterInterval, setcounterInterval] = useState({});
  const loaction = useLocation();

  const applyTimer = (closedate) => {
    let date_1 = new Date(closedate);
    let date_2 = new Date();
    let difference = date_1.getTime() - date_2.getTime();
    let expDays = Math.ceil(difference / (1000 * 3600 * 24));
    if (expDays > 1) {
      setexptimer(`${expDays} Days`);
    } else {
      let counterInt = setInterval(() => {
        setexptimer(dateFormatter(new Date(closedate).getTime()));
      }, 1000);
      setcounterInterval(counterInt);
    }
  };

  useEffect(() => {
    clearInterval(counterInterval);
    applyTimer(details?.clsdt);
  }, [details?.clsdt]);

  const getCmsData = async (issuecode) => {
    let cmsdata = {};
    try {
      cmsdata = await getIssueDetailsFromCmsByCode(issuecode);
      setcmsData(cmsdata?.data?.result);
    } catch (error) {
      cmsdata = {};
    }
  };

  useEffect(() => {
    getCmsData(details?.issuecode);
  }, []);

  return (
    <div
      className="otherGsecCard"
      onClick={() => history.push(`/gsec_details/${details?.issuecode}`)}
    >
      <div className="otherGsecCard_img">
        <img
          src={
            cmsData?.logo
              ? cmsData?.logo
              : details?.category === "GS"
                ? gs_icon
                : details?.category === "TB"
                  ? T_Bill_Icon
                  : details?.category === "SD"
                    ? sdl_icon
                    : NoImg
          }
          alt={details?.schname}
        />
      </div>
      <h2>{cmsData?.issue_name ? cmsData?.issue_name : details?.schname}</h2>
      <p
        style={{
          fontWeight: loaction.pathname === "/" && "500",
          color:
            loaction.pathname === "/" &&
            (details?.category === "GS"
              ? "#3D17D5"
              : details?.category === "TB"
                ? "#E94594"
                : details?.category === "SD"
                  ? "#AE8C12"
                  : ""),
        }}
      >
        {details?.category === "TB"
          ? "T-Bill"
          : details?.category === "SD"
            ? "SDL"
            : "G-Sec"}
      </p>
      <span>Bidding ends in {exptimer}</span>
      <div className="gsec_info">
        <div>
          <p>Tenure</p>
          <h3>{details?.gsecTenure}</h3>
        </div>
        <div>
          <p>
            {loaction.pathname === "/"
              ? "Interest Freq."
              : "Interest frequency"}
          </p>
          <h3>
            {details?.category === "GS" || details?.category === "SD"
              ? "Every 6 months"
              : "At maturity"}
          </h3>
        </div>
      </div>
      {details?.indicativeYield > 0 &&
        <div className="inductive_yield gsec_info_icon">
          <p>
            Indicative Yield
            <small>
              <span>
                <img src={infoicon} alt="infoicon" />
              </span>
              <span className="maturity_period_notif interest_apay">
                The indicative yield in government securities is an estimated
                return given by the issuer, taking into account the last traded
                price of the G-Sec. The actual yield will be determined by the
                weighted average price set during the auction.
                <span className="tringle_pointer"></span>
              </span>
            </small>
          </p>

          <h1>{details?.indicativeYield}%</h1>
        </div>
      }
    </div>
  );
};

export default OtherGsecCard;
