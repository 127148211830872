import "./ncdstyle.css";
import fallbackimg from "../../assets/images/fallback.svg";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
	getOpenIssuesList,
	getIssueDetailsFromCmsByCode,
} from "../../services/issuesServices";
import { ncdDateFormatter } from "../../helpers/utils";
import { CMS_URL, clevertap_key } from "../../vars/url";
import { useDispatch } from "react-redux";
import { applicationData } from "../../store/action/applicationData";
import { useSelector } from "react-redux";
import NoImg from "../../assets/images/noimg.jpg";
import { useHistory } from "react-router-dom";
import othimgplaceholder from "../../assets/images/IPODetails/noothrimg.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import timesvg from "./images/Time.svg";
import growthsvg from "./images/growth.svg";
import allotmentsvg from "./images/allotment.svg";
import ClevertapReact from "clevertap-react";
import LoaderImg from "../../assets/images/loader.svg";
import "./NewNcd.css";
import Group18219 from "../../assets/images/LandingPage/Group 18219.svg";
import IIFLSec from "../../assets/images/LandingPage/IIFL Sec.svg";
import NcdSeriesCard from "./NcdSeriesCard";
import NcdDetailSection from "./NcdDetailSection";
import { applicationData as setApplicationData } from "../../store/action/applicationData";
import { getNCDdetails } from "../../services/issuesServices";
import SweetAlert from "react-bootstrap-sweetalert";
import {
	actionFilterNcdSeries,
	actionSetNcdSeries,
} from "../../store/ncdseries/ncdAction";
import SideBar from "../SideBar/SideBar";
import Login from "../Login";
import useWindowDimensions from '../../hooks/screenWidth';

const NCDDetails = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { issueCode } = useParams();
	const [allncds, setallncds] = useState([]);
	const [ncddetails, setncddetails] = useState({});
	const [cmsdata, setcmsdata] = useState({});
	const [slidervalue, setslidervalue] = useState(0);
	const [counter, setCounter] = useState("");
	const [monthapplication, setmonthapplication] = useState([]);
	const applicationDataState = useSelector((state) => state.applicationData);
	const user = useSelector((state) => state.loggedIn);
	const [slidewidth, setslidewidth] = useState(0);
	const [morerange, setmorerange] = useState(false);
	const [cmsallncd, setcmsallncd] = useState([]);
	const [ratingStar, setratingStar] = useState(0);
	const [counterInterval, setcounterInterval] = useState({});
	const [loading, setLoading] = useState(true);
	const [applyLogin, setapplyLogin] = useState(false);
	const [seriesData, setSeriesData] = useState([]);
	const [ncdfulldetails, setncdfulldetails] = useState({});
	const [initialMinAmt, setInitialMinAmt] = useState(0);
	const [swalert, setalert] = useState("");
	const [activeTenure, setActiveTenure] = useState(0);
	const { filteredData, ncdSeriesData } = useSelector((state) => state.ncdReducer);
	const [activeMonth, setActiveMonth] = useState("");
	const { width } = useWindowDimensions();
	const [showPopup, setShowPopup] = useState(false);



	const [login, setLogin] = useState(false);

	const toggleLogin = () => {
		setLogin(!login);
	};

	const ncddetai = async () => {
		try {
			let {
				data: { resultData },
			} = await getOpenIssuesList("BOND");

			dispatch(applicationData({}));
			setallncds(resultData);

			var cmsarrall = [];

			for (let i = 0; i < resultData.length; i++) {
				try {
					var {
						data: { result },
					} = await getIssueDetailsFromCmsByCode(resultData[i].issuecode);
					cmsarrall.push(result);
				} catch (error) {
					cmsarrall.push({});
				}
			}
			setcmsallncd(cmsarrall);

			var details = resultData.filter(function (v, i) {
				return v.issuecode === issueCode;
			});
			setncddetails(details[0]);

			dispatch(actionSetNcdSeries(details[0]));

			var rating = details[0].bondRating;
			rating = rating?.replace(/[^a-zA-Z ]/g, "");
			var retstar = 0;
			if (rating == "AAA") {
				retstar = 5;
			}
			if (rating == "AA") {
				retstar = 4.5;
			}
			if (rating == "A") {
				retstar = 4;
			}
			if (rating == "BBB") {
				retstar = 3.5;
			}
			if (rating == "BB") {
				retstar = 3.5;
			}
			if (rating == "B") {
				retstar = 3;
			}
			if (rating == "C") {
				retstar = 2;
			}
			if (rating == "D") {
				retstar = 1;
			}

			setratingStar(retstar);

			if (details[0]) {
				setslidervalue(details[0].noOfMandatoryBonds * details[0].highprice);

				var setTimerInterval = setInterval(() => {
					setCounter(ncdDateFormatter(new Date(details[0].clsdt).getTime()));
				}, 1000);

				setcounterInterval(setTimerInterval);
			}
		} catch (e) {
			alert("Something went wrong try again later");
			setLoading(false);
		} finally {
			try {
				let {
					data: { result },
				} = await getIssueDetailsFromCmsByCode(issueCode);
				setcmsdata(result);
			} catch (error) {
				console.log(error);
				setcmsdata({});
			}

			function tenureret(
				array = details[0].ipoSeriesMap,
				columnName = "tenure"
			) {
				return array.map(function (value, index) {
					return value[columnName];
				});
			}
			ClevertapReact.initialize(clevertap_key);
			var payload = {
				Source: "OneUp HomePage",
				"Product Type": "NCD",
				"Issue Name": details[0]?.schname,
				"Issue size": ((details[0]?.highprice * details[0]?.issueQty) / 10000000).toFixed(2) + ' cr',
			};
			ClevertapReact.event("OU_Details Page Viewed", payload);

			setLoading(false);
		}
	};

	const setmonthselection = (value) => {
		if (monthapplication.includes(value)) {
			var index = monthapplication.indexOf(value);
			monthapplication.splice(index, 1);
		} else {
			monthapplication.push(value);
		}
		setmonthapplication([...monthapplication]);
	};

	//APPLY NCD
	const applyncd = async () => {
		let finalamountsld = ncdSeriesData
			?.map((item) => item.minAmt)
			.reduce((a, b) => a + b, 0);


		//CHECK FOR TOTAL INVESTMENT AMOUNT
		if (isNaN(finalamountsld) || finalamountsld < ncddetails.noOfMandatoryBonds * ncddetails.lowprice) {
			setalert("Investment should not be lower than minimum investment");
			setLoading(false);
			return false;
		};

		setLoading(true);
		var sendmonth = [];
		if (monthapplication.length < 1) {
			sendmonth = [];
		} else {
			sendmonth = monthapplication;
		}
		dispatch(
			applicationData({
				...applicationDataState,
				ncdcat: "IND|RET",
				clientcode: "",
				mobileNo: "",
				email: "",
				panNo: "",
				fstname: "",
				midname: "",
				lstname: "",
				applyfr: "NSDL",
				surName: "",
				// dpid: '',
				// beneficiaryID: '',
				selfoth: "Self",

				dematDetailsapp: [],
				monthselected: sendmonth,
				mainslidervalue: ncdSeriesData
					?.map((item) => item.minAmt)
					.reduce((a, b) => a + b, 0),
				ncddetails: ncddetails,
				cmsdata: cmsdata,
				series: ncdSeriesData,
				ncdfulldetails: ncdfulldetails,
			})
		);

		if (Object.keys(user).length > 0) {
			try {
				let data = await getNCDdetails(issueCode);
				dispatch(
					applicationData({
						...applicationDataState,
						ncdcat: "IND|RET",
						applyfr: "NSDL",
						surName: "",
						dematDetailsapp: [],
						selfoth: 'Self',
						monthselected: sendmonth,
						mainslidervalue: ncdSeriesData
							?.map((item) => item.minAmt)
							.reduce((a, b) => a + b, 0),
						ncddetails: ncddetails,
						cmsdata: cmsdata,
						series: ncdSeriesData,
						ncdfulldetails: data.data.resultData,
					})
				);
			} catch (error) {
				console.log(error)
			}

			if (
				user.user.clientType === "IIFLCLIENT" &&
				user.user.AppSource !== "9" &&
				user.user.AppSource !== "11" &&
				user.user.AppSource !== "12" &&
				user.user.AppSource !== "13"
			) {
				let data = await getNCDdetails(issueCode);
				dispatch(
					setApplicationData({
						...applicationData,
						ncdcat: "IND|RET",
						clientcode: user.user.clientcode,
						mobileNo: user.user.mobileNo,
						email: user.user.email,
						panNo: user.user.panNo,
						fstname: user.user.fstname,
						midname: user.user.midname,
						lstname: user.user.fullname||user.user.firstName,
						applyfr: "NSDL",
						dpid: "",
						beneficiaryID: "",
						selfoth: "Self",
						dematDetailsapp: [],
						monthselected: sendmonth,
						mainslidervalue: ncdSeriesData
							?.map((item) => item.minAmt)
							.reduce((a, b) => a + b, 0),
						ncddetails: ncddetails,
						cmsdata: cmsdata,
						series: ncdSeriesData,
						ncdfulldetails: data.data.resultData,
						surName: "",
					})
				);
				history.push("/ncd-application-step-3");
			} else {
				history.push("/ncd-application-step-2");
			}
		} else {
			setLoading(false);
			setapplyLogin(true);

			ClevertapReact.initialize(clevertap_key);
			ClevertapReact.event("OU_Login Completed", {
				Source: 'NCD Detail Page',
				"Issue Name": ncddetails.schname,
			})
			toggleLogin();
		}
	};

	const applyAutoLogin = async () => {
		if (Object.keys(user).length > 0 && applyLogin === true) {
			applyncd();
		}
	};

	useEffect(() => {
		let unmounted = false;
		if (!unmounted) {
			scrollTop();
			ncddetai();
		}
		return () => {
			unmounted = true;
		};
	}, [issueCode]);

	useEffect(() => {
		let unmounted = false;
		if (!unmounted) {
			applyAutoLogin();
		}
		return () => {
			unmounted = true;
		};
	}, [user]);

	const scrollTop = () => {
		var element = document.querySelector("header#topsection");
		if (element) {
			element.scrollIntoView();
		}
	};

	// const Ncdfulldetails = async () => {
	// 	try {
	// 		let data = await getNCDdetails(issueCode);

	// 		setncdfulldetails(data.data.resultData);
	// 		dispatch(
	// 			applicationData({
	// 				...applicationDataState,
	// 				ncdfulldetails: data.data.resultData,
	// 			})
	// 		);
	// 		setLoading(false);
	// 	} catch {
	// 		localStorage.removeItem("user");
	// 		history.push("/ncd_details/" + applicationData.ncddetails?.issuecode);
	// 	}
	// };

	// useEffect(() => {
	// 	if (user?.loggedIn) {
	// 		Ncdfulldetails();
	// 	}
	// }, [user]);

	const hidesweeetalert = () => {
		setalert("");
	};

	return (
		<>



			<div className="ncd_popup">
				<div id="popup1" className={showPopup ? "overlay showPopup" : "overlay"}>
					<div className="popup">
						<span onClick={() => setShowPopup(false)} className="close">&times;</span>
						<div className="content ">
							<div class="table-responsive">
								<table class="table table-bordered">
									<tbody>
										<tr>
											<td>Series</td>
											<td className="text-muted" colspan="4">1</td>
										</tr>
										<tr>
											<td>Type of Instrument</td>
											<td className="text-muted" colspan="4">Secured NCD</td>
										</tr>
										<tr>
											<td>Frequency of Interest Payment</td>
											<td className="text-muted" colspan="4">Half Yearly</td>
										</tr>
										<tr>
											<td>Minimum Application</td>
											<td className="text-muted" colspan="4"><i class="fa fa-rupee mrgR3"></i> 10,000 (10 NCDs) across all Series</td>
										</tr>
										<tr>
											<td>Face Value of NCDs (<i class="fa fa-rupee mrgR3"></i>/NCD)</td>
											<td className="text-muted" colspan="4"><i class="fa fa-rupee mrgR3"></i> 1,000</td>
										</tr>
										<tr>
											<td>In Multiples of thereafter (<i class="fa fa-rupee mrgR3"></i>)</td>
											<td className="text-muted" colspan="4"><i class="fa fa-rupee mrgR3"></i> 1,000 (1 NCD)</td>
										</tr>
										<tr>
											<td>STRPP with different ISIN</td>
											<td className="text-muted">STRPP A</td>
											<td className="text-muted">STRPP B</td>
											<td className="text-muted">STRPP C</td>
											<td className="text-muted">STRPP D</td>
										</tr>
										<tr>
											<td>Tenor</td>
											<td className="text-muted">3 Years</td>
											<td className="text-muted">5 Years</td>
											<td className="text-muted">7 Years</td>
											<td className="text-muted">9 Years</td>
										</tr>
										<tr>
											<td>Coupon (% per annum) for NCD Holders in Category I, II, III & IV</td>
											<td className="text-muted">8.25%</td>
											<td className="text-muted">8.25%</td>
											<td className="text-muted">8.25%</td>
											<td className="text-muted">8.25%</td>
										</tr>
										<tr>
											<td>Effective Yield (per annum) for NCD Holders in Category I, II, III & IV</td>
											<td className="text-muted">8.41%</td>
											<td className="text-muted">8.41%</td>
											<td className="text-muted">8.41%</td>
											<td className="text-muted">8.42%</td>
										</tr>
										<tr>
											<td>Maturity/Redemption (from the Deemed date of Allotment)</td>
											<td className="text-muted">3 Years</td>
											<td className="text-muted">5 Years</td>
											<td className="text-muted">7 Years</td>
											<td className="text-muted">9 Years</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="zoom_wrapper">
				{login && <Login toggleLogin={toggleLogin} />}


				{loading == true && (
					<div className="loading_screen">
						<img src={LoaderImg} alt="loading..." />
						<p>Please wait while fetching..</p>
					</div>
				)}

				{swalert != "" && (
					<SweetAlert
						custom
						showCancel
						showCloseButton
						error
						title="Alert!"
						cancelBtnBsStyle="light"
						onConfirm={hidesweeetalert}
						onCancel={hidesweeetalert}
					>
						{swalert}
					</SweetAlert>
				)}
			</div>


			<div className="mobile_top_haeder">
				<SideBar toggleLogin={toggleLogin} />
			</div>

			<div className="ncd_haeder d-md-block d-none">
				<div className="custom_container">
					<img onClick={() => history.push('/')} width={100} src={Group18219} alt="logo" />
					<span className="px-3">Powered by</span>
					<img src={IIFLSec} alt="logo" />
				</div>
			</div>

			{/* NCD DETAIL SECTION START*/}
			<div className="ncd_top_section">
				<NcdDetailSection
					ncddetails={ncddetails}
					counter={counter}
					cmsdata={cmsdata}
					setShowPopup={setShowPopup}
				/>
			</div>
			{/* NCD DETAIL SECTION END*/}

			{/* NCD SERIES SECTION START*/}
			<div className="ncd_series_wrapper">
				<div className="custom_container py-5 ">
					<div className="ncd_series_option">
						{
							issueCode === 'NHIT01' ?
								[
									"All",
								].map((item, i) => (

									<div key={i} className={activeTenure === i ? "active" : ""}>

										<p
											style={{ width: item === 'All' ? width < 768 ? '80px' : '' : '' }}
											onClick={() => {
												setActiveTenure(i);
												setActiveMonth(item);
												dispatch(actionFilterNcdSeries(item));
											}}
										>
											{i === 0 ? item : item.toLowerCase()}
										</p>
									</div>
								))
								:
								[
									"All",
									...new Set(ncddetails?.ipoSeriesMap?.map((item) => item.tenure))
								].map((item, i) => (

									<div key={i} className={activeTenure === i ? "active" : ""}>

										<p
											style={{ width: item === 'All' ? width < 768 ? '80px' : '' : '' }}
											onClick={() => {
												setActiveTenure(i);
												setActiveMonth(item);
												dispatch(actionFilterNcdSeries(item));
											}}
										>
											{i === 0 ? item : item.toLowerCase()}
										</p>
									</div>
								))}
					</div>
					<div className="select_series d-md-none">
						Select Series
					</div>

					<div className="ncd_series_section">
						{filteredData?.map((item, ind) => (
							<NcdSeriesCard
								ncddetails={ncddetails}
								activeMonth={activeMonth}
								e={item}
								key={ind}
								i={ind}
								setShowPopup={setShowPopup}
							/>
						))}
					</div>
				</div>
			</div>
			{/* NCD SERIES SECTION END*/}

			{ncdSeriesData?.map((item) => item.minAmt).reduce((a, b) => a + b, 0) > 0 &&
				<div className="total_invetment_sec_wrap">
					<div className="custom_container">
						<div className="total_invetment_sec ">
							<div>
								<h1><i class="fa fa-rupee mrgR3"></i>{(ncdSeriesData?.map((item) => item.minAmt).reduce((a, b) => a + b, 0)).toLocaleString()}</h1>
								<p>Total Investment</p>
							</div>
							<button onClick={applyncd}>Next</button>
						</div>
					</div>
				</div>
			}

		</>
	);
};

export default NCDDetails;
