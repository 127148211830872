import CryptoJS from "crypto-js";

export const decryptionWithCryptoJS = (cipher) => {
  const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_DECRYPTION_KEY);
  const iv1 = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_DECRYPTION_KEY);
  const plainText = CryptoJS.AES.decrypt(cipher, key, {
    keySize: 16,
    iv: iv1,
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });

  return plainText.toString(CryptoJS.enc.Utf8);
};

// ENCRYPT METHOD FOR USER DATA MASKING
export const dataEncrypt = (data) => {
  const keyHex = CryptoJS.enc.Utf8.parse(
    process.env.REACT_APP_DATA_MASKING_KEY
  );
  const ivHex = CryptoJS.enc.Hex.parse("0000000000000000");

  const encrypted = CryptoJS.AES.encrypt(data, keyHex, {
    iv: ivHex,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  return encrypted.toString();
};

// DECRYPT METHOD FOR USER DATA MASKING
export const dataDecrypt = (data) => {
  const keyHex = CryptoJS.enc.Utf8.parse(
    process.env.REACT_APP_DATA_MASKING_KEY
  );
  const ivHex = CryptoJS.enc.Hex.parse("0000000000000000");

  const decrypted = CryptoJS.AES.decrypt(data, keyHex, {
    iv: ivHex,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  return decrypted.toString(CryptoJS.enc.Utf8);
};
