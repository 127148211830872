import * as gsecType from "./gsecType";

const INITIAL_STATE = {
    gsecList: [],
};


const gsecReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case gsecType.GSEC_LIST:
            return {
                ...state,
                gsecList: action.payload,
            };

        default:
            return state;
    };
};

export default gsecReducer;