import React from "react";
import { useParams } from "react-router-dom";
import "./GsecDetailPage.css";
import ExploreMore from "./ExploreMore";
import HowItWork from "./HowItWork";
import InvestNowCard from "./InvestNowCard";
import GsecKnow from "./GsecKnow";
import GsecBasicDetail from "./GsecBasicDetail";
import GsecHeader from "./GsecHeader";
import { useEffect } from "react";
import { getOpenIssuesList } from "../../../../services/issuesServices";
import { useState } from "react";
import SideBar from "../../../SideBar/SideBar";
import Group18219 from "../../../../assets/images/LandingPage/Group 18219.svg";
import IIFLSec from "../../../../assets/images/LandingPage/IIFL Sec.svg";
import { useHistory } from "react-router-dom";
import Login from "../../../Login";
import DematAccountModal from "../DematAccountModal/DematAccountModal";
import GsecPaymentDrawer from "../GsecPaymentDrawer/GsecPaymentDrawer";
import GsecPaymentDetail from "../../../GsecPaymentDetail/GsecPaymentDetail";

const GsecDetailPage = ({
  setAddGsecResult,
  gsecDetail,
  setGsecDetail,
  invtQty,
  setInvtQty,
}) => {
  const [allGsecList, setAllGsecList] = useState([]);
  const params = useParams();
  const history = useHistory();
  const [login, setLogin] = useState(false);
  const [showPaymentDrawer, setShowPaymentDrawer] = useState(false);
  const [showDematAccModal, setShowDematAccModal] = useState(false);
  const [paynowLoader, setPaynowLoader] = useState(false);

  const fetchGsec = async () => {
    let { data } = await getOpenIssuesList("all");
    let gseclist = data.resultData.filter(function (v, i) {
      return (
        v.category.trim() == "GS" ||
        v.category.trim() == "TB" ||
        v.category.trim() == "SD"
      );
    });

    const result = gseclist.filter(
      (item) => item.issuecode === params.issueCode
    )[0];
    setGsecDetail(result);

    setAllGsecList(
      gseclist.filter((item) => item.issuecode !== params.issueCode)
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchGsec();
  }, [params.issueCode]);

  const toggleLogin = () => {
    setLogin(!login);
  };

  return (
    <>
      {paynowLoader && (
        <div className="payment_loader">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <h2>We are processing your payment</h2>
        </div>
      )}

      <div className="invest_now_login">
        {login && <Login toggleLogin={toggleLogin} />}
      </div>
      <div className="mobile_top_haeder">
        <SideBar toggleLogin={toggleLogin} />
      </div>

      <div className="gsec_detail_haeder d-md-block d-none">
        <div className="">
          <img
            onClick={() => history.push("/")}
            width={100}
            src={Group18219}
            alt="logo"
          />
          <span className="px-3">Powered by</span>
          <img src={IIFLSec} alt="logo" />
        </div>
      </div>

      <div className="gsec_detail_wrapp">
        <div className="custom_container">
          <div className="content_wrapper">
            <GsecHeader gsecDetail={gsecDetail} />
            <div className="gsec_card_details">
              <div className="row">
                <div className="col-md-12 col-lg-7">
                  <div className="row g-5">
                    <div className="col-md-12">
                      <GsecBasicDetail gsecDetail={gsecDetail} />
                    </div>
                    <div className="col-md-12">
                      <GsecKnow />
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <InvestNowCard
                    toggleLogin={toggleLogin}
                    gsecDetail={gsecDetail}
                    setShowPaymentDrawer={setShowPaymentDrawer}
                    invtQty={invtQty}
                    setInvtQty={setInvtQty}
                    setShowDematAccModal={setShowDematAccModal}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <HowItWork
          title="How it works?"
          isDate={true}
          gsecDetail={gsecDetail}
        />

        <div className="custom_container">
          <GsecPaymentDetail gsecDetail={gsecDetail} invtQty={invtQty} />
          <div className="content_wrapper">
            <ExploreMore allGsecList={allGsecList} />
          </div>
        </div>
      </div>

      <GsecPaymentDrawer
        setPaynowLoader={setPaynowLoader}
        showPaymentDrawer={showPaymentDrawer}
        setShowPaymentDrawer={setShowPaymentDrawer}
        gsecDetail={gsecDetail}
        invtQty={invtQty}
        setAddGsecResult={setAddGsecResult}
      />

      {showDematAccModal && (
        <DematAccountModal setShowDematAccModal={setShowDematAccModal} />
      )}
    </>
  );
};

export default GsecDetailPage;
