import * as ncdTypes from "./ncdType";


export const actionSetNcdSeries = (data) => {
    return {
        type: ncdTypes.NCD_SERIES,
        payload: data,
    };
};

export const actionFilterNcdSeries = (data) => {
    return {
        type: ncdTypes.FILTER_SERIES,
        payload: data,
    };
};

export const actionSetCheckValue = (data) => {
    return {
        type: ncdTypes.UPDATE_CHECK_VALUE,
        payload: data,
    };
};

export const actionSetNcdDetailData = (data) => {
    return {
        type: ncdTypes.NCD_DETAIL_DATA,
        payload: data,
    };
};

export const actionUpdateQty = (data) => {
    return {
        type: ncdTypes.UPDATE_QTY,
        payload: data,
    };
};

export const actionUncheckSeries = (data) => {
    return {
        type: ncdTypes.UNCHECK_SERIES,
        payload: data,
    };
};

export const actionOthersDetail = (data) => {
    return {
        type: ncdTypes.OTHER_DETAIL,
        payload: data,
    };
};