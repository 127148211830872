import { useEffect, useState } from "react";
import "./AsbaPaymentModal.css";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import pay_check from "../../assets/images/NCDDetail/pay_check1.svg";
import close1 from "../../assets/images/NCDDetail/close1.svg";
import close2 from "../../assets/images/NCDDetail/close2.svg";
import {
  getpdfcoordinatencd,
  getpdfFromCmsByCode,
  zohoCreateToken,
  zohoCreateLead,
} from "../../services/issuesServices";
import ClevertapReact from "clevertap-react";
import { CMS_URL, clevertap_key } from "../../vars/url";

const AsbaPaymentModal = ({ setAsbaConfirmationModal }) => {
  const history = useHistory();
  const [pdf, setPdf] = useState({});
  const applicationData = useSelector((state) => state.applicationData);
  const fetchform = async () => {
    if (applicationData.paymenttype === "ASBA") {
      let { data } = await getpdfcoordinatencd(
        applicationData.ncdfulldetails.issuecode,
        applicationData.clientcode,
        applicationData.placedncddetails.transcode.split("|")[1]
      );
      console.log("prntdtlls", data);
      if (data.statusCode == 200 && data.isSuccess == true) {
        let sampledt = {};
        sampledt.issuecode = applicationData.ncdfulldetails.issuecode;
        sampledt.applicationNo =
          applicationData.placedncddetails.transcode.split("|")[1];
        sampledt.coOrdinates = data.resultData;
        if (applicationData?.ncdhistory) {
          sampledt.ismodify = 1;
        }
        let finaldetails = await getpdfFromCmsByCode({
          sampleRes1: sampledt,
          sampleResponse2: {},
        });
        setPdf(finaldetails);
      }
    }

    // Zoho start
    if (JSON.parse(localStorage.getItem("user")).clientType === "IIFLCLIENT") {
      zohoCreateToken().then((resp) => {
        console.log("zoho", resp);
        zohoCreateLead({
          Token: resp.data.Body.Token,
          ObjectName: "Lead",
          Parameters: {
            FirstName: "",
            LastName: applicationData.lstname, //Dynamic from the field “Name”
            Mobile: applicationData.mobileNo, //Dynamic from the field “mobile number”
            Email: applicationData.email, //Dynamic from the field “email id”
            LeadProduct: "Equity", //Fixed
            Campaign: "NCD", //Fixed
            LeadSource: "OneUp", //Fixed
            LeadSubStatusTemp: "Completed", //Fixed
            Appsource: "25",
          },
        });
      });
    }
    // Zoho End

    // Clevertap start
    // console.log('appledt', applicationData);
    ClevertapReact.initialize(clevertap_key);
    function getSeriesNamect(array, keysearch) {
      var fianalArr = [];
      array?.forEach((element) => {
        fianalArr.push(element[keysearch]);
      });
      return fianalArr;
    }
    var payload = {
      Source: "NCD Details Page",
      "NCD Name": applicationData.ncdfulldetails?.schemename,
      "Bid Number": applicationData.placedncddetails?.transcode.split("|")[1],
      "No. of series": getSeriesNamect(
        applicationData.ncdfulldetails?.ipoSeriesMap,
        "seriesNumber"
      ).join(","),
      "Investment Amount": applicationData.mainslidervalue,
      "Investor category": applicationData.ncdcat,
      "Applying for": applicationData.selfoth,
      "payment type": applicationData.paymenttype,
      "Application number":
        applicationData.placedncddetails?.transcode.split("|")[0],
    };
    ClevertapReact.event("NCD_Application Step 4", payload);
    // Clevertap End
  };

  useEffect(() => {
    fetchform();
  }, []);

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const onBackButtonEvent = (e) => {
    e.preventDefault();

    if (window.confirm("Do you want to go back ?")) {
      // your logic
      history.push("/ncd_details/" + applicationData.ncddetails.issuecode);
    } else {
      window.history.pushState(null, null, window.location.pathname);
    }
  };

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    const message =
      "Are you sure you want to leave? All provided data will be lost.";
    e.returnValue = message;
    return message;
  };

  return (
    <div className="">
      <div className="asba_confirm_model_wrapper ">
        <div className="asba_confirm_model">
          <div className="asba_confirm_model_container">
            <div
              onClick={() =>
                history.push(
                  "/ncd_details/" + applicationData?.ncddetails?.issuecode
                )
              }
              className="asba_close_icon"
            >
              <img src={close1} alt="close1" />
              <img src={close2} alt="close2" />
            </div>
            <div className="asba_pay_approve mt-3">
              <div className="payment_check">
                <img src={pay_check} alt="pay_check" />
              </div>
              <div>
                <span>Your application request received!</span>
                <br />
                <small className="text-muted">
                  To avoid cancellation, check the next steps
                </small>
              </div>
            </div>

            <h4 className="pt-5 pb-3 Your_next_steps">Your next steps</h4>
            <div className="stepsWrap">
              {/* <div className='asba_pay_connector'></div> */}

              <div className="d-flex pb-5 left-line">
                <div className="payment_checkdd">1</div>
                <span>Download & print the Application form</span>
              </div>
              <div className="d-flex pt-3 pb-5 left-line">
                <div className="payment_checkdd">2</div>
                <span>Fill the required details & sign</span>
              </div>
              <div className="d-flex pt-3 pb-5 left-line">
                <div className="payment_checkdd">3</div>
                <span>Submit the form at your bank’s Branch</span>
              </div>
              <div className="d-flex pt-3 left-line">
                <div className="payment_checkdd">4</div>
                <span>
                  After form submission, funds will <br /> be blocked in your
                  bank A/C
                </span>
              </div>
            </div>

            <div className="asba_view_app_Btn d-flex justify-content-center pt-5">
              <a
                href={pdf?.data?.data?.file}
                target="_blank"
                class="download-app"
              >
                Download form
              </a>
            </div>
            <div className="asba_confirm_Done_buttn d-flex justify-content-center">
              <button
                onClick={() => {
                  localStorage.setItem("app_history", window.location.pathname);
                  history.push("/your_applications");
                  setAsbaConfirmationModal(false);
                }}
              >
                View application status
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AsbaPaymentModal;
