import "./ncd-application-04.css";
import { useEffect, useState } from "react";
import {
  getpdfcoordinatencd,
  getpdfFromCmsByCode,
  zohoCreateToken,
  zohoCreateLead,
} from "../../services/issuesServices";
import { CMS_URL, clevertap_key } from "../../vars/url";
import { useSelector } from "react-redux";
import NoImg from "../../assets/images/noimg.jpg";
import { useHistory } from "react-router-dom";
import ClevertapReact from "clevertap-react";
import close1 from "../../assets/images/NCDDetail/close1.svg";
import close2 from "../../assets/images/NCDDetail/close2.svg";

import pay_approve from "../../assets/images/NCDDetail/pay_approve.svg";
import pay_check from "../../assets/images/NCDDetail/pay_check1.svg";
import moment from "moment/moment";

const Ncdapplication4 = () => {
  const history = useHistory();
  const [pdf, setPdf] = useState({});
  const applicationData = useSelector((state) => state.applicationData);
  const fetchform = async () => {
    if (applicationData.paymenttype === "ASBA") {
      let { data } = await getpdfcoordinatencd(
        applicationData.ncdfulldetails.issuecode,
        applicationData.clientcode,
        applicationData.placedncddetails.transcode.split("|")[1]
      );

      if (data.statusCode == 200 && data.isSuccess == true) {
        let sampledt = {};
        sampledt.issuecode = applicationData.ncdfulldetails.issuecode;
        sampledt.applicationNo =
          applicationData.placedncddetails.transcode.split("|")[1];
        sampledt.coOrdinates = data.resultData;
        if (applicationData?.ncdhistory) {
          sampledt.ismodify = 1;
        }
        let finaldetails = await getpdfFromCmsByCode({
          sampleRes1: sampledt,
          sampleResponse2: {},
        });
        setPdf(finaldetails);
      }
    }

    // Zoho start
    if (JSON.parse(localStorage.getItem("user")).clientType === "IIFLCLIENT") {
      zohoCreateToken().then((resp) => {
        zohoCreateLead({
          Token: resp.data.Body.Token,
          ObjectName: "Lead",
          Parameters: {
            FirstName: "",
            LastName: applicationData.lstname, //Dynamic from the field “Name”
            Mobile: applicationData.mobileNo, //Dynamic from the field “mobile number”
            Email: applicationData.email, //Dynamic from the field “email id”
            LeadProduct: "Equity", //Fixed
            Campaign: "NCD", //Fixed
            LeadSource: "OneUp", //Fixed
            LeadSubStatusTemp: "Completed", //Fixed
            Appsource: "25",
          },
        });
      });
    }
    // Zoho End

    // Clevertap start

    ClevertapReact.initialize(clevertap_key);
    function getSeriesNamect(array, keysearch) {
      var fianalArr = [];
      array?.forEach((element) => {
        fianalArr.push(element[keysearch]);
      });
      return fianalArr;
    }
    var ClevertapPayload = {
      Source: "OneUp HomePage",
      Step: "4",
      "Product Type": "NCD",
      "Issue Name": applicationData.ncdfulldetails?.schemename,
      "Bid Number": applicationData?.series?.filter(
        (item) => item.check === true
      )?.length,
      "Investment Amount": applicationData.mainslidervalue,
      "Price Type": "",
      "Applying for": applicationData.selfoth,
      "Investor category": applicationData.ncdcat,
      "payment type": applicationData.paymenttype,
      "Failure Message": "",
      "Application number":
        applicationData.placedncddetails?.transcode.split("|")[0],
      Amount: "",
      Series: applicationData?.series
        ?.filter((item) => item.check === true)
        .map((item) => item.seriesNumber)
        .join(","),
    };
    ClevertapReact.event("OU_Application Step", ClevertapPayload);
    // Clevertap End
  };

  useEffect(() => {
    fetchform();
  }, []);

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const onBackButtonEvent = (e) => {
    e.preventDefault();

    if (window.confirm("Do you want to go back ?")) {
      // your logic
      history.push("/ncd_details/" + applicationData.ncddetails.issuecode);
    } else {
      window.history.pushState(null, null, window.location.pathname);
    }
  };

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    const message =
      "Are you sure you want to leave? All provided data will be lost.";
    e.returnValue = message;
    return message;
  };

  return (
    <>
      {applicationData?.paymenttype === "UPI" ? (
        <div className="upi_confirm_model_wrapper">
          <div className="upi_confirm_model">
            <div className="confirm_model_container">
              <div className="pay_approve">
                <img src={pay_approve} alt="pay_approve" />
                <h4 className="py-4" style={{ color: "#FFA928" }}>
                  <i class="fa fa-rupee mrgR3"></i>
                  {(applicationData?.mainslidervalue).toLocaleString()}
                </h4>

                <span>Approve payment on your UPI app </span>
              </div>

              <div className="p-5">
                <div className="d-flex py-5 line-left1">
                  <div className="payment_check">
                    <img src={pay_check} alt="pay_check" />
                  </div>
                  <div className="app_recieved">
                    <span>Your application has been received</span>
                    <br />
                    <small className="text-muted">
                      Today {moment().format("h:mm A")}
                    </small>
                  </div>
                </div>

                <div className="d-flex pt-3 pb-5">
                  <div className="payment_info">
                    <img src={pay_approve} alt="pay_approve" />
                  </div>
                  <div className="app_recieved">
                    <span>Payment approval pending</span>
                    <br />
                    <small className="text-muted">
                      Please check your UPI app to approve the payment request
                      of <i class="fa fa-rupee mrgR3"></i>
                      {(applicationData?.mainslidervalue).toLocaleString()} to
                      complete the NCD application.
                    </small>
                  </div>
                </div>

                <div className="view_app_Btn d-flex justify-content-center pt-5">
                  <button
                    onClick={() => {
                      localStorage.setItem(
                        "app_history",
                        window.location.pathname
                      );
                      history.push("/your_applications");
                    }}
                  >
                    View Application Status
                  </button>
                </div>
                <div className="confirm_Done_buttn d-flex justify-content-center">
                  <button
                    onClick={() => {
                      history.push("/");
                    }}
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="mobile_asba_confirm_model_wrapper">
          <div className="mobile_asba_confirm_model">
            <div className="asba_confirm_model_container">
              <div
                onClick={() =>
                  history.push(
                    "/ncd_details/" + applicationData?.ncdfulldetails?.issuecode
                  )
                }
                className="asba_close_icon"
              >
                <img src={close1} alt="close1" />
                <img src={close2} alt="close2" />
              </div>
              <div className="asba_pay_approve mt-3">
                <div className="payment_check">
                  <img src={pay_check} alt="pay_check" />
                </div>
                <div>
                  <span className="text-title">
                    Your application request received!
                  </span>
                  <br />
                  <small className="text-muted">
                    To avoid cancellation, check the next steps
                  </small>
                </div>
              </div>

              <h4 className="pt-5 pb-3 Your_next_steps">Your next steps</h4>
              <div className="stepsWrap">
                <div className="d-flex pb-5 left-line">
                  <div className="payment_checkdd">1</div>
                  <span>Download & print the Application form</span>
                </div>
                <div className="d-flex pt-3 pb-5 left-line">
                  <div className="payment_checkdd">2</div>
                  <span>Fill the required details & sign</span>
                </div>
                <div className="d-flex pt-3 pb-5 left-line">
                  <div className="payment_checkdd">3</div>
                  <span>Submit the form at your bank’s Branch</span>
                </div>
                <div className="d-flex pt-3 left-line">
                  <div className="payment_checkdd">4</div>
                  <span>
                    After form submission, funds will be blocked in your bank
                    A/C
                  </span>
                </div>
              </div>

              <div className="asba_view_app_Btn d-flex justify-content-center pt-5">
                <a
                  href={pdf?.data?.data?.file}
                  target="_blank"
                  class="download-app"
                >
                  Download form
                </a>
              </div>
              <div className="asba_confirm_Done_buttn d-flex justify-content-center">
                <button
                  onClick={() => {
                    localStorage.setItem(
                      "app_history",
                      window.location.pathname
                    );
                    history.push("/your_applications");
                  }}
                >
                  View Application Status
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Ncdapplication4;
