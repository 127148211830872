

const StrengthWeekness = (props) => {
    const {type} = props


    return (
        <>
           
            <section className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <table width="100%" id="StrengthWeakness">
                                <tr>
                                    <th>Strength</th>
                                    <th>Weakness</th>
                                </tr>
                                <tr>
                                    <td>Indian Railway Finance Corporation (IRFC) is a partly owned subsidiary of the Indian Railways</td>
                                    <td>Indian Railway Finance Corporation (IRFC) is a partly owned subsidiary of the Indian Railways</td>
                                </tr>
                                <tr>
                                    <td>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley.</td>
                                    <td>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley.</td>
                                </tr>
                                <tr>
                                    <td>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley.</td>
                                    <td>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley.</td>
                                </tr>
                                <tr>
                                    <td className="border-bottom-0">Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley.</td>
                                    <td className="border-bottom-0">Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley.</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </section>

            


        </>
    )
}


export default StrengthWeekness