import * as npsType from "./npsType";


export const actionSetNpsData = (data) => {
    return {
        type: npsType.NPS_DATA,
        payload: data,
    };
};

export const actionSetNpsPaymentMethod = (data) => {
    return {
        type: npsType.NPS_PAYMENT_METHOD,
        payload: data,
    };
};

