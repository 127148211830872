import s from "./DetailsScreen.module.scss";
import NoImg from "../../../assets/images/noimg.jpg";
import Detailssingle from "./DetailsSingle";
import { useLocation, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  getOpenIssuesList,
  getIssuesByCodes,
} from "../../../services/issuesServices";
import icon1 from "./assets/icon1.png";
import icon2 from "./assets/icon2.png";
import icon3 from "./assets/icon3.png";
import icon4 from "./assets/icon4.png";
import icon7 from "./assets/icon7.png";
import close from "./assets/close.svg";
import GsecIndemand from "./GsesIndemand";
import GsecSorting from "../../GsecSorting/GsecSorting";
import GsecPagination from "../../GsecPagination/GsecPagination";

const SortOption = ({ issueList, filteredGsecList, setFilteredGsecList }) => {
  const [filterByYear, setFilterByYear] = useState("");
  const [filterByType, setFilterByType] = useState("");
  const [allGsecList, setAllGsecList] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [activeFilter, setActiveFilter] = useState("1");
  const [gsecType, setGsecType] = useState("");
  const [selectedSort, setSelectedSort] = useState("");
  const [showSortBy, setShowSortBy] = useState(false);

  const handleSortGsec = (e) => {
    const val = e.target.value;
    setSelectedSort(val);
    if (val === "1") {
      var inc = filteredGsecList.sort((a, b) => a.tenureYear - b.tenureYear);
      setFilteredGsecList([...inc]);
    }
    if (val === "2") {
      var des = filteredGsecList.sort((a, b) => b.tenureYear - a.tenureYear);
      setFilteredGsecList([...des]);
    }
    if (val === "3") {
      const inc = filteredGsecList.sort(
        ({ indicativeYield: a }, { indicativeYield: b }) => a - b
      );
      setFilteredGsecList([...inc]);
    }
    if (val === "4") {
      const des = filteredGsecList.sort(
        ({ indicativeYield: a }, { indicativeYield: b }) => b - a
      );
      setFilteredGsecList([...des]);
    }
    setShowSortBy(false);
  };

  const handleApplyFilter = () => {
    if (activeFilter === "1" && filterByType) {
      const result = issueList.filter((item) => item.category === filterByType);
      setFilteredGsecList(result);
      setShowFilter(false);
    }
    if (activeFilter === "2" && filterByYear) {
      const result = issueList.filter(
        (item) => item.tenureYear <= parseFloat(filterByYear)
      );
      setFilteredGsecList(result);
      setShowFilter(false);
    }
  };

  return (
    <>
      {showFilter && <div className={s.filter_overlay}></div>}

      <div className={s.sorting}>
        {/* <span>Sort <img src={icon8} /></span> */}

        {/* <span>Highest Returns</span> */}
        {/* <span>Lowest Risk</span> */}
      </div>

      <div className={s.filterAndSort}>
        <div className={s.sorting}>
          <span onClick={() => setShowFilter(true)} className={s.filterTitle}>
            Filter <img src={icon7} />
          </span>
        </div>
        <GsecSorting
          selectedSort={selectedSort}
          setShowSortBy={setShowSortBy}
          showSortBy={showSortBy}
          title="Select"
          handleSortGsec={handleSortGsec}
        />
      </div>

      <div className={`${s.filter_popup} ${showFilter ? s.open : ""}`}>
        <div className={s.filter_heading}>
          <h1>Filter</h1>
          <img onClick={() => setShowFilter(false)} src={close} alt="close" />
        </div>
        <div className={s.filterType}>
          <div className="d-flex">
            <div className={s.filter_name}>
              <h1
                onClick={() => setActiveFilter("1")}
                className={activeFilter === "1" ? s.active_filter : ""}
              >
                G-Sec Type
              </h1>
              <h1
                onClick={() => setActiveFilter("2")}
                className={activeFilter === "2" ? s.active_filter : ""}
              >
                Tenure
              </h1>
            </div>
            <div className={s.filterDivider}></div>
            <div className={s.filter_option}>
              {activeFilter === "1" ? (
                <>
                  <p>Filter by</p>
                  <div className={s.filter_tenure}>
                    <input
                      onChange={(e) => setFilterByType(e.target.value)}
                      type="radio"
                      id="GS"
                      name="filter_Type"
                      value="GS"
                    />
                    <label htmlFor="GS">Govt bond</label>
                  </div>
                  <div className={s.filter_tenure}>
                    <input
                      onChange={(e) => setFilterByType(e.target.value)}
                      type="radio"
                      id="TB"
                      name="filter_Type"
                      value="TB"
                    />
                    <label htmlFor="TB">T- bill (Treasury bills)</label>
                  </div>
                  <div className={s.filter_tenure}>
                    <input
                      onChange={(e) => setFilterByType(e.target.value)}
                      type="radio"
                      id="SD"
                      name="filter_Type"
                      value="SD"
                    />
                    <label htmlFor="SD">
                      SDL (State <br /> Development Loans)
                    </label>
                  </div>
                </>
              ) : (
                <>
                  <p>Filter by</p>
                  <div className={s.filter_tenure}>
                    <input
                      onChange={(e) => setFilterByYear(e.target.value)}
                      type="radio"
                      id="1"
                      name="filter_tenure"
                      value="1"
                    />
                    <label htmlFor="1">upto 1 year</label>
                  </div>
                  <div className={s.filter_tenure}>
                    <input
                      onChange={(e) => setFilterByYear(e.target.value)}
                      type="radio"
                      id="3"
                      name="filter_tenure"
                      value="3"
                    />
                    <label htmlFor="3">upto 3 years</label>
                  </div>
                  <div className={s.filter_tenure}>
                    <input
                      onChange={(e) => setFilterByYear(e.target.value)}
                      type="radio"
                      id="5"
                      name="filter_tenure"
                      value="5"
                    />
                    <label htmlFor="5">upto 5 years</label>
                  </div>
                  <div className={s.filter_tenure}>
                    <input
                      onChange={(e) => setFilterByYear(e.target.value)}
                      type="radio"
                      id="10"
                      name="filter_tenure"
                      value="10"
                    />
                    <label htmlFor="10">upto 10 years</label>
                  </div>
                  <div className={s.filter_tenure}>
                    <input
                      onChange={(e) => setFilterByYear(e.target.value)}
                      type="radio"
                      id="20"
                      name="filter_tenure"
                      value="20"
                    />
                    <label htmlFor="20">upto 20 years</label>
                  </div>
                  <div className={s.filter_tenure}>
                    <input
                      onChange={(e) => setFilterByYear(e.target.value)}
                      type="radio"
                      id="30"
                      name="filter_tenure"
                      value="30"
                    />
                    <label htmlFor="30">upto 30 years</label>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div className={s.apply_filter}>
          <h1
            onClick={() => {
              setFilteredGsecList(issueList);
              setShowFilter(false);
            }}
          >
            Clear Filters
          </h1>
          <div onClick={handleApplyFilter} className={s.apply_filte_btn}>
            Apply
          </div>
        </div>
      </div>
    </>
  );
};

const GsecDetails = () => {
  const { issues } = useLocation();
  const [allissueList, setallissueList] = useState([]);
  const [issueList, setissueList] = useState([]);
  const [minInvest, setminInvest] = useState("N/A");
  const [invrReturn, setinvrReturn] = useState("N/A");
  const [allCmsData, setallCmsData] = useState([]);
  const [inDeemandIssue, setinDeemandIssue] = useState([]);
  const [filteredGsecList, setFilteredGsecList] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [indexNum, setIndexNum] = useState(6);
  const [totalPage, setTotalPage] = useState([]);

  // console.log('-------------', filteredGsecList)

  const fetchAllIssues = async () => {
    try {
      let { data } = await getOpenIssuesList("all");

      if (data.statusCode === 200) {
        let gseclist = data.resultData.filter(function (v, i) {
          return (
            v.category.trim() === "GS" ||
            v.category.trim() === "TB" ||
            v.category.trim() === "SD"
          );
        });

        const pageLength = Array(Math.ceil(gseclist.length / 6))
          .fill()
          .map((_, index) => index + 1);

        setTotalPage(pageLength);

        const res = gseclist.map((item) =>
          item.gsecTenure.split(" ")[1]?.toLowerCase() === "years"
            ? { ...item, tenureYear: item.gsecTenure.split(" ")[0] }
            : item.gsecTenure.split(" ")[1]?.toLowerCase() === "days"
            ? {
                ...item,
                tenureYear: (item.gsecTenure.split(" ")[0] / 365).toFixed(2),
              }
            : item
        );

        setFilteredGsecList(res);

        setissueList([...res]);
        setallissueList([...gseclist]);

        let lowestAmt = gseclist?.sort((a, b) => {
          return a.minAmount - b.minAmount;
        });
        setminInvest(lowestAmt[0]?.minAmount);

        let highestYeld = gseclist?.sort((a, b) => {
          return b.indicativeYield - a.indicativeYield;
        });

        let lastElement = highestYeld.pop();
        setinvrReturn(
          highestYeld[0]?.indicativeYield +
            "% - " +
            lastElement?.indicativeYield +
            "%"
        );
      }
    } catch (error) {
      console.log("err", error);
    }
  };

  const searchIssue = (e) => {
    // console.log('searcj',e)
    var str = new RegExp(e, "gi");
    var issuelist = allissueList.filter(function (v, i) {
      return v.issuecode.match(str) || v.schname.match(str);
    });
    //console.log('issyed',issuelist)
    setFilteredGsecList(issuelist);
  };

  const extractColumn = async (arr, column) => {
    if (arr.length > 1) {
      try {
        let returnArr = arr.map((x) => x[column]);
        let { data: cmsdata } = await getIssuesByCodes(returnArr);

        if (cmsdata.statusCode === 200) {
          let indemand = [];
          setallCmsData(cmsdata.data);

          indemand = cmsdata.data.filter(function (v, i) {
            return v.in_demand === true;
          });
          setinDeemandIssue([...indemand]);
        }

        //setallIssueCode([...returnArr])
      } catch (error) {
        console.log("cms error:", error);
      }
    }
  };

  useEffect(() => {
    fetchAllIssues();
  }, []);

  useEffect(() => {
    extractColumn(allissueList, "issuecode");
  }, [allissueList]);

  return (
    <>
      <div className={s.detailshead}>
        <div className={s.topDetails}>
          <h4>Get safe and better returns than FD</h4>
          <h2>Government Securities</h2>
          <p>
            G-sec refer to government securities which are safest alternative to
            Bank FDs, guranteed by the Govt. of India
          </p>
        </div>
        <div className={s.detailsTags}>
          <span>
            <img src={icon1} />
            Govt. Guarantee
          </span>
          <span>
            <img src={icon2} />
            No TDS
          </span>
          <span>
            <img src={icon3} />
            Portfolio Diversification
          </span>
        </div>
      </div>

      <div className={s.investmentDetails}>
        <p>Minimum Investment</p>
        <h2>
          <i className="fa fa-rupee mrgR3"></i>
          {minInvest && minInvest}
        </h2>
        <div className={s.tenureReturns}>
          <div>
            <span>Tenure</span>
            <p>91 days - 40yrs</p>
          </div>
          <div>
            <span>Returns</span>
            <p>upto {invrReturn && invrReturn}</p>
          </div>
        </div>
      </div>

      <div className={s.investCmsDetails}>
        <h2>
          About Government Securities{" "}
          <span className={s.watchvideo}>
            <img src={icon4} /> Watch Video
          </span>
        </h2>
        <div className={s.govtBlock}>
          <h2>
            Understand Government <br />
            Securities (G-secs)
          </h2>
          <Link to={"/gsec-read-more"} className={s.readNow}>
            Read Now
          </Link>
        </div>
      </div>

      <div className={s.gsecIndeemandWrap}>
        <GsecIndemand allissues={allissueList} indemand={inDeemandIssue} />
      </div>

      <div className={s.othersInvestmnet}>
        <div className={s.searchSection}>
          <h2>Explore Government securities</h2>
          <input
            type="text"
            onChange={(e) => searchIssue(e.target.value)}
            placeholder="Search a G-sec by ISIN or issuer name"
          />
        </div>

        <SortOption
          issueList={issueList}
          filteredGsecList={filteredGsecList}
          setFilteredGsecList={setFilteredGsecList}
        />

        {filteredGsecList?.slice(indexNum - 6, pageNum * 6).map((e, litm) => {
          let cms = {};
          cms = allCmsData.filter(function (v, i) {
            return v.issuecode.trim() === e.issuecode;
          });
          cms = cms.pop();

          return (
            <Detailssingle
              key={e.issuecode}
              details={e}
              cmsData={cms ? cms : []}
            />
          );
          //return (<h1>{litm} s</h1>)
        })}

        {filteredGsecList.length < 1 && (
          <div className={s.noResult}>
            <h2>No Results found</h2>
          </div>
        )}
      </div>

      {filteredGsecList.length > 6 && (
        <div className={s.mobilePagination}>
          <GsecPagination
            totalPage={totalPage}
            pageNum={pageNum}
            setPageNum={setPageNum}
            setIndexNum={setIndexNum}
          />
        </div>
      )}
    </>
  );
};

export default GsecDetails;
