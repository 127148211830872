/* eslint-disable no-lone-blocks */
import { useState, useEffect } from 'react';
import ApplicationCard from '../ApplicationCard';
import s from './YourApplications.module.scss';
import LoaderImg from '../../assets/images/loader.svg';
import { getIssueDetailsFromCmsByCode, getAppliedIPOList, getpdfFromCmsByCode, validthirdpartycheck } from '../../services/issuesServices';
import { getDateTypeForIPOSchedule } from '../../helpers/getFormattedDateTime';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Noapplied from '../../assets/images/noaplied.svg';
import { loggedIn as userAction } from '../../store/action/loggedIn.action';
import { clevertap_key } from '../../vars/url';
import ClevertapReact from 'clevertap-react';

const YourApplication = () => {
	const history = useHistory();
	const [cards, setCards] = useState([]);
	const [loading, setLoading] = useState(true);
	const [cmsData, setcmsData] = useState({});
	const [alloppor, setalloppor] = useState(JSON.parse(localStorage.getItem('alloppor')) || []);
	const dispatch = useDispatch();
	const user = useSelector((state) => state.loggedIn.user);


	function formatDateString(datastring) {
		var mdate = datastring.split(' ')[0];
		var fnldt = mdate.split('-')[1] + '/' + mdate.split('-')[2] + '/' + mdate.split('-')[0] + ' 00:00:00 AM';
		return String(fnldt);
	}

	const fetchDetails = async () => {


		try {
			let newCards = [];
			let clientcode = (user.clientType == 'NONIIFLCLIENT') ? user.panNo : user.clientcode;

			let myarrcms = [];
			let cmsexist = [];
			let { data: details } = await getAppliedIPOList(clientcode, '');

			if (typeof details.resultData === 'string') {
				setLoading(false)
				return false;
			}

			if (details.statusCode == 200 && details.isSuccess == true) {
				let unique = [...new Set(details.resultData.map(item => item.iponame))]
				let appliedipo = details.resultData.filter(function (v, i) {
					return (
            v.instrumentType.trim() == "IPO" ||
            v.instrumentType.trim() == "SGB" ||
            v.instrumentType.trim() == "NCD" ||
            v.instrumentType.trim() == "GS" ||
            v.instrumentType.trim() == "SD" ||
            v.instrumentType.trim() == "TB"
          );
				})

				ClevertapReact.initialize(clevertap_key);
				// console.log('OU_Bid Review Page Viewed', localStorage.getItem('app_history'));

				const payload = {
					"Source": localStorage.getItem('app_history') === '/' ? "OneUp HomePage" :
						localStorage.getItem('app_history')?.split('/')[1] === 'ipo_details' ? "IPO DetailsPage" :
							localStorage.getItem('app_history')?.split('/')[1] === 'ncd_details' ? "NCD DetailsPage" :
								localStorage.getItem('app_history')?.split('/')[1] === 'application_process' ? "IPO Application Step-4" :
									localStorage.getItem('app_history')?.split('/')[1] === 'sgb_application_finish' ? "SGB Success" : 'NCD Application Step-4',


					"Product Type": localStorage.getItem('app_history')?.split('/')[1] === 'application_process' ? 'IPO' :
						localStorage.getItem('app_history')?.split('/')[1] === 'sgb_application_finish' ? "SGB" :
							localStorage.getItem('app_history')?.split('/')[1] === 'ncd-application-step-4' ? "NCD" :
								localStorage.getItem('app_history')?.split('/')[1] === 'ncd-application-step-3' ? "NCD" :
									'',

					"Issue Name": localStorage.getItem('app_history')?.split('/')[1] === 'application_process' ? appliedipo[0]?.schemeName :
						localStorage.getItem('app_history')?.split('/')[1] === 'sgb_application_finish' ? appliedipo[0]?.schemeName :
							localStorage.getItem('app_history')?.split('/')[1] === 'ncd-application-step-3' ? appliedipo[0]?.schemeName :
								localStorage.getItem('app_history')?.split('/')[1] === 'ncd-application-step-4' ? appliedipo[0]?.schemeName : '',

					"Bid Number": localStorage.getItem('app_history')?.split('/')[1] === 'application_process' ? appliedipo[0]?.applicationno :
						localStorage.getItem('app_history')?.split('/')[1] === 'sgb_application_finish' ? appliedipo[0]?.applicationno :
							localStorage.getItem('app_history')?.split('/')[1] === 'ncd-application-step-3' ? appliedipo[0]?.applicationno :
								localStorage.getItem('app_history')?.split('/')[1] === 'ncd-application-step-4' ? appliedipo[0]?.applicationno : '',

					"Investment Amount": localStorage.getItem('app_history')?.split('/')[1] === 'application_process' ? appliedipo[0]?.amountpaid :
						localStorage.getItem('app_history')?.split('/')[1] === 'sgb_application_finish' ? appliedipo[0]?.amountpaid :
							localStorage.getItem('app_history')?.split('/')[1] === 'ncd-application-step-3' ? appliedipo[0]?.amountpaid :
								localStorage.getItem('app_history')?.split('/')[1] === 'ncd-application-step-4' ? appliedipo[0]?.amountpaid : '',
				}


				// console.log(payload)
				ClevertapReact.event("OU_Bid Review Page Viewed", payload)

				let cmsarr = []
				try {
					for (let i = 0; i < unique.length; i++) {
						var cmp = await getIssueDetailsFromCmsByCode(unique[i]);
						cmsarr.push(cmp.data.result)
					}
				} catch (error) {

				}

				if (appliedipo.length < 1) {
					setLoading(false);
				}


				appliedipo.map((e) => {

					if (
						e.instrumentType.trim() === "IPO" ||
            e.instrumentType.trim() == "NCD" ||
            e.instrumentType.trim() == "GS" ||
            e.instrumentType.trim() == "SD" ||
            e.instrumentType.trim() == "TB"
					) {

						if (cmsarr) {
							cmsexist = cmsarr.filter(function (v, i) {
								return (v.issuecode === e.iponame);
							})
						}

						let cmsData = {};
						if (cmsexist) {
							cmsData = cmsexist[0];

						}
						else {
							cmsData = {};
						}

						var bidcats = []

						let confstatus = []
						confstatus[0] = { 'status': e.status, 'nextSteps': e.nextSteps }

						let dates = {
							offerStart: e?.offerStartDate != '' ? formatDateString(e?.offerStartDate) : '01/01/1990 00:00:00 AM',
							offerEnd: e?.offerEndDate != '' && formatDateString(e?.offerEndDate),
							allotmentFinalization: e?.allotmentFinalizationDate != '' && formatDateString(e?.allotmentFinalizationDate),
							refundInitiation: e?.refundInitiationDate != '' && formatDateString(e?.refundInitiationDate),
							dematTransfer: e?.dematTransferDate != '' && formatDateString(e?.dematTransferDate),
							listing: e?.listingDate != '' && formatDateString(e?.listingDate)
						};

						let dateTypes = getDateTypeForIPOSchedule(Object.values(dates));

						const bidPrice = [];

						for (let i = 0; i < e.bids.length; i++) {

							bidPrice.push(e.bids[i].bidPrice)
						}

						var catcount = 0
						var snglitm = alloppor.filter(function (v, i) {
							return (v.issuecode == e.iponame);
						})
						if (snglitm[0]) {
							catcount = (snglitm[0].categoryList).length
						}
						
						if(e.instrumentType.trim() == "NCD") {
							getpdfFromCmsByCode({
								"sampleRes1": {
									"applicationNo": e.srno,
									"issuecode": e.iponame
								},
								"sampleResponse2": {
									"resultData": {
										"panNo": clientcode
									}
								}
							}).then((pdffile) => {
								newCards.push({
									status:e?.status,
									img: cmsData?.logo,
									fullcmsdata: cmsData,
									srno: e?.srno,
									pdffile: pdffile?.data?.data?.file,
									issue_name: e?.schemeName,
									title: e.iponame,
									applicationNo: e.applicationno,
									closeDate: e.closeDate,
									clientCode: e.clientCode,
									openDate: e?.openDate,
									amountpaid: e.amountpaid,
									investment: `₹${e.amountpaid} for ${e.bids.length} Bids`,
									highestBid: `₹${Math.max(...bidPrice)} (Cut Off Price)`,
									warning: e.status,
									confstatus: confstatus,
									paymentMode: e.paymentMode,
									investmentAs: e.nsE_CodeDetails,
									bidcats: bidcats,
									catcount: catcount,
									schedule: [
										{
											status: dateTypes[0],
											name: 'Offer Start',
											date: String(dates.offerStart)
											//date:''
										},
										{
											status: dateTypes[1],
											name: 'Offer End',
											date: String(dates.offerEnd)
											//date:''
										},
										{
											status: dateTypes[2],
											name: 'Allotment Finalization',
											date: String(dates.allotmentFinalization)
											//date:''
										},
										{
											status: dateTypes[3],
											name: 'Refund Initiation',
											date: String(dates.refundInitiation)
											//date:''
										},
										{
											status: dateTypes[4],
											name: 'Demat Transfer',
											date: String(dates.dematTransfer)
											//date:''
										},
										{
											status: dateTypes[5],
											name: 'Listing',
											date: String(dates.listing)
											//date:''
										}
	
									],
									instrumentType: e.instrumentType,
									scheduleData: e,
									issuetype: e.instrumentType,
									srno: e.srno,
								});
								setCards([...newCards]);
								setLoading(false);
	
							});
						}else{
							newCards.push({
								status:e?.status,
								img: cmsData?.logo,
								fullcmsdata: cmsData,
								srno: e?.srno,
								//pdffile: pdffile?.data?.data?.file,
								issue_name: e?.schemeName,
								title: e.iponame,
								applicationNo: e.applicationno,
								closeDate: e.closeDate,
								clientCode: e.clientCode,
								openDate: e?.openDate,
								amountpaid: e.amountpaid,
								investment: `₹${e.amountpaid} for ${e.bids.length} Bids`,
								highestBid: `₹${Math.max(...bidPrice)} (Cut Off Price)`,
								warning: e.status,
								confstatus: confstatus,
								paymentMode: e.paymentMode,
								investmentAs: e.nsE_CodeDetails,
								bidcats: bidcats,
								catcount: catcount,
								schedule: [
									{
										status: dateTypes[0],
										name: 'Offer Start',
										date: String(dates.offerStart)
										//date:''
									},
									{
										status: dateTypes[1],
										name: 'Offer End',
										date: String(dates.offerEnd)
										//date:''
									},
									{
										status: dateTypes[2],
										name: 'Allotment Finalization',
										date: String(dates.allotmentFinalization)
										//date:''
									},
									{
										status: dateTypes[3],
										name: 'Refund Initiation',
										date: String(dates.refundInitiation)
										//date:''
									},
									{
										status: dateTypes[4],
										name: 'Demat Transfer',
										date: String(dates.dematTransfer)
										//date:''
									},
									{
										status: dateTypes[5],
										name: 'Listing',
										date: String(dates.listing)
										//date:''
									}

								],
								instrumentType: e.instrumentType,
								scheduleData: e,
								issuetype: e.instrumentType,
								srno: e.srno,
							});
							setCards([...newCards]);
							setLoading(false);
						}
						
					}
					else if (e.instrumentType.trim() === 'SGB') {
						newCards.push({
							img: cmsData?.logo,
							pdffile: '',
							schemeName:e?.schemeName,
							issue_name: 'Sovereign Gold Bond',
							title: 'Sovereign Gold Bond',
							applicationNo: e?.applicationno,
							openDate: e?.openDate,
							closeDate: e?.closeDate,
							amountpaid: e?.amountpaid,
							investment: e?.amountpaid,
							highestBid: e?.amountpaid,
							warning: e.status,
							confstatus: [{ status: e.status }],
							paymentMode: e.paymentMode,
							investmentAs: e.nsE_CodeDetails,
							bidcats: [],
							schedule: [],
							scheduleData: e,
							instrumentType: e.instrumentType,
							srno: e.srno,
							issuetype: e.instrumentType,
						});
						setCards([...newCards]);
						setLoading(false);
					}



				})



			}
			else {
				setLoading(false);
			}



		} catch (error) {
			console.log(error)
			alert(error)
			var serror = String(error)
			if (serror.includes("Request failed with status code 401")) {
				alert('Session expired, Please login again!')
				localStorage.removeItem('user');
				window.location.replace("/");
			}
			else {
				alert(error)
			}
		}

	};

	const fetchthirdpart = async () => {
		const urlSearchParams = new URLSearchParams(window.location.search)
		const urltoken = urlSearchParams.get('token');
		const requestercode = urlSearchParams.get('RequesterCode');
		let AppSource = urlSearchParams.get('AppSource');
		if (urltoken) {
			try {
				let data = await validthirdpartycheck(urltoken, requestercode);
				// console.log('tptdata', data)

				if (data.data.statusCode == 200 && data.data.isSuccess == true) {

					var token = data.data.resultData.token;
					let clientdetails = {
						"panNo": "",
						"firstName": "",
						"middleName": "",
						"surName": "",
						"mobileNo": "",
						"email": "",
						"clientcode": data.data.resultData.clientCode,
						"loginid": data.data.resultData.requesterCode,
						"dpType": "",
						"dpid": "",
						"beneficiaryID": "",
						"bankBranch": "",
						"bankName": "",
						"bankAccountNo": "",
						"investorStatus": "",
						"mkrid": "",
						"mkrDt": "",
						"dateOfbith": "",
						"address": "",
						"pincode": "",
						"secondHolderName": "",
						"secondHolderPan": "",
						"thirdHolderName": "",
						"thirdHolderPan": "",
						"issuecode": "",
						"leadid": "",
						"clientType": "IIFLCLIENT",
						"AppSource": AppSource,
					}
					localStorage.setItem('user', JSON.stringify({ ...clientdetails, token }));
					dispatch(userAction({ ...clientdetails, token }));
					window.location.replace("/");
					return false
				}
				else {
					alert('Invalid Session')
					setTimeout(() => {
						localStorage.removeItem('user');
						window.location.replace("/");
					}, 2000);
					alert('Invalid Session')
				}
			}
			catch (error) {
				alert(error);
			}
		}
	}

	useEffect(() => {
		fetchDetails();
		fetchthirdpart();
	}, []);

	useEffect(() => {
		window.history.pushState(null, null, window.location.pathname);
		window.addEventListener('popstate', onBackButtonEvent);
		return () => {
			window.removeEventListener('popstate', onBackButtonEvent);
		};
	}, []);

	const onBackButtonEvent = (e) => {
		e.preventDefault();
		history.push('/');
	};



	return (
		<div className={s.main}>
			<h1>Your Applications</h1>
			{loading == true &&
				<div className="loading_screen">
					<img src={LoaderImg} alt="loading..." />
				</div>
			}
			{!cards.length &&
				<>
					<div className={s.noapplieddata}>
						<img src={Noapplied} alt="no applied IPO" />
						<h2>You haven't applied in anywhere IPOs so far!</h2>
						<p>You haven't applied anywhere yet! Explore & invest in some of the great opportunities available right now before you miss out.</p>
						<a href="#" onClick={() => { history.push("/") }} className={s.explorebtn}>Explore IPOs now!</a>
					</div>
				</>
			}


			{cards.sort((a, b) => a.srno < b.srno ? 1 : -1).map((e, key) => {
				return <ApplicationCard
					key={key}
					details={e}
					setLoading={setLoading}
					alloppor={alloppor}
					keyvl={e.title}
					fetchDetails={fetchDetails}
					fetchthirdpart={fetchthirdpart}
				/>;
			})}
		</div>
	);
};

export default YourApplication;
