import React, { useState } from "react";
import SideBar from "../../../SideBar/SideBar";
import "./GsecReadMore.css";
import security from "../assets/security.svg";
import gsecwatch from "../assets/gsecwatch.svg";
import whygsec1 from "../assets/whygsec1.svg";
import whygsec2 from "../assets/whygsec2.svg";
import whygsec3 from "../assets/whygsec3.svg";
import type1 from "../assets/type1.svg";
import type2 from "../assets/type2.svg";
import type3 from "../assets/type3.svg";
import HowItWork from "../GsecDetailPage/HowItWork";
import dropDown from "../assets/dropDown.svg";
import mtype1 from "../assets/mtype1.svg";
import mtype2 from "../assets/mtype2.svg";
import mtype3 from "../assets/mtype3.svg";
import playicon from "../assets/playicon.svg";
import faq1 from "../assets/faq1.svg";
import faq2 from "../assets/faq2.svg";
import faq3 from "../assets/faq3.svg";
import faq0 from "../assets/faq0.png";
import faq_back from "../assets/faq_back.svg";
import { Link } from "react-router-dom";
import Group18219 from "../../../../assets/images/LandingPage/Group 18219.svg";
import IIFLSec from "../../../../assets/images/LandingPage/IIFL Sec.svg";
import { useHistory } from "react-router-dom";
import icon3 from "../assets/Group 48097754.svg";
import arrowup from "../assets/arrow-up.svg";

const GsecReadMore = () => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [selectedFaq, setSelectedFaq] = useState("");

  const faqData = [
    {
      id: 1,
      title: "What are Government Securities?",
      content:
        "Government Securities are like loans issued by the Indian government to fund development projects and infrastructure. It's a way for the government to raise money. People can buy these bonds or T-bills and get regular interest payments and their money back when the loan matures.",
    },
    {
      id: 2,
      title: "What types of Government Securities are there?",
      content:
        "There are two types: T-bills and bonds. T-bills are short-term loans issued by the government, usually for 91 days, 182 days, or 364 days. Bonds (G-Sec and SDL), on the other hand, are long-term loans with maturities ranging from 1 year to 40 years. SDLs or State Development Loans, are bonds which are issued by state governments.",
    },
    {
      id: 3,
      title: "How do T-bills work?",
      content:
        "T-bills are short-term debt instruments issued by the government. When they mature, you get the full face value of the bill. They don't pay regular interest like bonds, but the difference between the discount price and the face value is your return.",
    },
    {
      id: 4,
      title: "Why should I invest in Government Securities?",
      content:
        "Government Securities are considered safe investments. You can earn regular interest payments, and they can be easily bought and sold. You can also hold them in a Demat account and even use them as collateral to borrow money.",
    },
    {
      id: 5,
      title: "How are Government Securities issued?",
      content:
        "Government Securities are issued through auctions run by the Reserve Bank of India (RBI). The auctions happen on an electronic platform called E-Kuber. The minimum amount to participate in the auction is ₹10,000.",
    },
    {
      id: 6,
      title: "What is accrued interest?",
      content:
        "Accrued interest is the interest calculated for the period between the last coupon payment date and the trade settlement date. The buyer pays this accrued interest during the trade settlement.",
    },
    {
      id: 7,
      title: "Can I bid multiple times in the same auction?",
      content:
        "No, you can only submit a single bid in each specified auction. Once you apply for a particular tenor (duration), you cannot invest in the same tenor again.",
    },
    {
      id: 8,
      title: "What are State Development Loans (SDLs)?",
      content:
        "State Development Loans (SDLs) are bonds issued by state governments through similar auctions as the central government securities.",
    },
    {
      id: 9,
      title: "Can I use T-bills and SDLs as collateral?",
      content:
        "No, you can only use G-Secs (Government Securities) as collateral. T-bills and SDLs cannot be used as collateral.",
    },
    {
      id: 10,
      title: "Who can invest in Government Securities?",
      content:
        "Any individual, firm, company, institution, provident fund, trust, or any other entity specified by the RBI can participate in the scheme.",
    },
    {
      id: 11,
      title: "What are the taxes on Government Securities?",
      content:
        "For Bonds, the interest income is considered income from other sources and is taxed according to the income tax slab. If the bond price goes up, it's considered capital gains, with a flat rate of 10% for long-term gains held for more than 3 years, or 20% with indexation. Short-term gains are taxed according to the applicable slab rate. For T-bills, the difference between the discount and face value is considered short-term capital gain, taxed at the applicable slab rate. For G-Secs, gains held for more than 3 years are considered long-term gains, while gains ",
    },
  ];

  return (
    <div>
      <div className="mobile_top_haeder">
        <SideBar />
      </div>

      <div className="gsec_read_haeder d-md-block d-none">
        <div className="">
          <img
            onClick={() => history.push("/")}
            width={100}
            src={Group18219}
            alt="logo"
          />
          <span className="px-3">Powered by</span>
          <img src={IIFLSec} alt="logo" />
        </div>
      </div>

      <div className="gsec_read_wrapp">
        <div className="custom_container">
          <div className="top_section">
            <div className="shield_img">
              <img src={security} alt="security" />
            </div>
            <div className="top_content">
              <h1>Invest in Government Securities</h1>
              <p>
                G-sec refer to government securities which are safest
                alternative to bank FDs, which are guranteed by the govt. of
                India
              </p>
            </div>
            <div className="watch_gsec">
              <img src={gsecwatch} alt="gsecwatch" />
            </div>
            <div className="under_gsec_wrap">
              <span className="under_gsec">
                <img src={playicon} alt="playicon" />
                Understand G-sec
              </span>
            </div>
          </div>

          <div className="whatIsGsec">
            <h2>What are government securities?</h2>
            <ul>
              <li>
                Government bond also known as sovereign bond is a form of bond issued by a government to support government spending. It generally includes a commitment to pay periodic interest, called coupon payment, and to repay the face value on the maturity date.
              </li>
              <li>
                A Government Security (G-Sec) is a tradable instrument issued by the Central Government or the State Governments. It acknowledges the Government’s debt obligation. Such securities are short term (usually called treasury bills, with original maturities of less than one year) or long term (usually called Government bonds or dated securities with original maturity of one year or more). In India, the Central Government issues both, treasury bills and bonds or dated securities while the State Governments issue only bonds or dated securities, which are called the State Development Loans (SDLs). G-Secs carry practically no risk of default and, hence, are called risk-free gilt-edged instruments.
              </li>
              <li>
                Treasury bills or T-bills, which are money market instruments, are short term debt instruments issued by the Government of India and are presently issued in three tenors, namely, 91 day, 182 day and 364 day. Treasury bills are zero coupon securities and pay no interest. Instead, they are issued at a discount and redeemed at the face value at maturity. For example, a 91 day Treasury bill of ₹100/- (face value) may be issued at say ₹98.20, that is, at a discount of say, ₹1.80 and would be redeemed at the face value of ₹100/-.
              </li>
            </ul>
          </div>

          <div className="why-gsec">
            <h2>Why should one invest in G-Secs?</h2>
            <p>Holding of cash in excess of the day-to-day needs (idle funds) does not give any return. Investment in gold has attendant problems in regard to appraising its purity, valuation, warehousing and safe custody, etc. In comparison, investing in G-Secs has the following advantages:</p>
            <div className="why_gsec_box">
              <ul>
                <li>
                  Besides providing a return in the form of coupons (interest), G-Secs offer the maximum safety as they carry the Sovereign’s commitment for payment of interest and repayment of principal.
                </li>
                <li>
                  They can be held in book entry, i.e., dematerialized/ scripless form, thus, obviating the need for safekeeping. They can also be held in physical form.
                </li>
                <li>
                  G-Secs are available in a wide range of maturities from 91 days to as long as 40 years to suit the duration of varied liability structure of various institutions.
                </li>
                <li>
                  G-Secs can be sold easily in the secondary market to meet cash requirements.
                </li>
                <li>
                  G-Secs can also be used as collateral to borrow funds in the repo market.
                </li>
                <li>
                  Securities such as State Development Loans (SDLs) and Special Securities (Oil bonds, UDAY bonds etc) provide attractive yields.
                </li>
                <li>
                  The settlement system for trading in G-Secs, which is based on Delivery versus Payment (DvP), is a very simple, safe and efficient system of settlement. The DvP mechanism ensures transfer of securities by the seller of securities simultaneously with transfer of funds from the buyer of the securities, thereby mitigating the settlement risk.
                </li>
                <li>
                  G-Sec prices are readily available due to a liquid and active secondary market and a transparent price dissemination mechanism.
                </li>
                <li>
                  Besides banks, insurance companies and other large investors, smaller investors like Co-operative banks, Regional Rural Banks, Provident Funds are also required to statutory hold G-Secs as indicated below:
                </li>
              </ul>
            </div>
          </div>

          <div className="whatIsGsec keyandadv">
          <div className="trasp-border"></div>
            <div className="row justify-content-between">
              <div className="col-md-5">
                <p>Key Features of G-sec, SDL and T-bill</p>
                <ul>
                  <li>
                    Government bonds and Treasury bills are issued by the Government of India
                  </li>
                  <li>
                    State Development Loans are issued by the State Government
                  </li>
                  <li>
                    Government bonds, State Development Loans and Treasury bills are Issued through Reserve Bank of India
                  </li>
                  <li>
                    Government bonds, State Development Loans have longer tenor maturities extending above 30 years
                  </li>
                  <li>
                    Treasury bills have maturity of 91 days, 182 days and 365 days
                  </li>
                  <li>
                    Government bonds and State Development Loans pay interest every six months
                  </li>
                  <li className="treasury">
                    Treasury bills are zero coupon bonds. They are issued by discount and redeemed at face value
                  </li>
                </ul>
              </div>
              <div className="col-md-5">
                <p>Advantages of investing in G-sec, SDL and T-bill</p>
                <ul>
                  <li>
                    Safety: Being Sovereign security, no default risk
                  </li>
                  <li>
                    Ease of Exit: Investor can sell the government bonds in the secondary market
                  </li>
                  <li>
                    Fixed income investment available across the maturities
                  </li>
                  <li>
                    No TDS applicable on interest
                  </li>
                  <li>
                    Can be held in existing demat account
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="type_gsec">
            <h2>Types of government securities</h2>
            <div className="row g-5">
              <div className="col-md-4">
                <div className="type_gsec_card">
                  <img src={type2} alt="type2" />
                  <h3>T-bills ( Treasuary Bills)</h3>
                  <ul>
                    <li>
                      Treasury bills or T-bills are issued in three tenors 91
                      day, 182 day and 364 days
                    </li>
                    <li>
                      T-bills are issued at a discount and redeemed at the face
                      value at maturity.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4">
                <div className="type_gsec_card">
                  <img src={type3} alt="type3" />
                  <h3>Govt. Bonds</h3>
                  <ul>
                    <li>
                      These securities carry a fixed or floating coupon
                      (interest rate) which is paid on the face value, on
                      half-yearly basis.
                    </li>
                    <li>
                      Generally, the tenor of dated securities ranges from 1
                      years to 40 years.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4">
                <div className="type_gsec_card">
                  <img src={type1} alt="type1" />
                  <h3>SDL (State Development Loans)</h3>
                  <ul>
                    <li>
                      State development loans or SDL are issued by the
                      respective state government.
                    </li>
                    <li>
                      Generally, the tenor of dated securities ranges from 1
                      years to 40 years.
                    </li>
                    <li>
                      They are similar to govt bonds in nature of purchase,
                      maturity and interest frequency
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="type_gsec_mobile">
            <h2>Types of government securities?</h2>

            <div className="row g-5">
              <div className="col-12">
                <div className="type_gsec_mobile_card">
                  <img src={mtype2} alt="mtype2" />
                  <div>
                    <h3>T-bills</h3>
                    <ul>
                      <li>
                        Treasury bills or T-bills are issued in three tenors 91
                        day, 182 day and 364 days
                      </li>
                      <li>
                        T-bills are issued at a discount and redeemed at the
                        face value at maturity.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="type_gsec_mobile_card">
                  <img src={mtype1} alt="mtype1" />
                  <div>
                    <h3>Govt. bonds</h3>
                    <ul>
                      <li>
                        These securities carry a fixed or floating coupon
                        (interest rate) which is paid on the face value, on
                        half-yearly basis.
                      </li>
                      <li>
                        Generally, the tenor of dated securities ranges from 1
                        years to 40 years.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="type_gsec_mobile_card">
                  <img src={mtype3} alt="mtype3" />
                  <div>
                    <h3>SDL - State Development Loans</h3>
                    <ul>
                      <li>
                        State development loans or SDL are issued by the
                        respective state government.
                      </li>
                      <li>
                        Generally, the tenor of dated securities ranges from 1
                        years to 40 years.
                      </li>
                      <li>
                        They are similar to govt bonds in nature of purchase,
                        maturity and interest frequency
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="gsec_button_wrap">
            <Link to="/gsec_details">
              <span className="gsec_button d-none d-md-flex">
                Explore government securities
              </span>
              <span className="gsec_button d-md-none">Explore G-secs</span>
            </Link>
          </div>
        </div>

        <HowItWork
          title="How to invest in government securities"
          isDate={false}
        />

        <div className="custom_container">
          <div className="gsec_button_wrap d-none d-md-flex">
            <Link to="/gsec_details">
              <span className="gsec_button">Explore government securities</span>
            </Link>
          </div>

          <div className="faq_section">
            <h2 className="d-none d-md-block">Need help with</h2>
            <h2 className="d-md-none">Need help?</h2>

            <div className="row">
              <div className="col-md-6">
                <div className="row g-4">
                  {faqData.slice(0, 6).map((item) => (
                    <div key={item.id} className="col-md-12">
                      <div className="faq_card">
                        <div
                          onClick={() => {
                            setShow(!show);
                            if (selectedFaq === item.id) {
                              setSelectedFaq("");
                            } else {
                              setSelectedFaq(item.id);
                            }
                          }}
                          className="faq_card_title"
                        >
                          <p>{item.title}</p>
                          <img
                            src={selectedFaq === item.id ? arrowup : dropDown}
                            alt="img"
                          />
                        </div>
                        {selectedFaq === item.id && (
                          <div className="faq_card_content">{item.content}</div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-md-6">
                <div className="row g-4">
                  {faqData.slice(6, 11).map((item) => (
                    <div key={item.id} className="col-md-12">
                      <div className="faq_card">
                        <div
                          onClick={() => {
                            setShow(!show);
                            if (selectedFaq === item.id) {
                              setSelectedFaq("");
                            } else {
                              setSelectedFaq(item.id);
                            }
                          }}
                          className="faq_card_title"
                        >
                          <p>{item.title}</p>
                          <img
                            src={selectedFaq === item.id ? arrowup : dropDown}
                            alt="img"
                          />
                        </div>
                        {selectedFaq === item.id && (
                          <div className="faq_card_content">{item.content}</div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="faq_mobile">
            <h2>Need help?</h2>
            <div className="row">
              <div className="col-12">
                <a
                  target="_blank"
                  href="https://help.indiainfoline.com/portal/en/kb/iifl-help/ipo-ofs"
                >
                  <div className="faq_0">
                    <img src={faq0} alt="faq0" />
                    <p>What are Government Securities?</p>
                  </div>
                </a>
              </div>
              <div className="col-6 jkjhkjhk">
                <a
                  target="_blank"
                  href="https://help.indiainfoline.com/portal/en/kb/iifl-help/ipo-ofs"
                >
                  <div className="faq_1">
                    <img src={faq_back} alt="faq_back" />
                    <img src={faq2} alt="faq1" />
                    <p>Who can apply?</p>
                  </div>
                </a>
              </div>
              <div className="col-6 fgsdfsdf">
                <a
                  target="_blank"
                  href="https://help.indiainfoline.com/portal/en/kb/iifl-help/ipo-ofs"
                >
                  <div className="faq_2">
                    <img src={faq_back} alt="faq_back" />
                    <img src={faq3} alt="faq3" />
                    <p>How G-secs price are decided?</p>
                  </div>
                </a>
              </div>
              <div className="col-12">
                <a
                  target="_blank"
                  href="https://help.indiainfoline.com/portal/en/kb/iifl-help/ipo-ofs"
                >
                  <div className="faq_3">
                    <img src={faq1} alt="faq1" />
                    <p>Can you sell G-Secs in secondary market?</p>
                  </div>
                </a>
              </div>
            </div>

            <div className="viewallfaq">
              <a
                target="_blank"
                href="https://help.indiainfoline.com/portal/en/kb/iifl-help/ipo-ofs"
              >
                <p>View all FAQs</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GsecReadMore;
