import React from 'react';
import moment from 'moment';

const GsecBasicDetail = ({ gsecDetail }) => {
    return (
        <div className='gsec_detail_one'>
            <div className='row g-4'>
                <div className='col-6 col-md-4'>
                    <p>Face Value</p>
                    <h2><i className="fa fa-rupee mrgR3"></i>{gsecDetail?.faceValue}/Unit</h2>
                </div>
                <div className='col-6 col-md-4'>
                    <p>Market Value</p>
                    <h2><i className="fa fa-rupee mrgR3"></i>{gsecDetail?.markupFaceValue}/Unit</h2>
                </div>
                <div className='col-6 col-md-4'>
                    <p>Allotment</p>
                    <h2>{moment(gsecDetail?.listingDate).format("MMM D, YYYY")}</h2>
                </div>
                <div className='col-6 col-md-4'>
                    <p>Interest frequency</p>
                    <h2>{gsecDetail?.category === 'GS' || gsecDetail?.category === 'SD' ? 'Every 6 months' : 'At maturity'}</h2>
                </div>
                <div className='col-6 col-md-4'>
                    <p>Tenure</p>
                    <h2>{gsecDetail?.gsecTenure}</h2>
                </div>
            </div>
        </div>
    )
};

export default GsecBasicDetail;
