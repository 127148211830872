import React from "react";
import { useEffect } from "react";
import qtyDec from "../assets/qtyDec.svg";
import qtyInc from "../assets/qtyInc.svg";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import qty_btn_1 from "../assets/qty_btn_1.svg";
import qty_btn_2 from "../assets/qty_btn_2.svg";

const InvestNowCard = ({
  setInvtQty,
  invtQty,
  setShowPaymentDrawer,
  gsecDetail,
  toggleLogin,
  setShowDematAccModal,
}) => {
  const user = useSelector((state) => state.loggedIn);
  const params = useParams();

  useEffect(() => {
    if (gsecDetail.lotsize) {
      setInvtQty(gsecDetail?.lotsize);
    }
  }, [gsecDetail?.lotsize, params.issueCode]);

  const handleInvestNow = () => {
    handleAmountRoundOff();
    if (user?.loggedIn) {
      if (user?.user.clientType === "NONIIFLCLIENT") {
        setShowDematAccModal(true);
      } else {
        setShowPaymentDrawer(true);
      }
    } else {
      toggleLogin();
    }
  };

  // HANDLE AMOUNT ROUND OFF IN LOWEST MULTIPLE OF LOTSIZE
  const handleAmountRoundOff = () => {
    if (invtQty < gsecDetail?.lotsize) {
      setInvtQty(gsecDetail?.lotsize);
    } else {
      setInvtQty(
        JSON.stringify(invtQty / gsecDetail?.lotsize).split(".")[0] *
        gsecDetail?.lotsize
      );
    }
    if (invtQty > gsecDetail?.maxAmount / gsecDetail?.faceValue) {
      setInvtQty(gsecDetail?.maxAmount / gsecDetail?.faceValue);
    }
  };

  return (
    <>
      <div className="invset_now_card invest_big_card">
        <div className="invset_qty">
          <h2>Enter quantity</h2>
          <span>
            <i className="fa fa-rupee mrgR3"></i>
            {gsecDetail?.faceValue * gsecDetail?.lotsize} /{" "}
            {gsecDetail?.lotsize} bonds
          </span>
        </div>

        <div className="input_qty">
          <div>
            <img
              onClick={() =>
                invtQty > gsecDetail?.lotsize
                  ? setInvtQty(invtQty * 1 - gsecDetail?.lotsize)
                  : null
              }
              src={invtQty > gsecDetail?.lotsize ? qty_btn_2 : qtyDec}
              alt="qtyDec"
            />
            <input
              value={invtQty}
              onChange={(e) => {
                const newValue = e.target.value;
                if (newValue.length <= 6) {
                  setInvtQty(newValue);
                }
              }}
              onBlur={handleAmountRoundOff}
              type="number"
            />
            <img
              onClick={() =>
                invtQty < gsecDetail?.maxAmount / gsecDetail?.faceValue
                  ? setInvtQty(invtQty * 1 + gsecDetail?.lotsize)
                  : null
              }
              src={
                invtQty === gsecDetail?.maxAmount / gsecDetail?.faceValue
                  ? qty_btn_1
                  : qtyInc
              }
              alt="qtyInc"
            />
          </div>
          <span>Quantity in multiple of {gsecDetail?.lotsize}s</span>
        </div>

        <div className="row g-5 gsec_card_inner_info">
          <div className="col-md-8">
            <div className="invt_detls">
              <h2>Total Investment Amount</h2>
              <span>(Face Value * Quantity)</span>
            </div>
          </div>
          <div className="col-md-4">
            <div className="invt_amt">
              <h2>
                <i className="fa fa-rupee mrgR3"></i>
                {(gsecDetail?.faceValue * invtQty).toLocaleString("en-IN")}
              </h2>
            </div>
          </div>
          {gsecDetail.category !== "TB" && (
            <>
              <div className="col-md-8">
                <div className="invt_detls">
                  <h2>Total Payable Amount</h2>
                  <span> (Markup Value * Quantity)</span>
                </div>
              </div>
              <div className="col-md-4">
                <div className="invt_amt">
                  <h2>
                    <i className="fa fa-rupee mrgR3"></i>
                    {(gsecDetail?.markupFaceValue * invtQty).toLocaleString(
                      "en-IN"
                    )}
                  </h2>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="invt_total">
          <h2>Total payable amount</h2>
          <h1>
            <i className="fa fa-rupee mrgR3"></i>
            {gsecDetail.category === "TB"
              ? (gsecDetail?.faceValue * invtQty).toLocaleString("en-IN")
              : (gsecDetail?.markupFaceValue * invtQty).toLocaleString("en-IN")}
          </h1>
          <div onClick={handleInvestNow} className="inst_now_btn">
            <p>Invest Now</p>
          </div>
        </div>
      </div>

      <div className="invset_now_card invest_big_card_mobile">
        <div className="invest_now_heading">
          <div className="invset_qty">
            <h2>Enter quantity</h2>
            <span>
              <i className="fa fa-rupee mrgR3"></i>
              {gsecDetail?.faceValue * gsecDetail?.lotsize} /
              {gsecDetail?.lotsize} bonds
            </span>
          </div>

          <div className="input_qty">
            <div>
              <img
                onClick={() =>
                  invtQty > gsecDetail?.lotsize
                    ? setInvtQty(invtQty - gsecDetail?.lotsize)
                    : null
                }
                src={invtQty > gsecDetail?.lotsize ? qty_btn_2 : qtyDec}
                alt="qtyDec"
              />
              <input
                value={invtQty}
                onChange={(e) => {
                  const newValue = e.target.value;
                  if (newValue.length <= 6) {
                    setInvtQty(newValue);
                  }
                }}
                onBlur={handleAmountRoundOff}
                type="number"
              />
              <img
                onClick={() =>
                  invtQty < gsecDetail?.maxAmount / gsecDetail?.faceValue
                    ? setInvtQty(invtQty * 1 + gsecDetail?.lotsize)
                    : null
                }
                src={
                  invtQty === gsecDetail?.maxAmount / gsecDetail?.faceValue
                    ? qty_btn_1
                    : qtyInc
                }
                alt="qtyInc"
              />
            </div>
            <span>Quantity in multiple of {gsecDetail?.lotsize}s</span>
          </div>
        </div>

        <div className="invt_total">
          <div>
            <h1>
              <i className="fa fa-rupee mrgR3"></i>
              {gsecDetail.category === "TB"
                ? (gsecDetail?.faceValue * invtQty).toLocaleString("en-IN")
                : (gsecDetail?.markupFaceValue * invtQty).toLocaleString(
                  "en-IN"
                )}
            </h1>
            {/* <span>View Breakup</span> */}
          </div>
          <div onClick={handleInvestNow} className="inst_now_btn">
            <p>Invest Now</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvestNowCard;
