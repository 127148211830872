import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import minusicon from "../../assets/images/NCDDetail/minusicon.svg";
import minus_active from "../../assets/images/NCDDetail/minus_active.svg";
import plusicon from "../../assets/images/NCDDetail/plusicon.svg";
import useWindowDimensions from '../../hooks/screenWidth';
import { useDispatch } from 'react-redux';
import { actionSetCheckValue, actionUpdateQty, actionUncheckSeries } from '../../store/ncdseries/ncdAction';


const NcdSeriesCard = ({ e, i, activeMonth, ncddetails, setShowPopup }) => {
    var rate = parseFloat(e.rate).toFixed(2);
    const { width } = useWindowDimensions();
    const [checked, setChecked] = useState(e.check);
    const [quantity, setQuantity] = useState(e.minQty);
    const dispatch = useDispatch();


    useEffect(() => {
        setChecked(e.check)
    }, [e.check]);

    useEffect(() => {
        setQuantity(e.minQty)
    }, [e.minQty, activeMonth]);


    return (
        <div key={i} className="ncd_series_card my-5">
            <div className="row g-0 align-items-center">
                <div className='col-md-7'>
                    <div className='row g-0 justify-content-between ncd_series_card_col align-items-end'>
                        <div className='col-6'>
                            <div className="series_detail d-flex align-items-md-start align-items-center">
                                <div className="duration_ncd_page">
                                    <div className='duration_ncd_page_wrapper'>
                                        <label>
                                            <input
                                                type="checkbox"
                                                checked={checked}
                                                onChange={(a) => {
                                                    setChecked(a.target.checked);
                                                    dispatch(actionSetCheckValue({ qtys: e.minQty, check: a.target.checked, seriesNumber: e.seriesNumber }));
                                                }}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className='detail__name_custom'>
                                    <p>Series {e.seriesNumber}</p>
                                    <span className='d-md-block d-none'>Interest payout <strong>{e.frequency[0] + e.frequency.substring(1).toLowerCase()}</strong></span>
                                    <div className='d-md-none d-block'>
                                        {ncddetails?.issuecode === 'IMC01' ?
                                            <div className="showpopup_box">
                                                <span onClick={() => setShowPopup(true)}>Tenure Details</span>
                                            </div>
                                            :
                                            <span>Tenure <strong>{e.tenure.toLowerCase()}</strong></span>
                                        }
                                         <div className="series_perce_detail">
                                            <div className='title_name'>Coupon Rate</div>
                                            {e.couponYield && e.couponYield.replace('%', '') * 1 !== 0 ? <span>{e?.couponYield} <small style={{ color: '#000000' }}>p.a</small></span> : <strong>NA</strong>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-5 detail__name_custom'>
                            <div className="series_detail d-md-block d-none">
                                <div className="series_perce_detail">Coupon Rate:  {e.couponYield && e.couponYield.replace('%', '') * 1 !== 0 ? <span>{e?.couponYield} <small style={{ color: '#000000' }}>p.a</small></span> : <strong>NA</strong>}</div>
                                <div className="series_perce_detail" style={{ padding: '5px 0' }}>Effective Yield:  {e.effectiveYield && e.effectiveYield.replace('%', '') * 1 !== 0 ? <span>{e?.effectiveYield} <small style={{ color: '#000000' }}>p.a</small></span> : <strong>NA</strong>}</div>
                                {ncddetails?.issuecode === 'IMC01' ?
                                    <div className="showpopup_box">
                                        <span onClick={() => setShowPopup(true)}>Tenure Details</span>
                                    </div>
                                    :
                                    <span>Tenure <strong>{e.tenure.toLowerCase()}</strong></span>
                                }
                            </div>
                            <div className='d-md-none d-flex flex-column'>
                                <span className='pb-4'>Interest payout</span>
                                <strong>{e.frequency[0] + e.frequency.substring(1).toLowerCase()}</strong>
                            </div>
                            <div className="d-md-none series_perce_detail">
                                <div className='title_name'>Effective Yield</div>
                                {e.effectiveYield && e.effectiveYield.replace('%', '') * 1 !== 0 ? <span>{e?.effectiveYield} <small style={{ color: '#000000' }}>p.a</small></span> : <strong>NA</strong>}
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ display: e.check ? '' : width < 768 ? 'none' : '' }} className='col-md-5 mobile_sec_border'>
                    <div className='row align-items-center justify-content-center border1-des'>
                        <div className='col-7 text-center'>
                            <div className="series_detail  d-flex flex-column align-items-center">
                                <div className='jjhkjh d-none'></div>
                                <div>Enter quantity</div>
                                <div className="enter_invest_detail">
                                    <img onClick={() => {
                                        if (e.check && quantity >= 1) {
                                            setQuantity(quantity - 1);
                                            dispatch(actionUpdateQty({ qty: quantity - 1, seriesNo: e.seriesNumber }));
                                        }
                                    }} src={!quantity || quantity === 0 ? minusicon : minus_active} alt="minusicon" />
                                    <input
                                        onChange={(a) => {
                                            if (a.target.value.length < 10) {
                                                setQuantity(parseInt(a.target.value));
                                                dispatch(actionUpdateQty({ qty: parseInt(a.target.value), seriesNo: e.seriesNumber }));
                                            }
                                        }}
                                        value={isNaN(quantity) ? '' : quantity}
                                        type="number"
                                        className='d-md-block d-none'
                                    />
                                    <input
                                        onChange={(a) => {
                                            if (a.target.value.length < 10) {
                                                setQuantity(parseInt(a.target.value));
                                                dispatch(actionUpdateQty({ qty: parseInt(a.target.value), seriesNo: e.seriesNumber, isMobile: true }));
                                            }
                                        }}
                                        onBlur={(a) => {
                                            dispatch(actionUncheckSeries({ quan: a.target.value, seriesNum: e.seriesNumber }))
                                        }}
                                        value={isNaN(quantity) ? '' : quantity}
                                        type="number"
                                        className='d-md-none'
                                    />
                                    <img onClick={() => {
                                        if (isNaN(quantity) || quantity < 999999999) {
                                            setQuantity(isNaN(quantity) ? 1 : quantity + 1);
                                            dispatch(actionUpdateQty({ qty: quantity + 1, seriesNo: e.seriesNumber }));
                                        }
                                    }} src={plusicon} alt="plusicon" />
                                </div>
                                <div><span className="text-muted px-1 ">Investment Amt</span> <span className="series_amount"><br /> <i class="fa fa-rupee mrgR3"></i>{(e.minAmt).toLocaleString()}</span></div>
                            </div>
                        </div>
                        {/* <div className='col-4'>
                            <div className="series_detail_amount_mobile">
                                {ncddetails?.issuecode === 'KLMVIII' ? '' :
                                    <>
                                        <p>Maturity Amt</p>
                                        {e.frequency === 'ANNUALLY' &&
                                            <h2>
                                                <i class="fa fa-rupee mrgR3"></i>{
                                                    quantity ?
                                                        ((parseFloat(rate).toFixed(0) * quantity) + (rate * (parseFloat(e.couponYield) / 100) * (parseFloat(e.tenure.replace("MONTHS", "")) / 12)).toFixed(2) * quantity).toLocaleString()
                                                        : 0
                                                }
                                            </h2>
                                        }
                                        {e.frequency === 'MONTHLY' &&
                                            <h2>
                                                <i class="fa fa-rupee mrgR3"></i>{
                                                    quantity ?
                                                        (parseFloat(rate).toFixed(0) * quantity + (rate * (parseFloat(e.couponYield) / 100) * (parseFloat(e.tenure.replace("MONTHS", "")) / 12)).toFixed(2) * quantity).toLocaleString()
                                                        : 0}
                                            </h2>
                                        }
                                        {e.frequency === 'CUMULATIVE' &&
                                            <h2>
                                                <i class="fa fa-rupee mrgR3"></i>{
                                                    quantity ?
                                                        (rate * (Math.pow((1 + parseFloat(e.effectiveYield.replace("%", "")) / 100), (parseFloat(e.tenure.replace("MONTHS", "")) / 12))).toFixed(2) * quantity).toLocaleString()
                                                        : 0}
                                            </h2>
                                        }
                                    </>
                                }
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default NcdSeriesCard;
