import * as npsType from "./npsType";

const INITIAL_STATE = {
    npsData: {},
    npsPaymentMethod: {
        netbanking: false,
        card: false,
        upi: false
    }
};


const npsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case npsType.NPS_DATA:
            return {
                ...state,
                npsData: action.payload,
            };

        case npsType.NPS_PAYMENT_METHOD:
            return {
                ...state,
                npsPaymentMethod: action.payload,
            };

        default:
            return state;
    };
};

export default npsReducer;