import "./ncd-application-03.css";
import { useEffect, useState } from "react";
import {
  getBankList,
  getBankLocationList,
  addncd,
  upiValidate,
  zohoCreateToken,
  zohoCreateLead,
} from "../../services/issuesServices";
import { ncdDateFormatter } from "../../helpers/utils";
import { CMS_URL, clevertap_key } from "../../vars/url";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import NoImg from "../../assets/images/noimg.jpg";
import { Redirect, useHistory } from "react-router-dom";
import LoaderImg from "../../assets/images/loader.svg";
import ClevertapReact from "clevertap-react";
import { applicationData as setApplicationData } from "../../store/action/applicationData";
import SweetAlert from "react-bootstrap-sweetalert";
import { addAndCheckNonIiflJourneyClientDetail } from "../../services/userServices";
import Group18219 from "../../assets/images/LandingPage/Group 18219.svg";
import IIFLSec from "../../assets/images/LandingPage/IIFL Sec.svg";
import BiddingSuccess from "./BiddingSuccess";
import AsbaPaymentModal from "./AsbaPaymentModal";
import SideBar from "../SideBar/SideBar";
import downarrow from "../../assets/images/NCDDetail/down-arrow.png";
import { actionOthersDetail } from "../../store/ncdseries/ncdAction";
import dis_close from "../../assets/images/NCDDetail/dis_close.svg";
import { dataEncrypt } from "../../helpers/decodeReferralCode";

const Ncdapplication3 = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const applicationData = useSelector((state) => state.applicationData);
  const user = useSelector((state) => state.loggedIn.user);
  const [banklist, setbanklist] = useState([]);
  const [banklocationlist, setbanklocationlist] = useState([]);
  const [noofshares, setnoofshares] = useState([]);
  const [bidPrice, setbidPrice] = useState([]);
  const [cutoff, setcutoff] = useState([]);
  const [chqAmount, setchqAmount] = useState(0);
  const [bankname, setbankname] = useState("");
  const [accountno, setaccountno] = useState(user?.bankAccountNo || "");
  const [bankbranch, setbankbranch] = useState(user?.bankBranch || "");
  const [paymenttype, setpaymenttype] = useState("UPI");
  const [upiname, setupiname] = useState(user?.upiName || "");
  const [upihandle, setupihandle] = useState(user?.upiHandle || "");
  const [upiuser, setupiuser] = useState({ statusCode: 100 });
  const [alert, setalert] = useState("");
  const [loading, setLoading] = useState(false);
  const [counter, setCounter] = useState("");
  const [refercode, setrefercode] = useState(
    localStorage?.getItem("ReferralCode")
      ? localStorage?.getItem("ReferralCode")
      : ""
  );
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [abaConfirmationModal, setAsbaConfirmationModal] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  const upivaliditycheck = async () => {
    if (user?.upiName) {
      let { data } = await upiValidate(user?.upiName + user?.upiHandle);
      setupiuser(data);
    }
  };

  const fetchbanklist = async () => {
    let { data } = await getBankList(applicationData.ncdfulldetails?.issuecode);

    // if (data.statusCode === 200 && user?.clientType === 'IIFLCLIENT') {
    // 	const bankData = data?.resultData?.filter((item) => item.bankFName === user?.bankName);
    // 	setbankname(bankData[0].rbi_codes)

    // 	let datas = await getBankLocationList(
    // 		applicationData.ncdfulldetails?.issuecode,
    // 		bankData[0].rbi_codes
    // 	);

    // 	setbanklocationlist([...datas?.data.resultData]);
    // };

    setInterval(() => {
      setCounter(
        ncdDateFormatter(
          new Date(applicationData?.ncdfulldetails?.enddate).getTime()
        )
      );
    }, 1000);
    setbanklist(data.resultData);

    let shares = [];
    let bids = [];
    let cutoff = [];
    let totamnt = 0;
    if (applicationData?.ncdhistory) {
      applicationData?.ncdhistory?.bids.forEach((e) => {
        shares.push(e.noOfShares);
        bids.push(e.bidPrice);
        cutoff.push("Y");
      });

      setpaymenttype(applicationData?.ncdhistory?.ipobankname);
      if (applicationData?.ncdhistory?.ipobankname === "ASBA") {
        setbankname(applicationData?.ncdhistory?.asbaBankCode);
        setaccountno(applicationData?.ncdhistory?.accountNumber);
        fetchlocation(applicationData?.ncdhistory?.asbaBankCode);
        setbankbranch(applicationData?.ncdhistory?.bankLocation);
      }
    } else {
      if (user?.bankName) {
        // fetchlocation(user?.bankName);
      }

      ClevertapReact.initialize(clevertap_key);

      function getSeriesNamect(array, keysearch) {
        var fianalArr = [];
        array?.forEach((element) => {
          fianalArr.push(element[keysearch]);
        });
        return fianalArr;
      }

      var ClevertapPayload = {
        Source: "OneUp HomePage",
        Step: "3",
        "Product Type": "NCD",
        "Issue Name": applicationData.ncdfulldetails?.schemename,
        "Bid Number": applicationData?.series?.filter(
          (item) => item.check === true
        )?.length,
        "Investment Amount": applicationData.mainslidervalue,
        "Price Type": "",
        "Applying for": applicationData.selfoth,
        "Investor category": applicationData.ncdcat,
        "payment type": paymenttype,
        "Failure Message": "",
        "Application number": "",
        Amount: "",
        Series: applicationData?.series
          ?.filter((item) => item.check === true)
          .map((item) => item.seriesNumber)
          ?.join(","),
      };
      ClevertapReact.event("OU_Application Step", ClevertapPayload);

      applicationData.series?.forEach((e) => {
        if (e.minQty) {
          shares.push(e.minQty);
          bids.push(parseInt(e.minAmt / e.minQty));
        } else {
          shares.push(0);
          bids.push(0);
        }
        cutoff.push("Y");
        totamnt = parseFloat(totamnt) + parseFloat(e.minAmt);
      });
    }

    setnoofshares([...shares]);
    setbidPrice([...bids]);
    setcutoff([...cutoff]);
    setchqAmount(totamnt);
    if (totamnt > 500000) {
      setpaymenttype("ASBA");
    }
  };

  const fetchlocation = async (bankcode) => {
    let { data } = await getBankLocationList(
      applicationData.ncdfulldetails?.issuecode,
      bankcode
    );
    setbanklocationlist([...data.resultData]);
  };

  /// PLACE NCD FINAL STEP ////
  const placencd = async (isMobile) => {
    dispatch(
      setApplicationData({
        ...applicationData,
        paymenttype: paymenttype,
      })
    );

    if (paymenttype === "UPI") {
      setIsVerifying(true);

      try {
        let { data } = await upiValidate(upiname + upihandle);
        setIsVerifying(false);
        if (data?.resultData?.success) {
          setupiuser(data);
        } else {
          setalert("Invalid UPI id");
          return false;
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      if (bankname === "") {
        setalert("Please select bank");
        return false;
      }

      if (bankbranch === "") {
        setalert("Bank branch is required");
        return false;
      }

      if (accountno === 0 || accountno.length < 10) {
        setalert("Invalid account number");
        return false;
      }
    }
    setLoading(true);
    try {
      if (applicationData?.ncdhistory) {
        var catt = applicationData.ncdfulldetails?.categoryList.filter(
          function (v, i) {
            return (
              v.categoryName === applicationData.ncdhistory?.nsE_CodeDetails
            );
          }
        );

        var postvalue = {
          ipoName: applicationData.ncdhistory.iponame,
          clientcode:
            user.clientType === "NONIIFLCLIENT" ? user.panNo : user.clientcode,
          loginId:
            user.clientType == "NONIIFLCLIENT" ? user.panNo : user.loginid,
          noOfShares: noofshares,
          bidPrice: bidPrice,
          // "cutOff": cutoff,
          totalBidCount: applicationData.ncdhistory.bids.length,
          chqAmount: applicationData.ncdhistory.amountpaid,
          categoryType: applicationData.ncdhistory.category,
          issueType: "BOND",
          category: applicationData.ncdhistory?.investortype,
          entryType: "C",
          mkrid: user.clientType == "NONIIFLCLIENT" ? user.panNo : user.loginid,
          subBrokerId: refercode,
          signOffStatus: "",
          appNo: applicationData.ncdhistory.applicationno,
          formtype: "ONLINE",
          ipoBankName: paymenttype,
          flgPassBack: "N",
          flgdiscount: "N",
          BidFlag: "M",
          crmLeadID: "0",
          pincode: "0",
          asbaParameter:
            paymenttype !== "UPI"
              ? `${bankname}|${accountno}|${bankbranch}|0|N`
              : "9999|0|NASBAL|0|N",
          upiNo: paymenttype === "UPI" ? upiname + upihandle : "",
          appSource: user?.AppSource ? user?.AppSource : 25,
        };
      } else {
        if (user.clientType == "NONIIFLCLIENT") {
          const pahseOneData = await addAndCheckNonIiflJourneyClientDetail({
            mobileNo: dataEncrypt(applicationData.mobileNo),
            email: dataEncrypt(applicationData.email),
            panNo: dataEncrypt(applicationData.panNo),
            stageFlage: "Phase1",
          });

          await addAndCheckNonIiflJourneyClientDetail({
            panNo: dataEncrypt(applicationData.panNo),
            firstName: dataEncrypt(applicationData.fstname),
            middleName: applicationData.midname,
            surName: applicationData.lstname,
            mobileNo: dataEncrypt(applicationData.mobileNo),
            email: dataEncrypt(applicationData.email),
            clientcode: applicationData.clientcode,
            dpType: applicationData.applyfr,
            dpid:
              applicationData.applyfr === "NSDL"
                ? dataEncrypt(applicationData.dpid)
                : "",
            beneficiaryID: dataEncrypt(applicationData.beneficiaryID),
            bankBranch: "",
            bankName: bankname,
            bankAccountNo: accountno,
            investorStatus: "Process",
            mkrid: "",
            mkrDt: "",
            dateOfbith: "",
            address: "",
            pincode: "545454",
            secondHolderName: applicationData?.dematDetailsapp[0]?.fullName,
            secondHolderPan: applicationData?.dematDetailsapp[0]?.pan,
            thirdHolderName: applicationData?.dematDetailsapp[1]?.fullName,
            thirdHolderPan: applicationData?.dematDetailsapp[1]?.pan,
            issuecode: applicationData.ncddetails.issuecode,
            clientType: user.clientType,
            leadid: "",
            stageFlage: "Phase2",
          }).then(async (response) => {
            if (
              response.data.statusCode == 200 &&
              response.data.isSuccess == true
            ) {
              if (user.clientType === "IIFLCLIENT") {
                zohoCreateToken().then((resp) => {
                  zohoCreateLead({
                    Token: resp.data.Body.Token,
                    ObjectName: "Lead",
                    Parameters: {
                      FirstName: "",
                      LastName: applicationData.lstname
                        ? applicationData.lstname
                        : "Oneup User", //Dynamic from the field “Name”
                      Mobile: applicationData.mobileNo, //Dynamic from the field “mobile number”
                      Email: applicationData.email, //Dynamic from the field “email id”
                      LeadProduct: "Equity", //Fixed
                      Campaign: "NCD", //Fixed
                      LeadSource: "OneUp", //Fixed
                      LeadSubStatusTemp: "Incomplete", //Fixed
                      Appsource: "25",
                    },
                  });
                });
              }

              var catt = applicationData.ncdfulldetails?.categoryList.filter(
                function (v, i) {
                  return (
                    v.categoryCode === applicationData.ncdcat.split("|")[0]
                  );
                }
              );

              var postvalue = {
                ipoName: applicationData.ncddetails.issuecode,
                // "clientcode": applicationData?.clientcode,
                clientcode:
                  pahseOneData.data.resultData.clientType === "NONIIFLCLIENT"
                    ? applicationData.clientcode
                    : pahseOneData.data.resultData.clientcode,
                loginId:
                  user.clientType == "NONIIFLCLIENT"
                    ? user.panNo
                    : user.loginid,
                noOfShares: noofshares,
                bidPrice: bidPrice,
                // "cutOff": cutoff,
                totalBidCount: noofshares.length,
                chqAmount: chqAmount,
                categoryType: catt[0].bidLimit < chqAmount ? "HNI" : "RET",
                issueType: "BOND",
                category: applicationData.ncdcat.split("|")[0],
                entryType: "C",
                mkrid:
                  user.clientType == "NONIIFLCLIENT"
                    ? user.panNo
                    : user.loginid,
                subBrokerId: refercode,
                signOffStatus: "",
                appNo: 0,
                formtype: "ONLINE",
                ipoBankName: paymenttype,
                flgPassBack: "N",
                flgdiscount: "N",
                BidFlag: "N",
                crmLeadID: "0",
                pincode: "0",
                asbaParameter:
                  paymenttype !== "UPI"
                    ? `${bankname}|${accountno}|${bankbranch}|0|N`
                    : "9999|0|NASBAL|0|N",
                upiNo: paymenttype === "UPI" ? upiname + upihandle : "",
                appSource: user?.AppSource ? user?.AppSource : 25,
              };

              let { data } = await addncd(postvalue);
              if (data.isSuccess === true && data.statusCode === 200) {
                //REMOVE THE REFERRAL CODE FROM LOCALSTORAGE
                localStorage.removeItem("ReferralCode");

                dispatch(
                  setApplicationData({
                    ...applicationData,
                    fstname: "",
                    panNo: "",
                    email: "",
                    mobileNo: "",
                    dpid: "",
                    beneficiaryID: "",
                    placedncddetails: data.resultData,
                    paymenttype: paymenttype,
                    totalpaid: applicationData?.ncdhistory
                      ? applicationData.ncdhistory.amountpaid
                      : chqAmount,
                  })
                );

                dispatch(
                  actionOthersDetail({
                    fstname: "",
                    panNo: "",
                    email: "",
                    mobileNo: "",
                    dpid: "",
                    beneficiaryID: "",
                  })
                );

                // Clevertap start
                function getSeriesNamect(array, keysearch) {
                  var fianalArr = [];
                  array.forEach((element) => {
                    fianalArr.push(element[keysearch]);
                  });
                  return fianalArr;
                }
                ClevertapReact.initialize(clevertap_key);

                if (applicationData?.ncdhistory) {
                  ClevertapReact.event("NCD_Modify Success", {
                    Source: "Application history page",
                    "NCD Name": applicationData.ncdfulldetails.schemename,
                    "No. of series": getSeriesNamect(
                      applicationData.ncdfulldetails.ipoSeriesMap,
                      "seriesNumber"
                    ).join(","),
                    "Investment Amount": "",
                    "Investor category": "",
                    "Application number": "",
                  });
                } else {
                  var ClevertapPayload = {
                    Source: "OneUp HomePage",
                    Step: "3",
                    "Product Type": "NCD",
                    "Issue Name": applicationData.ncdfulldetails?.schemename,
                    "Bid Number": applicationData?.series?.filter(
                      (item) => item.check === true
                    )?.length,
                    "Investment Amount": applicationData.mainslidervalue,
                    "Price Type": "",
                    "Applying for": applicationData.selfoth,
                    "Investor category": applicationData.ncdcat,
                    "payment type": paymenttype,
                    "Failure Message": "",
                    "Application number": "",
                    Amount: "",
                    Series: applicationData?.series
                      ?.filter((item) => item.check === true)
                      .map((item) => item.seriesNumber)
                      .join(","),
                  };
                  ClevertapReact.event("OU_Application Step", ClevertapPayload);
                }
                // Clevertap End

                // SHOW CONFIRMATION MODAL
                if (isMobile) {
                  history.push("/ncd-application-step-4");
                } else {
                  if (paymenttype === "UPI") {
                    setShowConfirmModal(true);
                  } else {
                    setAsbaConfirmationModal(true);
                  }
                }
              } else {
                if (user.clientType === "IIFLCLIENT") {
                  zohoCreateToken().then((resp) => {
                    zohoCreateLead({
                      Token: resp.data.Body.Token,
                      ObjectName: "Lead",
                      Parameters: {
                        FirstName: "",
                        LastName: applicationData.lstname, //Dynamic from the field “Name”
                        Mobile: applicationData.mobileNo, //Dynamic from the field “mobile number”
                        Email: applicationData.email, //Dynamic from the field “email id”
                        LeadProduct: "Equity", //Fixed
                        Campaign: "NCD", //Fixed
                        LeadSource: "OneUp", //Fixed
                        LeadSubStatusTemp: "Incomplete", //Fixed
                        Appsource: "25",
                      },
                    });
                  });
                }

                // Clevertap start
                ClevertapReact.initialize(clevertap_key);
                function getSeriesNamect(array, keysearch) {
                  var fianalArr = [];
                  array.forEach((element) => {
                    fianalArr.push(element[keysearch]);
                  });
                  return fianalArr;
                }
                var ClevertapPayload = {
                  Source: "OneUp HomePage",
                  Step: "3",
                  "Product Type": "NCD",
                  "Issue Name": applicationData.ncdfulldetails.schemename,
                  "Bid Number": applicationData?.series?.filter(
                    (item) => item.check === true
                  )?.length,
                  "Investment Amount": applicationData.mainslidervalue,
                  "Price Type": "",
                  "Applying for": applicationData.selfoth,
                  "Investor category": applicationData.ncdcat,
                  "payment type": paymenttype,
                  "Failure Message": data.resultData.message,
                  "Application number": "",
                  Amount: "",
                  Series: applicationData?.series
                    ?.filter((item) => item.check === true)
                    .map((item) => item.seriesNumber)
                    .join(","),
                };
                ClevertapReact.event("OU_Application Step", ClevertapPayload);
                // Clevertap End

                setalert(data.resultData.message);
                setLoading(false);
              }
            }
          });
        } else {
          var catt = applicationData.ncdfulldetails?.categoryList.filter(
            function (v, i) {
              return v.categoryCode === applicationData.ncdcat.split("|")[0];
            }
          );

          if (applicationData.selfoth === "Self") {
            var postvalue = {
              ipoName: applicationData.ncddetails.issuecode,
              clientcode:
                user?.AppSource === "9" ||
                user?.AppSource === "11" ||
                user?.AppSource === "12" ||
                user?.AppSource === "13"
                  ? user?.clientcode
                  : applicationData.clientcode,
              loginId:
                user.clientType == "NONIIFLCLIENT" ? user.panNo : user.loginid,
              noOfShares: noofshares,
              bidPrice: bidPrice,
              // "cutOff": cutoff,
              totalBidCount: noofshares.length,
              chqAmount: chqAmount,
              categoryType: catt[0].bidLimit < chqAmount ? "HNI" : "RET",
              issueType: "BOND",
              category: applicationData.ncdcat.split("|")[0],
              entryType: "C",
              mkrid:
                user.clientType == "NONIIFLCLIENT" ? user.panNo : user.loginid,
              subBrokerId: refercode,
              signOffStatus: "",
              appNo: 0,
              formtype: "ONLINE",
              ipoBankName: paymenttype,
              flgPassBack: "N",
              flgdiscount: "N",
              BidFlag: "N",
              crmLeadID: "0",
              pincode: "0",
              asbaParameter:
                paymenttype !== "UPI"
                  ? `${bankname}|${accountno}|${bankbranch}|0|N`
                  : "9999|0|NASBAL|0|N",
              upiNo: paymenttype === "UPI" ? upiname + upihandle : "",
              appSource: user?.AppSource ? user?.AppSource : 25,
            };

            let { data } = await addncd(postvalue);
            if (data.isSuccess === true && data.statusCode === 200) {
              //REMOVE THE REFERRAL CODE FROM LOCALSTORAGE
              localStorage.removeItem("ReferralCode");

              dispatch(
                setApplicationData({
                  ...applicationData,
                  fstname: "",
                  // panNo: '',
                  // email: '',
                  // mobileNo: '',
                  // dpid: '',
                  // beneficiaryID: '',
                  placedncddetails: data.resultData,
                  paymenttype: paymenttype,
                  totalpaid: applicationData?.ncdhistory
                    ? applicationData.ncdhistory.amountpaid
                    : chqAmount,
                })
              );

              dispatch(
                actionOthersDetail({
                  fstname: "",
                  panNo: "",
                  email: "",
                  mobileNo: "",
                  dpid: "",
                  beneficiaryID: "",
                })
              );

              // Clevertap start
              function getSeriesNamect(array, keysearch) {
                var fianalArr = [];
                array.forEach((element) => {
                  fianalArr.push(element[keysearch]);
                });
                return fianalArr;
              }
              ClevertapReact.initialize(clevertap_key);

              if (applicationData?.ncdhistory) {
                ClevertapReact.event("NCD_Modify Success", {
                  Source: "Application history page",
                  "NCD Name": applicationData.ncdfulldetails.schemename,
                  "No. of series": getSeriesNamect(
                    applicationData.ncdfulldetails.ipoSeriesMap,
                    "seriesNumber"
                  ).join(","),
                  "Investment Amount": "",
                  "Investor category": "",
                  "Application number": "",
                });
              } else {
                var ClevertapPayload = {
                  Source: "OneUp HomePage",
                  Step: "3",
                  "Product Type": "NCD",
                  "Issue Name": applicationData.ncdfulldetails.schemename,
                  "Bid Number": applicationData?.series?.filter(
                    (item) => item.check === true
                  )?.length,
                  "Investment Amount": applicationData.mainslidervalue,
                  "Price Type": "",
                  "Applying for": applicationData.selfoth,
                  "Investor category": applicationData.ncdcat,
                  "payment type": paymenttype,
                  "Failure Message": "",
                  "Application number": "",
                  Amount: "",
                  Series: applicationData?.series
                    ?.filter((item) => item.check === true)
                    .map((item) => item.seriesNumber)
                    .join(","),
                };
                ClevertapReact.event("OU_Application Step", ClevertapPayload);
              }
              // Clevertap End

              // SHOW CONFIRMATION MODAL
              if (isMobile) {
                history.push("/ncd-application-step-4");
              } else {
                if (paymenttype === "UPI") {
                  setShowConfirmModal(true);
                } else {
                  setAsbaConfirmationModal(true);
                }
              }
            } else {
              if (user.clientType === "IIFLCLIENT") {
                zohoCreateToken().then((resp) => {
                  zohoCreateLead({
                    Token: resp.data.Body.Token,
                    ObjectName: "Lead",
                    Parameters: {
                      FirstName: "",
                      LastName: applicationData.lstname, //Dynamic from the field “Name”
                      Mobile: applicationData.mobileNo, //Dynamic from the field “mobile number”
                      Email: applicationData.email, //Dynamic from the field “email id”
                      LeadProduct: "Equity", //Fixed
                      Campaign: "NCD", //Fixed
                      LeadSource: "OneUp", //Fixed
                      LeadSubStatusTemp: "Incomplete", //Fixed
                      Appsource: "25",
                    },
                  });
                });
              }

              // Clevertap start
              ClevertapReact.initialize(clevertap_key);
              function getSeriesNamect(array, keysearch) {
                var fianalArr = [];
                array.forEach((element) => {
                  fianalArr.push(element[keysearch]);
                });
                return fianalArr;
              }
              var ClevertapPayload = {
                Source: "OneUp HomePage",
                Step: "3",
                "Product Type": "NCD",
                "Issue Name": applicationData.ncdfulldetails.schemename,
                "Bid Number": applicationData?.series?.filter(
                  (item) => item.check === true
                )?.length,
                "Investment Amount": applicationData.mainslidervalue,
                "Price Type": "",
                "Applying for": applicationData.selfoth,
                "Investor category": applicationData.ncdcat,
                "payment type": paymenttype,
                "Failure Message": data.resultData.message,
                "Application number": "",
                Amount: "",
                Series: applicationData?.series
                  ?.filter((item) => item.check === true)
                  .map((item) => item.seriesNumber)
                  .join(","),
              };
              ClevertapReact.event("OU_Application Step", ClevertapPayload);
              // Clevertap End

              setalert(data.resultData.message);
              setLoading(false);
            }
          } else {
            const pahseOneData = await addAndCheckNonIiflJourneyClientDetail({
              mobileNo: dataEncrypt(applicationData.mobileNo),
              email: dataEncrypt(applicationData.email),
              panNo: dataEncrypt(applicationData.panNo),
              stageFlage: "Phase1",
            });

            await addAndCheckNonIiflJourneyClientDetail({
              panNo: dataEncrypt(applicationData.panNo),
              firstName: dataEncrypt(applicationData.fstname),
              middleName: applicationData.midname,
              surName: applicationData.lstname,
              mobileNo: dataEncrypt(applicationData.mobileNo),
              email: dataEncrypt(applicationData.email),
              clientcode: applicationData.clientcode,
              dpType: applicationData.applyfr,
              dpid:
                applicationData.applyfr === "NSDL"
                  ? dataEncrypt(applicationData.dpid)
                  : "",
              beneficiaryID: dataEncrypt(applicationData.beneficiaryID),
              bankBranch: "",
              bankName: bankname,
              bankAccountNo: accountno,
              investorStatus: "Process",
              mkrid: "",
              mkrDt: "",
              dateOfbith: "",
              address: "",
              pincode: "545454",
              secondHolderName: applicationData?.dematDetailsapp[0]?.fullName,
              secondHolderPan: applicationData?.dematDetailsapp[0]?.pan,
              thirdHolderName: applicationData?.dematDetailsapp[1]?.fullName,
              thirdHolderPan: applicationData?.dematDetailsapp[1]?.pan,
              issuecode: applicationData.ncddetails.issuecode,
              clientType: user.clientType,
              leadid: "",
              stageFlage: "Phase2",
            }).then(async (response) => {
              if (
                response.data.statusCode == 200 &&
                response.data.isSuccess == true
              ) {
                if (user.clientType === "IIFLCLIENT") {
                  zohoCreateToken().then((resp) => {
                    zohoCreateLead({
                      Token: resp.data.Body.Token,
                      ObjectName: "Lead",
                      Parameters: {
                        FirstName: "",
                        LastName: applicationData.lstname
                          ? applicationData.lstname
                          : "Oneup User", //Dynamic from the field “Name”
                        Mobile: applicationData.mobileNo, //Dynamic from the field “mobile number”
                        Email: applicationData.email, //Dynamic from the field “email id”
                        LeadProduct: "Equity", //Fixed
                        Campaign: "NCD", //Fixed
                        LeadSource: "OneUp", //Fixed
                        LeadSubStatusTemp: "Incomplete", //Fixed
                        Appsource: "25",
                      },
                    });
                  });
                }

                var postvalue = {
                  ipoName: applicationData.ncddetails.issuecode,
                  // "clientcode": applicationData?.clientcode,
                  clientcode:
                    pahseOneData.data.resultData.clientType === "NONIIFLCLIENT"
                      ? applicationData.clientcode
                      : pahseOneData.data.resultData.clientcode,
                  loginId:
                    user.clientType == "NONIIFLCLIENT"
                      ? user.panNo
                      : user.loginid,
                  noOfShares: noofshares,
                  bidPrice: bidPrice,
                  // "cutOff": cutoff,
                  totalBidCount: noofshares.length,
                  chqAmount: chqAmount,
                  categoryType: catt[0].bidLimit < chqAmount ? "HNI" : "RET",
                  issueType: "BOND",
                  category: applicationData.ncdcat.split("|")[0],
                  entryType: "C",
                  mkrid:
                    user.clientType == "NONIIFLCLIENT"
                      ? user.panNo
                      : user.loginid,
                  subBrokerId: refercode,
                  signOffStatus: "",
                  appNo: 0,
                  formtype: "ONLINE",
                  ipoBankName: paymenttype,
                  flgPassBack: "N",
                  flgdiscount: "N",
                  BidFlag: "N",
                  crmLeadID: "0",
                  pincode: "0",
                  asbaParameter:
                    paymenttype !== "UPI"
                      ? `${bankname}|${accountno}|${bankbranch}|0|N`
                      : "9999|0|NASBAL|0|N",
                  upiNo: paymenttype === "UPI" ? upiname + upihandle : "",
                  appSource: user?.AppSource ? user?.AppSource : 25,
                };

                let { data } = await addncd(postvalue);
                if (data.isSuccess === true && data.statusCode === 200) {
                  //REMOVE THE REFERRAL CODE FROM LOCALSTORAGE
                  localStorage.removeItem("ReferralCode");

                  dispatch(
                    setApplicationData({
                      ...applicationData,
                      fstname: "",
                      panNo: "",
                      // email: "",
                      // mobileNo: "",
                      dpid: "",
                      beneficiaryID: "",
                      placedncddetails: data.resultData,
                      paymenttype: paymenttype,
                      totalpaid: applicationData?.ncdhistory
                        ? applicationData.ncdhistory.amountpaid
                        : chqAmount,
                    })
                  );

                  dispatch(
                    actionOthersDetail({
                      fstname: "",
                      panNo: "",
                      email: "",
                      mobileNo: "",
                      dpid: "",
                      beneficiaryID: "",
                    })
                  );

                  // Clevertap start
                  function getSeriesNamect(array, keysearch) {
                    var fianalArr = [];
                    array.forEach((element) => {
                      fianalArr.push(element[keysearch]);
                    });
                    return fianalArr;
                  }
                  ClevertapReact.initialize(clevertap_key);

                  if (applicationData?.ncdhistory) {
                    ClevertapReact.event("NCD_Modify Success", {
                      Source: "Application history page",
                      "NCD Name": applicationData.ncdfulldetails.schemename,
                      "No. of series": getSeriesNamect(
                        applicationData.ncdfulldetails.ipoSeriesMap,
                        "seriesNumber"
                      ).join(","),
                      "Investment Amount": "",
                      "Investor category": "",
                      "Application number": "",
                    });
                  } else {
                    var ClevertapPayload = {
                      Source: "OneUp HomePage",
                      Step: "3",
                      "Product Type": "NCD",
                      "Issue Name": applicationData.ncdfulldetails.schemename,
                      "Bid Number": applicationData?.series?.filter(
                        (item) => item.check === true
                      )?.length,
                      "Investment Amount": applicationData.mainslidervalue,
                      "Price Type": "",
                      "Applying for": applicationData.selfoth,
                      "Investor category": applicationData.ncdcat,
                      "payment type": paymenttype,
                      "Failure Message": "",
                      "Application number": "",
                      Amount: "",
                      Series: applicationData?.series
                        ?.filter((item) => item.check === true)
                        .map((item) => item.seriesNumber)
                        .join(","),
                    };
                    ClevertapReact.event(
                      "OU_Application Step",
                      ClevertapPayload
                    );
                  }
                  // Clevertap End

                  // SHOW CONFIRMATION MODAL
                  if (isMobile) {
                    history.push("/ncd-application-step-4");
                  } else {
                    if (paymenttype === "UPI") {
                      setShowConfirmModal(true);
                    } else {
                      setAsbaConfirmationModal(true);
                    }
                  }
                } else {
                  if (user.clientType === "IIFLCLIENT") {
                    zohoCreateToken().then((resp) => {
                      zohoCreateLead({
                        Token: resp.data.Body.Token,
                        ObjectName: "Lead",
                        Parameters: {
                          FirstName: "",
                          LastName: applicationData.lstname, //Dynamic from the field “Name”
                          Mobile: applicationData.mobileNo, //Dynamic from the field “mobile number”
                          Email: applicationData.email, //Dynamic from the field “email id”
                          LeadProduct: "Equity", //Fixed
                          Campaign: "NCD", //Fixed
                          LeadSource: "OneUp", //Fixed
                          LeadSubStatusTemp: "Incomplete", //Fixed
                          Appsource: "25",
                        },
                      });
                    });
                  }

                  // Clevertap start
                  ClevertapReact.initialize(clevertap_key);
                  function getSeriesNamect(array, keysearch) {
                    var fianalArr = [];
                    array.forEach((element) => {
                      fianalArr.push(element[keysearch]);
                    });
                    return fianalArr;
                  }
                  var ClevertapPayload = {
                    Source: "OneUp HomePage",
                    Step: "3",
                    "Product Type": "NCD",
                    "Issue Name": applicationData.ncdfulldetails.schemename,
                    "Bid Number": applicationData?.series?.filter(
                      (item) => item.check === true
                    )?.length,
                    "Investment Amount": applicationData.mainslidervalue,
                    "Price Type": "",
                    "Applying for": applicationData.selfoth,
                    "Investor category": applicationData.ncdcat,
                    "payment type": paymenttype,
                    "Failure Message": data.resultData.message,
                    "Application number": "",
                    Amount: "",
                    Series: applicationData?.series
                      ?.filter((item) => item.check === true)
                      .map((item) => item.seriesNumber)
                      .join(","),
                  };
                  ClevertapReact.event("OU_Application Step", ClevertapPayload);
                  // Clevertap End

                  setalert(data.resultData.message);
                  setLoading(false);
                }
              } else {
                setLoading(false);
                setalert(response.data.resultData);
              }
            });
          }
        }
      }
    } catch (e) {
      setalert("Something went wrong try later!");
      setLoading(false);
    }
  };

  const validateupi = async (upihandle) => {
    if (upiname.includes("@")) {
      setalert("@ not allowed");
      setupiname(upiname.split("@")[0]);
    }
    if (upiname !== "" && upihandle !== "") {
      let { data } = await upiValidate(upiname + upihandle);
      setupiuser(data);
    }
  };

  const hidesweeetalert = () => {
    setalert("");
  };

  const redirectsalert = (path = "/") => {
    history.push("/ncd_details/" + applicationData.ncdfulldetails.issuecode);
  };

  useEffect(() => {
    fetchbanklist();
    upivaliditycheck();
  }, []);

  // REDIRECT ON PAGE REFRESSS TO HOME PAGE
  if (!applicationData.ncdfulldetails?.issuecode) {
    return <Redirect to="/" />;
  }

  return (
    <>
      {alert != "" && (
        <SweetAlert
          custom
          showCancel
          showCloseButton
          error
          title="Alert!"
          cancelBtnText="Back to home"
          cancelBtnBsStyle="light"
          onConfirm={hidesweeetalert}
          onCancel={hidesweeetalert}
        >
          {alert}
        </SweetAlert>
      )}
      <div className="zoom_wrapper">
        {loading === true && (
          <div className="loading_screen loading_inside">
            <img src={LoaderImg} alt="loading..." />
          </div>
        )}
      </div>

      <div>
        {abaConfirmationModal ? (
          <AsbaPaymentModal
            setAsbaConfirmationModal={setAsbaConfirmationModal}
          />
        ) : (
          ""
        )}
        {showConfirmModal ? (
          <BiddingSuccess setShowConfirmModal={setShowConfirmModal} />
        ) : (
          ""
        )}
      </div>
      <div className="ncd_haeder_step1 d-md-block d-none">
        <div className="custom_container">
          <img
            onClick={() => history.push("/")}
            width={100}
            src={Group18219}
            alt="logo"
          />
          <span className="px-3">Powered by</span>
          <img src={IIFLSec} alt="logo" />
        </div>
      </div>

      <div className="d-md-none mobile_top_haeder">
        <SideBar />
      </div>

      <div>
        <div className="ncd_step_one_header">
          <div className="custom_container">
            <div className="row total_invest_series">
              <div className="col-md-4 d-md-block d-none">
                <h2>
                  <i class="fa fa-rupee mrgR3"></i>
                  {(applicationData?.mainslidervalue).toLocaleString()}
                </h2>
                <p>Total investment</p>
              </div>
              <div className="col-md-3 d-md-block d-none">
                <h2>
                  {
                    applicationData?.series?.filter(
                      (item) => item.check === true
                    )?.length
                  }{" "}
                  series
                </h2>
                <div className="d-flex">
                  <p className="pe-3">Series</p>
                  {applicationData?.series
                    ?.filter((item) => item.check === true)
                    .map((e, i) => (
                      <p>
                        {e.seriesNumber}
                        {i !==
                        applicationData?.series?.filter(
                          (item) => item.check === true
                        )?.length -
                          1
                          ? ","
                          : ""}{" "}
                      </p>
                    ))}
                </div>
              </div>

              <div className="col-md-5">
                <div className="d-flex align-items-md-start align-items-center">
                  <img
                    onClick={() =>
                      history.push(
                        "/ncd_details/" +
                          applicationData.ncdfulldetails.issuecode
                      )
                    }
                    className="me-4 ncd_logo_name"
                    src={
                      applicationData?.cmsdata?.logo
                        ? `${CMS_URL}${applicationData?.cmsdata?.logo}`
                        : NoImg
                    }
                    alt="Ellipse1860"
                  />

                  <div className="ncd_header_top_step1">
                    <h2>{applicationData?.ncdfulldetails?.schemename}</h2>
                    <p className="d-flex">
                      Ends in{" "}
                      <div
                        className="px-2"
                        dangerouslySetInnerHTML={{ __html: counter }}
                      ></div>
                    </p>
                  </div>
                </div>
              </div>

              <div className="d-flex py-5 d-md-none total_invest_series">
                <div className="col-md-4">
                  <h2>
                    <i class="fa fa-rupee mrgR3"></i>
                    {(applicationData?.mainslidervalue).toLocaleString()}
                  </h2>
                  <p>Total investment</p>
                </div>
                <div className="col-md-3">
                  <h2>
                    {
                      applicationData?.series?.filter(
                        (item) => item.check === true
                      )?.length
                    }{" "}
                    series
                  </h2>
                  <div className="d-flex">
                    <p className="pe-3">Series</p>
                    {applicationData?.series
                      ?.filter((item) => item.check === true)
                      .map((e, i) => (
                        <p>
                          {e.seriesNumber}
                          {i !==
                          applicationData?.series?.filter(
                            (item) => item.check === true
                          )?.length -
                            1
                            ? ","
                            : ""}{" "}
                        </p>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="step_three_wraop">
          <div className="custom_container ">
            <div className="payment_box_shodow">
              <div className="appling_stepone">
                <div className="d-flex pt-5 pb-4">
                  <div className="apply_as">
                    <p>You are applying as</p>
                    <div>
                      <span className="isSelf_oth">
                        {applicationData?.selfoth}
                      </span>{" "}
                      |{" "}
                      <span className="isSelf_oth">
                        {applicationData?.ncdcat}
                      </span>
                    </div>
                  </div>
                  <p
                    onClick={() => {
                      dispatch(
                        setApplicationData({
                          ...applicationData,
                          selfoth: "Self",
                        })
                      );
                      history.push("/ncd-application-step-2");
                    }}
                    className="edit_stepone"
                  >
                    Edit
                  </p>
                </div>
              </div>

              <div className="row-1">
                <div className="pt-4 apply_for_radio">
                  {chqAmount <= 500000 ?
                    <>
                      <input
                        onChange={(e) => {
                          setpaymenttype(e.target.value);
                        }}
                        defaultChecked={true}
                        type="radio"
                        id="upi"
                        name="apply_for"
                        value="UPI"
                      />
                      <label className="ms-2 me-5" htmlFor="upi">
                        Pay using UPI
                      </label>
                    </>
                    :
                    <>
                      <input
                        onChange={(e) => {
                          setpaymenttype(e.target.value);
                        }}
                        type="radio"
                        id="asba"
                        name="apply_for"
                        value="ASBA"
                      />
                      <label className="ms-2 me-5" htmlFor="asba">
                        ASBA
                      </label>
                    </>
                  }
                </div>
              </div>

              {paymenttype === "UPI" ? (
                <div className="wpimain">
                  <div>
                    <p>Enter UPI ID</p>
                    <div className="upiwrap">
                      <input
                        type="text"
                        placeholder="Enter UPI ID"
                        value={upiname}
                        onChange={(e) => {
                          if (!e.target.value) {
                            setupiuser({ statusCode: 100 });
                          }

                          setupiname(e.target.value);
                        }}
                        // onBlur={(e) => validateupi(upihandle)}
                      />
                      <select
                        value={upihandle}
                        onChange={(e) => {
                          setupihandle(e.target.value);
                          // setTimeout(() => {
                          // 	validateupi(e.target.value);
                          // }, 100);
                        }}
                      >
                        <option value="">Select UPI</option>
                        {applicationData.ncdfulldetails?.upiList?.map(
                          (e, i) => {
                            return (
                              <option key={i} value={e.upiName}>
                                {e.upiName}
                              </option>
                            );
                          }
                        )}
                      </select>
                      <img src={downarrow} alt="downarrow" />
                    </div>
                    {/* <div className="upimsg">
									{Object.keys(upiuser).length > 0 && upiname.length !== 0 && (
										<>
											{upiuser.statusCode === 100 ? (
												<p>Please provide your upi id</p>
											) : upiuser.statusCode !== 200 ? (
												<p>something went wrong try later</p>
											) : (
												<>
													{upiuser?.resultData?.success === true ? (
														<p className="upifetchsuccess">
															Verified {upiuser.resultData.customer_name} (
															{upiuser.resultData.vpa})
														</p>
													) : (
														<p className="upifetchfailed">
															Not Verified {upiuser.resultData.customer_name} (
															{upiuser.resultData.vpa})
														</p>
													)}
												</>
											)}
										</>
									)}
								</div> */}
                  </div>

                  <div
                    className={
                      paymenttype === "ASBA"
                        ? "referral_code"
                        : "referral_code d-md-block d-none"
                    }
                  >
                    <p>Referee Code</p>
                    <input
                      disabled={
                        localStorage?.getItem("ReferralCode") ? true : false
                      }
                      value={localStorage?.getItem("ReferralCode")}
                      type="text"
                      onChange={(e) => setrefercode(e.target.value)}
                      placeholder="Enter code given by RM/Franchise/Sub-Broker"
                    />
                  </div>
                </div>
              ) : (
                <>
                  <div className="asba_pay_wrap">
                    <div className="row g-5">
                      <div className="col-md-4 col-lg-3">
                        <div>
                          <p>Bank Name</p>
                          <div className="bank-name">
                            <select
                              value={bankname}
                              onChange={(e) => {
                                fetchlocation(e.target.value);
                                setbankname(e.target.value);
                              }}
                            >
                              <option value="">Select Bank</option>
                              {banklist.map((e, i) => {
                                return (
                                  <option value={e.rbi_codes}>
                                    {e.bankFName}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div>
                          <p>Account Number</p>

                          <input
                            type="text"
                            disabled={
                              applicationData?.ncdhistory ? true : false
                            }
                            defaultValue={
                              applicationData?.ncdhistory
                                ? applicationData?.ncdhistory?.accountNumber
                                : user?.bankAccountNo
                                ? user?.bankAccountNo
                                : ""
                            }
                            onChange={(e) => setaccountno(e.target.value)}
                            placeholder="Enter Account No."
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div>
                          <p>Referee Code</p>
                          <input
                            disabled={
                              localStorage?.getItem("ReferralCode")
                                ? true
                                : false
                            }
                            value={localStorage?.getItem("ReferralCode")}
                            type="text"
                            onChange={(e) => setrefercode(e.target.value)}
                            placeholder="Enter code given by RM/Franchise/Sub-Broker"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <h4 className="pb-3">Help us find the nearest branch</h4>
                  <div className="row-3">
                    <div>
                      <p>Your Current Location</p>
                      <div className="location">
                        <select
                          value={bankbranch}
                          onChange={(e) => setbankbranch(e.target.value)}
                        >
                          <option value="">Select Location</option>
                          {banklocationlist.map((e, i) => {
                            return (
                              <option value={e.location}>{e.location}</option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>

            {paymenttype === "UPI" && (
              <div className="referral_code d-md-none">
                <p>Referee Code</p>
                <input
                  disabled={
                    localStorage?.getItem("ReferralCode") ? true : false
                  }
                  value={localStorage?.getItem("ReferralCode")}
                  type="text"
                  onChange={(e) => setrefercode(e.target.value)}
                  placeholder="Enter code given by RM/Franchise/Sub-Broker"
                />
              </div>
            )}

            {paymenttype === "UPI" ? (
              <div className="step_one_btn py-5">
                <button
                  disabled={
                    upiname.length === 0 ||
                    upihandle.length === 1 ||
                    upihandle.length === 0
                      ? true
                      : false
                  }
                  style={{
                    background:
                      upiname.length === 0 ||
                      upihandle.length === 1 ||
                      upihandle.length === 0
                        ? "#ddd"
                        : "",
                  }}
                  className="d-md-block d-none"
                  onClick={() => {
                    placencd(false);
                  }}
                >
                  {isVerifying
                    ? "Verifying please wait..."
                    : "Submit Application"}
                </button>

                <button
                  disabled={
                    upiname.length === 0 ||
                    upihandle.length === 1 ||
                    upihandle.length === 0
                      ? true
                      : false
                  }
                  style={{
                    background:
                      upiname.length === 0 ||
                      upihandle.length === 1 ||
                      upihandle.length === 0
                        ? "#ddd"
                        : "",
                  }}
                  className="d-md-none d-block"
                  onClick={() => {
                    placencd(true);
                  }}
                >
                  {isVerifying
                    ? "Verifying please wait..."
                    : "Submit Application"}
                </button>
              </div>
            ) : (
              <div className="step_one_btn py-5">
                <button
                  className="d-md-block d-none"
                  onClick={() => {
                    placencd(false);
                  }}
                >
                  Submit Application
                </button>

                <button
                  className="d-md-none d-block"
                  onClick={() => {
                    placencd(true);
                  }}
                >
                  Submit Application
                </button>
              </div>
            )}
            <div className="ncd_popup">
              <div
                id="popup1"
                className={showDisclaimer ? "overlay showPopup" : "overlay"}
              >
                <div className="popup disclaimerPop">
                  <span className="dis_close">
                    <img
                      onClick={() => setShowDisclaimer(false)}
                      src={dis_close}
                      alt="dis_close"
                    />
                  </span>
                  <div className="disclamer_content">
                    <h3>Please note the generic disclaimer</h3>
                    <ul>
                      <li>
                        The securities available are part of a public issue.
                      </li>
                      <li>
                        Investments in the securities market are subject to
                        market risks, further investment in fixed income
                        security involves risks such as default and liquidity
                        risk (low volume). Please read all the related offer
                        documents carefully before investing and/or obtain
                        expert professional advice.
                      </li>
                      <li>
                        It is advisable to verify the veracity of the related
                        information before using the information provided in the
                        offer document.
                      </li>
                      <li>
                        The Subscription to the same is voluntary and no advice
                        has been given by IIFL Securities Limited or any of its
                        employees.
                      </li>
                      <li>
                        IIFL group, associate and subsidiary companies are
                        engaged in providing various financial services and for
                        the said services (including the service for acquiring
                        and sourcing the units of the fund) may earn fees or
                        remuneration.
                      </li>
                      <li>
                        The company is just a referral / distributor for
                        Non-Broking Products/Services such as loans, Mutual
                        Fund, Mutual Fund-SIP, Research reports, Insurance etc.,
                        All disputes with respect to the distribution activity
                        would not have access to Exchange investor redressal
                        forum or Arbitration mechanism. Loan products are
                        offered by IIFL Finance Ltd. & IIFL Home Finance Ltd and
                        Insurance Products are offered by IIFL Insurance Brokers
                        Limited.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="disclamer">
              By clicking on submit , you agree to our{" "}
              <span onClick={() => setShowDisclaimer(true)}>disclamer</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Ncdapplication3;
