import React from "react";
import "./GsecPaymentDetail.css";

const GsecPaymentDetail = ({ gsecDetail, invtQty }) => {
  return (
    <div className="payment-detail">
      <h2>Payment details</h2>
      <div className="payment-detail-box">
        <div className="payment-detail-box-content">
          <div>
            <p>Total investment amount</p>
            <small>(Face Value * Quantity)</small>
          </div>
          <h3>₹{(gsecDetail?.faceValue * invtQty).toLocaleString("en-IN")}</h3>
        </div>
        {gsecDetail.category !== "TB" && (
          <div className="payment-detail-box-content">
            <div>
              <p>Total payable amount</p>
              <small>(Markup Value * Quantity)</small>
            </div>
            <h3>
              ₹{(gsecDetail?.markupFaceValue * invtQty).toLocaleString("en-IN")}
            </h3>
          </div>
        )}

        <div className="to-pay-amount">
          <h3>To pay</h3>
          <h3>
            ₹{(gsecDetail?.markupFaceValue * invtQty).toLocaleString("en-IN")}
          </h3>
        </div>
      </div>
    </div>
  );
};

export default GsecPaymentDetail;
