import * as gsecType from "./gsecType";


export const actionGsecList = (data) => {
    return {
        type: gsecType.GSEC_LIST,
        payload: data,
    };
};

