import React from "react";
import { useState } from "react";
import { getIssueDetailsFromCmsByCode } from "../../../services/issuesServices";
import { dateFormatter } from "../../../helpers/utils";
import { useEffect } from "react";
import OtherGsecCard from "../GsecWeb/OtherGsecCard/OtherGsecCard.js";
import "./GsecCard.css";

const GsecCard = ({ details }) => {
  const [cmsData, setcmsData] = useState({});
  const [exptimer, setexptimer] = useState("");
  const [counterInterval, setcounterInterval] = useState({});

  const applyTimer = (closedate) => {
    let date_1 = new Date(closedate);
    let date_2 = new Date();
    let difference = date_1.getTime() - date_2.getTime();
    let expDays = Math.ceil(difference / (1000 * 3600 * 24));
    if (expDays > 1) {
      setexptimer(`${expDays} Days`);
    } else {
      let counterInt = setInterval(() => {
        setexptimer(dateFormatter(new Date(closedate).getTime()));
      }, 1000);
      setcounterInterval(counterInt);
    }
  };

  const getCmsData = async (issuecode) => {
    let cmsdata = {};
    try {
      cmsdata = await getIssueDetailsFromCmsByCode(issuecode);
      setcmsData(cmsdata?.data?.result);
    } catch (error) {
      cmsdata = {};
    }
  };

  useEffect(() => {
    clearInterval(counterInterval);
    getCmsData(details?.issuecode);
    applyTimer(details?.clsdt);
  }, []);

  return (
    <div className="gsec_card_desg">
      <OtherGsecCard details={details} />
    </div>
  );
};

export default GsecCard;
