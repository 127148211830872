import s from './SideBar.module.scss';
import classNames from 'classnames';
import useWindowDimensions from '../../hooks/screenWidth';
import { Fragment } from 'react';
import OneUpLogo from '../../assets/images/LandingPage/OneUp_Logo_132x40_px@3x.png';
import helpIcon from '../../assets/images/SideBar/help.svg';
import applicationIcon from '../../assets/images/SideBar/applications.svg';
import closeIcon from '../../assets/images/SideBar/closIcon.svg';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import PoweredBySecLogo from '../../assets/images/LandingPage/IIFLSec.png';
import { useState } from 'react';

const SideBar = ({ toggleLogin }) => {
	const { width } = useWindowDimensions();
	const user = useSelector((state) => state.loggedIn);
	const [helpershow, sethelpershow] = useState(() => { return localStorage.getItem('helpershow') ? localStorage.getItem('helpershow') : 0 });

	const history = useHistory();
	const pathname = window.location.pathname

	const logoutuser = () => {
		localStorage.removeItem('user');
		window.location.reload();
	};

	const closeApplicationhelp = () => {
		localStorage.setItem('helpershow', 1);
		sethelpershow(1)
	}

	return (
		<div className={pathname === '/sovereign_gold_bond_details' ? s.sgbsidebarmain : s.main}>
			{width > 768 ? (
				<Fragment>
					<div className={s.items}>
						<button onClick={() => history.push("/")} className={classNames(s.item, s.home, pathname === '/' ? s.itemActive : '')} />
						{Object.keys(user).length > 0 &&
							<button onClick={() => {
								localStorage.setItem('app_history', pathname)
								history.push("/your_applications");
							}} className={classNames(s.item, s.applustatus, pathname === '/your_applications' ? s.itemActive : '')} />
						}
						<a href="https://help.indiainfoline.com/portal/en/kb/iifl-help/ipo-ofs" target="_blank"><button className={classNames(s.item, s.help, false ? s.itemActive : '')} /></a>
					</div>
					<div className={s.bottom}>
					</div>
				</Fragment>
			) : (
				<>

					<div className={s.mobile}>
						<nav className="navbar navbar-expand-lg navbar-light bg light" style={{ width: "-webkit-fill-available" }}>
							<div className="container-fluid">
								<div className={s.left} onClick={() => history.push("/")}>
									<img src={OneUpLogo} alt="OneUp" className={s.logo} />
									<span>Powered by<img src={PoweredBySecLogo} alt="Powered By IIFL Securities" className={s.powered} /></span>
								</div>
								<div className={s.mobileMenu}>
									<a href="https://help.indiainfoline.com/portal/en/kb/iifl-help/ipo-ofs" rel="noreferrer" target="_blank"><img src={helpIcon} alt={"Help"} /></a>

									{Object.keys(user).length > 0 &&
										<div className={s.myApplications} > <span onClick={() => {
											localStorage.setItem('app_history', pathname)
											history.push("/your_applications");

										}}><img src={applicationIcon} alt={"My applications"} /> My Bids </span>
											{helpershow === 0 &&
												<>
													<div className={s.applicationHelperTooltip}>
														<img src={closeIcon} alt={'Close'} onClick={() => closeApplicationhelp()} />
														Track your <br /> application here!
													</div>
												</>
											}
										</div>
									}

									{(() => {
										if (user?.user?.AppSource != "1" && user?.user?.AppSource != "5" && user?.user?.AppSource != "6" && user?.user?.AppSource != "10" && user?.user?.AppSource != "0") {
											return (
												<>
													{Object.keys(user).length > 0 ?
														<button className={s.loginMobileBtn} onClick={() => logoutuser()}>Logout</button> :
														<button className={s.loginMobileBtn} onClick={() => toggleLogin()}>Login</button>
													}
												</>
											)
										}
									})()}
								</div>
							</div>
						</nav>
					</div>
				</>
			)}

		</div>
	);
};

export default SideBar;
