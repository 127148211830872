import React, { Fragment } from 'react';
import s from '../LandingPage/LandingPage.module.scss';
import EventImg from '../../assets/images/LandingPage/event_black_24dp.svg';
import { useHistory } from 'react-router-dom';
import useWindowDimensions from '../../hooks/screenWidth';
import ClevertapReact from 'clevertap-react';
import { CMS_URL, clevertap_key, CMS_API_URL } from '../../vars/url';
import PeopleImg from '../../assets/images/LandingPage/people_black_24dp (1)@3x.png';
import stars from '../../assets/images/stars.svg';

const Section1BigCard = ({
    logo,
    upper_tag_logo,
    card_color_left,
    card_color_right,
    apply_button_text,
    issuecode,
    invest_minimum_amount,
    invest_minimum_shares,
    invest_within,
    subscriptions,
    know_more,
    expiry_date,
    subsdata,
    openipodt,
    issue_name,
    user,
    applyipoind,
}) => {
    const { width } = useWindowDimensions();
    const history = useHistory();
    const handleClicksec = (issuecode) => {

        ClevertapReact.initialize(clevertap_key);
        // ClevertapReact.event("In-Demand	Know More", {
        //     'path': window.location.hostname,
        //     'user-agent': navigator.userAgent,
        //     'issue-Type': 'ipo',
        //     'app-source': Object.keys(user).length > 0 ? (user?.user?.AppSource ? user?.user?.AppSource : 25) : 25,
        //     'Source': 'Landing Page',
        //     'Issuecode': issuecode
        // })
        ClevertapReact.event("OU_Indemand Click", {
            "Button Name": "Know More",
            "Issue Name": issuecode,
            'client-type': Object.keys(user).length > 0 ? user.user.clientType : 'Pre-login',
            'client-code': Object.keys(user).length > 0 ? user.user.clientcode : 'Pre-login',
        })

        history.push("/ipo_details/" + issuecode)
    }
    card_color_left = "#4940a9";
    card_color_right = "#241f55";

    return (
        <div className={s.card1 + ' ' + s.bigCardInDeemand + " animate__animated animate__fadeIn hover-animate"} style={{ background: `linear-gradient(321deg,  ${card_color_right}, ${card_color_left})` }} >

            {upper_tag_logo !== 'none' &&
                <div className={s.top + " animate__animated animate__zoomIn"}>
                    <span>
                        <img src={`${CMS_API_URL}/icons/${upper_tag_logo}.svg`} alt={upper_tag_logo} />
                        {upper_tag_logo === 'fire' && 'On fire!'}
                        {upper_tag_logo === 'percentage' && 'Tax benefits'}
                        {upper_tag_logo === 'rupees' && 'Fixed returns'}
                        {upper_tag_logo === 'discount' && 'On discount'}
                    </span>
                </div>
            }
            {width > 768 && <img src={`${CMS_URL}${logo}`} style={{ borderRadius: '50%' }} alt={issuecode} />}

            <div className={s.right}>
                {width > 768 ? (
                    <Fragment>
                        <h3 style={card_color_left === '#ffffff' ? { color: '#000' } : { width: 'initial' }}>{issue_name}</h3>
                        <div className={s.subHeading}>
                            {openipodt.map((e, litm) => {
                                if (issuecode == e.issuecode) {
                                    return (
                                        <>
                                            <span style={card_color_left === '#ffffff' ? { color: '#000' } : { width: 'initial' }}>Invest minimum <i class="fa fa-rupee mrgR3"></i>{e.lowprice * e.lotsize}</span>
                                            <span style={card_color_left === '#ffffff' ? { color: '#000' } : { width: 'initial' }} className={s.light}>/ {e.lotsize} Shares </span>
                                        </>
                                    )
                                }

                            })}




                        </div>
                    </Fragment>
                ) : (
                    <div className={s.mobileHead}>
                        <img src={`${CMS_URL}${logo}`} alt={issuecode} />
                        <div className={s.heading}>
                            <h3>{issue_name}</h3>
                            <div className={s.subHeading}>
                                {openipodt.map((e, litm) => {
                                    if (issuecode == e.issuecode) {
                                        return (
                                            <>
                                                <span style={card_color_left === '#ffffff' ? { color: '#000' } : { width: 'initial' }}>Invest minimum <i class="fa fa-rupee mrgR3"></i>{e.lowprice * e.lotsize}</span>
                                                <span style={card_color_left === '#ffffff' ? { color: '#000' } : { width: 'initial' }} className={s.light}>/ {e.lotsize} Shares </span>
                                            </>
                                        )
                                    }

                                })}
                            </div>
                        </div>
                    </div>
                )}


                {/* <div className={s.sub}>
                    <img src={PeopleImg} alt="Subscription" />
                    {subsdata ?
                        <span>
                            IPO subscribed by {(subsdata * 100).toFixed(2)}%
                        </span>
                        :
                        <span>
                            Subscription count will be updated soon
                        </span>
                    }
                </div> */}


                <div className={s.invest}>

                    {openipodt.map((e, litm) => {
                        if (issuecode == e.issuecode) {
                            return (
                                <>
                                    <img src={EventImg} style={card_color_left === '#ffffff' ? { filter: 'invert(1)' } : { filter: 'inherit' }} alt="Invest" />
                                    <span style={card_color_left === '#ffffff' ? { color: '#000' } : { width: 'initial' }}>Invest Before {new Date(e.clsdt).toLocaleDateString('en-IN', {
                                        day: '2-digit', month: '2-digit', year: 'numeric'
                                    })} 5:00 PM.</span>
                                </>
                            )
                        }
                    })}


                </div>

                <div className={s.buttons}>
                    <button className={s.button1} onClick={() => handleClicksec(issuecode)} style={card_color_left === '#ffffff' ? { color: '#000', background: '#ccc' } : { maxwidth: 'initial' }}>Know More</button>

                    <button className={s.button2} onClick={() => applyipoind(issuecode)} style={card_color_left === '#ffffff' ? { color: '#000', background: '#ccc' } : { maxwidth: 'initial' }} >Apply Now</button>
                </div>
            </div>
            <img className={s.stars} src={stars} alt="star" />
        </div>
    )
};

export default Section1BigCard;
