import React, { useState } from 'react';
import './NpsForm.scss';
import img3 from '../../assets/images/nps/img3.svg';
import img4 from '../../assets/images/nps/img4.svg';
import img5 from '../../assets/images/nps/img5.svg';
import img6 from '../../assets/images/nps/img6.svg';
import img8 from '../../assets/images/nps/img8.svg';
import { checkPRANNo } from '../../services/issuesServices';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { actionSetNpsData, actionSetNpsPaymentMethod } from '../../store/nps/npsAction';
const panregex = /[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

const NpsForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [addArrears, setAddArrears] = useState(false);
  const [pranNo, setPranNo] = useState('');
  const [panno, setPanno] = useState('');
  const [normalAmount, setNormalAmount] = useState('');
  const [arrearsAmount, setArrearsAmount] = useState('');
  const [reffrenceNo, setReffrenceNo] = useState('');
  const [showChargesModal, setShowChargesModal] = useState(false);
  const [paymentType, setPaymentType] = useState('netBanking');
  const [errMsg, setErrMsg] = useState({
    pranNo: '',
    panno: '',
    normalAmount: '',
    arrearsAmount: '',
  });
  const history = useHistory();
  const dispatch = useDispatch();


  const validatePranNumber = async (e) => {
    e.preventDefault();

    if (
      !pranNo ||
      !normalAmount ||
      (!arrearsAmount && addArrears) ||
      normalAmount < 1 ||
      (arrearsAmount < 1 && addArrears) ||
      pranNo.length !== 12
    ) {
      if (!panno) setErrMsg((preVal) => ({ ...preVal, panno: 'Please enter PAN number' }));
      if (!normalAmount) setErrMsg((preVal) => ({ ...preVal, normalAmount: 'Please enter top-up amount' }));
      if (!arrearsAmount && addArrears) setErrMsg((preVal) => ({ ...preVal, arrearsAmount: 'Please enter arrears amount' }));
      return false;
    }

    setShowChargesModal(true)
  }

  const handleCheckPranNumber = async (panNumber) => {
    if (panNumber.length === 10) {
      try {
        setIsLoading(true);
        const res = await checkPRANNo({ panno: panNumber });
        if (res.data.isSuccess && res.data.statusCode == 200) {
          setPranNo(res.data.resultData.pranNo);
          setReffrenceNo(res.data.resultData.uniqueRefNumber);
        } else {
          setPranNo('');
          setErrMsg((preVal) => ({ ...preVal, panno: res.data.resultData }));
        }
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoading(false);
      }
    }
  }

  const confirmPayment = () => {
    if (paymentType === 'netBanking') {
      dispatch(actionSetNpsPaymentMethod({
        netbanking: true,
        card: false,
        upi: false
      }))
    } else if (paymentType === 'debitCard') {
      dispatch(actionSetNpsPaymentMethod({
        netbanking: false,
        card: true,
        upi: false
      }))
    } else {
      dispatch(actionSetNpsPaymentMethod({
        netbanking: false,
        card: false,
        upi: true
      }))
    }

    dispatch(actionSetNpsData({
      paymentType,
      panno,
      pranNo,
      normalAmount,
      arrearsAmount: arrearsAmount ? arrearsAmount : '0',
      uniqueRefNumber: reffrenceNo
    }));

    setShowChargesModal(false)
    history.push('/nps-payment');
  }


  return (
    <div className='nps_form'>
      <div className='logo'>
        <img src={img3} alt="img" />
        <small>Powered by</small>
        <img src={img4} alt="img" />
      </div>
      <div className='rocket_img'>
        <img src={img5} alt="img" />
      </div>
      <div className='circle_img'>
        <img src={img6} alt="img" />
      </div>

      <form onSubmit={validatePranNumber}>
        <div className='form_input_box'>
          <h2>Make your Top-Up Payment Now</h2>
          <p>This NPS system is for the subscribed users only.</p>
          <div className='form-input'>
            <label>PAN Number*</label>
            <br />
            {isLoading &&
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            }
            <input
              value={panno}
              maxLength="10"
              onChange={(e) => {
                setPanno(e.target.value.toUpperCase());
                if (!e.target.value) {
                  setPranNo('');
                  setErrMsg((preVal) => ({ ...preVal, panno: 'Please enter PAN number' }));
                } else if (!panregex.test(e.target.value.toUpperCase())) {
                  setPranNo('');
                  setErrMsg((preVal) => ({ ...preVal, panno: 'PAN number must be valid' }));
                } else {
                  setErrMsg((preVal) => ({ ...preVal, panno: '' }));
                  handleCheckPranNumber(e.target.value.toUpperCase());
                }
              }}
              type="text"
              placeholder='Enter PAN Number'
            />
            <span>{errMsg.panno}</span>
          </div>
          <div className='form-input'>
            <label>PRAN Number*</label>
            <br />
            <input readOnly value={pranNo} type="text" />
            <span>{errMsg.pranNo}</span>
          </div>
          <div className='form-input'>
            <label>Top-Up Amount*</label>
            <br />
            <input
              value={normalAmount}
              onChange={(e) => {
                setNormalAmount(e.target.value);
                if (!e.target.value) {
                  setErrMsg((preVal) => ({ ...preVal, normalAmount: 'Please enter top-up amount' }));
                } else if (e.target.value < 1) {
                  setErrMsg((preVal) => ({ ...preVal, normalAmount: 'Top-up amount must be greater than 0' }));
                } else {
                  setErrMsg((preVal) => ({ ...preVal, normalAmount: '' }));
                }
              }}
              type="number"
              placeholder='Enter Amount'
            />
            <span>{errMsg.normalAmount}</span>

          </div>
          {addArrears && <div className='form-input'>
            <label>Arrears Amount*</label>
            <br />
            <input
              value={arrearsAmount}
              onChange={(e) => {
                setArrearsAmount(e.target.value);
                if (!e.target.value) {
                  setErrMsg((preVal) => ({ ...preVal, arrearsAmount: 'Please enter arrears amount' }));
                } else if (e.target.value < 1) {
                  setErrMsg((preVal) => ({ ...preVal, arrearsAmount: 'Arrears amount must be greater than 0' }));
                } else {
                  setErrMsg((preVal) => ({ ...preVal, arrearsAmount: '' }));
                }
              }}
              type="number"
              placeholder='Enter Amount'
            />
            <span>{errMsg.arrearsAmount}</span>

          </div>}
        </div>
        <div className='form_btn'>
          <button type='submit'>Submit</button>
          <button
            onClick={() => {
              setAddArrears(!addArrears)
              if (addArrears) {
                setErrMsg((preVal) => ({ ...preVal, arrearsAmount: '' }));
                setArrearsAmount('');
              }
            }}
            type='button'
            className='arrer_btn'
          >
            {addArrears ? 'Remove Arrears' : 'Add Arrears'}
          </button>
        </div>
      </form>

      <div className={showChargesModal ? 'popup_overlay show' : 'popup_overlay'}></div>
      <div className={showChargesModal ? 'popup pay_charges show' : 'popup pay_charges'}>
        <img onClick={() => setShowChargesModal(false)} className='close' src={img8} alt="img" />
        <div className='charge-head'>
          <h2>Transaction Charges for NPS</h2>
        </div>
        <div className='nps-charges-box'>
          <div className='charge-option nps-charges-head'>
            <div className='table-divider'>Method</div>
            <div>Pricing</div>
          </div>
          <div className='charge-option'>
            <div>Net-Banking</div>
            <div>INR 15</div>
          </div>
          <div className='charge-option'>
            <div>Debit Card</div>
            <div>NA</div>
          </div>
          <div className='charge-option'>
            <div>0-2000</div>
            <div>0.50%</div>
          </div>
          <div className='charge-option'>
            <div>2001 & above</div>
            <div>1.00%</div>
          </div>
          <div className='charge-option last'>
            <div>UPI</div>
            <div>INR 5</div>
          </div>
        </div>
        <div className='charges-note'>
          <span>NOTE:</span> 18% GST will be applied to all Payment Modes.
        </div>


        <div className='payment-method'>
          <h1>Select Payment Method</h1>
          <div className="pb-5 apply_for_radio">

            <div
              className={
                paymentType === "netBanking" ? "radio_label active" : "radio_label"
              }
            >
              <input
                onChange={(e) => setPaymentType(e.target.value)}
                defaultChecked={true}
                type="radio"
                id="netBanking"
                name="payment_type"
                value="netBanking"
              />
              <label className="ms-2" htmlFor="netBanking">
                Net-Banking
              </label>
            </div>
            <div
              className={
                paymentType === "debitCard" ? "radio_label active" : "radio_label"
              }
            >
              <input
                onChange={(e) => setPaymentType(e.target.value)}
                type="radio"
                id="debitCard"
                name="payment_type"
                value="debitCard"
              />
              <label className="ms-2" htmlFor="debitCard">
                Debit Card
              </label>
            </div>
            <div
              className={
                paymentType === "upi" ? "radio_label active" : "radio_label"
              }
            >
              <input
                onChange={(e) =>setPaymentType(e.target.value)}
                type="radio"
                id="upi"
                name="payment_type"
                value="upi"
              />
              <label className="ms-2" htmlFor="upi">
                UPI
              </label>
            </div>
          </div>
        </div>

        <div className='form_btn'>
          <button onClick={() => confirmPayment()} type='button'>Confirm</button>
        </div>
      </div>

    </div>
  )
};

export default NpsForm;