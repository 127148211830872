import React from "react";
import OtherGsecCard from "../OtherGsecCard/OtherGsecCard";

const ExploreMore = ({ allGsecList }) => {
  return (
    <div className="explore_more">
      {allGsecList.length > 0 && <h1 className="explore_more_head">Explore government securities</h1>}
      <div className="explore_more_card">
        {allGsecList.map((data) => (
          <div key={data.issuecode}>
            <OtherGsecCard details={data} isHome={false} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExploreMore;
