import React, { useEffect, useState } from "react";
import s from "./GsecSection.module.scss";
import { useHistory } from "react-router-dom";
import GsecCard from "./GsecCard";
import useWindowDimensions from "../../../hooks/screenWidth";
import { useSelector } from "react-redux";

const GsecSection = ({ details, toggleLogin }) => {
  const history = useHistory();
  const { width } = useWindowDimensions();
  const [applyLogin, setapplyLogin] = useState(false);
  const user = useSelector((state) => state.loggedIn);

  // CHECK USER IS LOGGEDIN OR NOT
  const validate = () => {
    var user = localStorage.getItem("user");
    if (!user) {
      setapplyLogin(true);
      toggleLogin();
    } else {
      history.push(`/gsec_details`);
    }
  };

  const applyAutoLogin = async () => {
    if (Object.keys(user).length > 0 && applyLogin === true) {
      validate();
    }
  };

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      // applyAutoLogin();
    }
    return () => {
      unmounted = true;
    };
  }, [user]);

  return (
    <>
      <h1 className={s.gseclandingheading}>Explore Government Securities</h1>
      <div className={s.gseclandingWrap}>
        <div className={s.gseccardbox}>
          {details.map((e, litm) => {
            if (litm > 1 && width < 768) {
              return;
            }
            if (litm > 4 && width > 768) {
              return;
            }

            return <GsecCard key={e.issuecode} details={e} />;
          })}
        </div>

        {details.length > 5 && width > 768 && (
          <button
            className={s.allbondsbutton}
            onClick={() => history.push(`/gsec_details`)}
          >
            View all bonds ({details.length})
          </button>
        )}
        {details.length > 1 && width < 768 && (
          <button
            className={s.allbondsbutton}
            onClick={() => history.push(`/gsec_details`)}
          >
            View all bonds ({details.length})
          </button>
        )}
      </div>
    </>
  );
};

export default GsecSection;
