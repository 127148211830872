import './ncd-application-01.css';
import { useEffect, useState } from 'react';
import { getNCDdetails } from '../../services/issuesServices';
import { dateFormatter } from '../../helpers/utils';
import { CMS_URL, clevertap_key } from '../../vars/url';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import NoImg from '../../assets/images/noimg.jpg';
import { useHistory } from 'react-router-dom';
import LoaderImg from '../../assets/images/loader.svg';
import ClevertapReact from 'clevertap-react';
import { applicationData as setApplicationData } from '../../store/action/applicationData';
import SweetAlert from 'react-bootstrap-sweetalert';

import Ellipse1860 from "../../assets/images/NCDDetail/Ellipse 1860.svg";



import "./NewNcd.css";
import Group18219 from "../../assets/images/LandingPage/Group 18219.svg";
import IIFLSec from "../../assets/images/LandingPage/IIFL Sec.svg";


const Ncdapplication1 = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const applicationData = useSelector(state => state.applicationData)
    const [series, setseries] = useState([])
    const [ncdfulldetails, setncdfulldetails] = useState({})
    const [counter, setCounter] = useState('')
    const [swalert, setalert] = useState('');
    const [loading, setLoading] = useState(true);


    const genaratelist = () => {
        if (applicationData?.series) {
            setseries(applicationData?.series)
            return false
        }
        let series = applicationData.ncddetails.ipoSeriesMap
        let monhts = applicationData.monthselected
        let mainslidervalue = applicationData.mainslidervalue

        var noseries = series.filter(function (v, i) {
            return (monhts.includes(v.tenure));
        })

        let finalseries = [];
        let firstcheckseries = -1;
        for (let i = 0; i < series.length; i++) {
            const singlseries = series[i];
            if (monhts.includes(series[i].tenure) && mainslidervalue >= series[i].minAmt) {
                if (firstcheckseries === -1) {
                    firstcheckseries = i;
                }
                singlseries.check = 1
                mainslidervalue = parseFloat(mainslidervalue) - parseFloat(series[i].minAmt)
                singlseries.cardslide = series[i].minAmt
                singlseries.cardqty = singlseries.cardslide / series[i].rate
            }
            else {
                singlseries.check = 0
                singlseries.cardslide = 0
            }
            finalseries.push(singlseries)

        }
        if (firstcheckseries !== -1) {
            finalseries[firstcheckseries].cardslide = parseFloat(mainslidervalue) + parseFloat(finalseries[firstcheckseries].cardslide)
            finalseries[firstcheckseries].cardqty = finalseries[firstcheckseries].cardslide / finalseries[firstcheckseries].rate
        }

        if (monhts.length < 1) {
            finalseries[0].cardslide = parseFloat(mainslidervalue)
            finalseries[0].cardqty = finalseries[0].cardslide / finalseries[0].rate
            finalseries[0].check = 1
        }
        setseries([...finalseries])


        ClevertapReact.initialize(clevertap_key);
        function getSeriesNamect(array, keysearch) {
            var fianalArr = [];
            array.forEach(element => {
                fianalArr.push(element[keysearch])
            });
            return fianalArr;
        }
        var payload = {
            'Source': "NCD Details Page",
            'NCD Name': applicationData.ncddetails.schname,
            'No. of series': getSeriesNamect(applicationData.ncddetails.ipoSeriesMap, 'seriesNumber').join(','),
            'Frequency': getSeriesNamect(applicationData.ncddetails.ipoSeriesMap, 'frequency').join(','),
            'Investment Amount': applicationData.mainslidervalue,
            'Journey time Start': new Date()
        }
        ClevertapReact.event("NCD_Application Step 1", payload)


    }

    const updatevalue = (i, value) => {
        var updatedseries = series
        updatedseries[i].cardslide = value
        updatedseries[i].cardqty = parseInt(value / updatedseries[i].rate)
        setseries([...updatedseries])
    }

    const Ncdfulldetails = async () => {
        try {
            let data = await getNCDdetails(applicationData.ncddetails.issuecode)
            setncdfulldetails(data.data.resultData)
            setInterval(() => {
                setCounter(dateFormatter(new Date(applicationData.ncddetails.clsdt).getTime()));
            }, 1000);
            setLoading(false)
        }
        catch {
            localStorage.removeItem('user');
            history.push('/ncd_details/' + applicationData.ncddetails.issuecode);
        }
    }

    const ncdstepnext = () => {
        setLoading(true)
        if (series) {
            var finalamountsld = 0
            for (let i = 0; i < series.length; i++) {
                if (series[i].check === 1) {
                    finalamountsld += parseFloat(series[i].cardslide)
                }
            }
        }

        if (finalamountsld < (ncdfulldetails.noOfMandatoryBonds * ncdfulldetails.lowprice)) {
            setalert('Investment should not be lower than minimum investment');
            setLoading(false)
            return false
        }
        dispatch(
            setApplicationData({
                ...applicationData,
                series: series,
                ncdfulldetails: ncdfulldetails,
                mainslidervalue: finalamountsld
            })
        );

        history.push("/ncd-application-step-2")
    }

    const checkunchk = (val, i) => {

        var singlseries = series
        if (val === 1) {
            singlseries[i].check = 0
            singlseries[i].cardslide = 0
            singlseries[i].cardqty = 0
        }
        else {
            singlseries[i].check = 1
            singlseries[i].cardslide = series[i].minAmt
            singlseries[i].cardqty = series[i].minAmt / series[i].rate
        }
        setseries([...singlseries])
    }

    const setinputslider = (value, step, i) => {
        var updatedseries = series
        var exces = value % step
        var newval = value - exces
        updatedseries[i].cardslide = newval
        updatedseries[i].cardqty = parseInt(updatedseries[i].cardslide / updatedseries[i].rate)
        setseries([...updatedseries])
    }

    const hidesweeetalert = () => {
        setalert('')
    }
    const redirectsalert = (path = "/") => {
        history.push(path)
    }

    useEffect(() => {
        genaratelist()
        Ncdfulldetails()

    }, []);

    useEffect(() => {
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);
        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);

    const onBackButtonEvent = (e) => {
        e.preventDefault();

        if (window.confirm("Do you want to go back ?")) {
            history.push('/ncd_details/' + applicationData.ncddetails.issuecode);
        }
        else {
            window.history.pushState(null, null, window.location.pathname);
        }

    }

    const handleBeforeUnload = (e) => {
        e.preventDefault();
        const message =
            "Are you sure you want to leave? All provided data will be lost.";
        e.returnValue = message;
        return message;
    };

    return (
        <>
            {loading == true &&
                <div className="loading_screen">
                    <img src={LoaderImg} alt="loading..." />
                    <p>Please wait while fetching..</p>
                </div>
            }


            {/* {swalert != '' &&
                <SweetAlert
                    custom
                    showCancel
                    showCloseButton
                    error
                    title="Alert!"
                    cancelBtnText="Back to home"
                    cancelBtnBsStyle="light"
                    onConfirm={hidesweeetalert}
                    onCancel={redirectsalert}
                >
                    {swalert}
                </SweetAlert>
            }
            <section id="header">
                <div class="header-left">
                    <img onClick={() => history.push("/ncd_details/" + applicationData.ncddetails.issuecode)} src={applicationData.cmsdata?.logo ? `${CMS_URL}${applicationData.cmsdata.logo}` : NoImg} alt="" />
                    <div>
                        <h1>{applicationData.ncddetails.schname}</h1>
                        <div class="header-heading-subtext">
                            <p dangerouslySetInnerHTML={{ __html: counter + ' left to apply' }}></p>
                            <p class="seperator">·</p>
                            <p><span class="lowopacity">Min Qty:</span>{applicationData.ncddetails.noOfMandatoryBonds}</p>
                            <p class="seperator">·</p>
                            <p><span class="lowopacity">Price per NCD:</span> ₹{applicationData.ncddetails.lowprice}</p>
                        </div>
                    </div>
                </div>
                <div class="header-right">
                    <div class="steps">
                        <p class="active">1</p>
                        <hr />
                        <p>2</p>
                        <hr />
                        <p>3</p>
                        <hr />
                        <p>4</p>
                    </div>
                    <div class="step-names">
                        <p>Select Series</p>
                        <p>Your Details</p>
                        <p>Payment</p>
                        <p>Apply</p>
                    </div>
                </div>
            </section>
            <section id="container" class="ncdapplication1">


                {series.map((e, i) => {
                    return <SeriesCard details={e} changevalue={updatevalue} setcheck={checkunchk} count={i} setinputslider={setinputslider} />
                })}



            </section>
            <footer class="ncdfooter singlClmnMobile">
                {(() => {
                    if (series) {
                        var serisname = []
                        var finalamount = 0
                        for (let i = 0; i < series.length; i++) {
                            if (series[i].check === 1) {
                                serisname.push(series[i].seriesNumber)
                                finalamount += parseFloat(series[i].cardslide)
                            }

                        }
                        return <p><span>Total investment in Series {serisname.join(',')} </span>₹ {finalamount.toLocaleString()}</p>
                    }
                })()}
                <a class="next-btn" href="javascript:void(0)" onClick={ncdstepnext}>Next<i class="fas fa-arrow-right"></i></a>
            </footer> */}
        </>

    )
};

export default Ncdapplication1;